import { ADMINISTRATOR_ROLE } from '../config';
import { ensureCurrentUser } from './data';
import { getCurrentUserLocation } from './localStorage';

export const calculateAge = birthObj => {
    const { day, month, year } = birthObj;
    const birthday = new Date([month, day, year].join('/'));

    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const isProfileComplete = currentUser => {
    const { attributes, profileImage } = currentUser;
    const { profile, emailVerified } = attributes;
    const { id: imageId } = profileImage || {};

    const {
        bio,
        publicData: {
            availability,
            interest,
            desiredDisciplines,
            skills,
            languages,
            phoneVerified,
            hasGalleryAssets,
        },
    } = profile;

    const isPrimaryFieldsComplete =
        !!availability &&
        !!profileImage &&
        !!imageId &&
        !!bio &&
        !!interest &&
        interest.length > 0 &&
        !!desiredDisciplines &&
        desiredDisciplines.length > 0;

    return {
        profileComplete:
            isPrimaryFieldsComplete &&
            !!skills &&
            skills.length > 0 &&
            !!languages &&
            Object.values(languages || {}).length > 0 &&
            emailVerified &&
            phoneVerified &&
            !!hasGalleryAssets,
        isPrimaryFieldsComplete,
    };
};
/**
 * Resolve lat lng string from either user public data or LS value
 * @param {*} user sdk user
 * @returns null | string
 */
export const resolveUserLatLng = user => {
    if (
        user &&
        user.attributes &&
        user.attributes.profile &&
        user.attributes.profile.publicData &&
        user.attributes.profile.publicData.userLocation
    ) {
        const { userLocation } = user.attributes.profile.publicData;

        const userLocationStorage =
            userLocation && userLocation.lat && userLocation.lng ? userLocation : null;

        const userOriginStorage =
            userLocation && userLocation.selectedPlace && userLocation.selectedPlace.origin
                ? userLocation.selectedPlace.origin
                : null;

        if (!userLocationStorage && !userOriginStorage) {
            return '';
        }

        const { lat, lng } = userLocationStorage || userOriginStorage;
        return `${lat},${lng}`;
    }
    const userLSData = getCurrentUserLocation();

    if (userLSData && userLSData.loc) {
        return userLSData.loc;
    }

    return null;
};

export const SCORE_TOTAL = 'total';
export const SCORE_SUFFICIENT = 'sufficient';
export const SCORE_INSUFFICIENT = 'insufficient';

/**
 * total, sufficient, insufficient
 * @param {Number} score
 * @returns
 */
export const resolveStatusByMatchingMatrixScore = score =>
    score >= 70 ? SCORE_TOTAL : score > 30 && score <= 69 ? SCORE_SUFFICIENT : SCORE_INSUFFICIENT;

export const isAdmin = currentUser => {
    if (!currentUser || !currentUser.id) {
        return false;
    }

    const {
        attributes: {
            profile: {
                privateData: { role },
            },
        },
    } = ensureCurrentUser(currentUser);

    return role === ADMINISTRATOR_ROLE;
};

export const isEmailNotVerified = currentUser => {
    const {
        attributes: { emailVerified, email },
    } = ensureCurrentUser(currentUser);

    return email && !emailVerified;
};

import React from 'react';
import { injectIntl } from 'react-intl';
import css from './HorseDealAppTeaser.css';

const HorseDealAppTeaser = () => {
    const render = () => {
        return (
            <div className={css.appTeaserWrapper}>
                <div className={css.appTeaserContent}>
                    <div className={css.appTeaserTitle}>Hol dir unsere App!</div>
                    <div className={css.appTeaserDescription}>
                        Lade dir HorseDeal kostenlos im Playstore oder App Store herunter und
                        schon kann es losgehen!
                    </div>
                    <div className={css.appTeaserStoreLinks}>
                        <a className={css.storeLink} target='_blank'
                           href='https://play.google.com/store/apps/details?id=com.horsedeal24'>
                            <img src='/static/icons/google-playstore-badge.svg'
                                 className={css.appPlayStoreIcon} alt='Google Play Store Badge' />
                        </a>
                        <br className={css.pageBreak} />
                        <a className={css.storeLink} target='_blank'
                           href='https://support.horsedeal.com/hc/de/articles/17567786473618'>
                            <img src='/static/icons/apple-appstore-badge.svg'
                                 className={css.appPlayStoreIcon} alt='App Store Badge' />
                        </a>
                    </div>
                </div>
                <div className={css.appTeaserImage}></div>
            </div>
        );
    };

    return render();
};

export default injectIntl(HorseDealAppTeaser);

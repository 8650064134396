import React from 'react';
import { string, arrayOf, bool, func } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import classNames from 'classnames';
import { InlineTextButton, AppointmentMessage, TransitionMessage } from '../../components';
import { ensureTransaction, ensureUser, ensureListing } from '../../util/data';
import {
    anonymizeOtherParty,
    isAppointment,
    isMessage,
    isRelevantPastTransition,
    isTransition,
    resolveOtherPartyData,
} from '../../util/transaction';
import { propTypes } from '../../util/types';
import css from './ActivityFeed.css';
import {
    isOwnMessage,
    organizeItems,
    resolveChatLastActivity,
    resolveMessageDateGroups,
} from './ActivityFeed.helpers';

import EmptyTransition from './EmptyTransition';
import Message from './Message';
import MessageStatus from './MessageStatus';
import {
    CHAT_ENTITY_TYPE_APPOINTMENT,
    CHAT_ENTITY_TYPE_MESSAGE,
    CHAT_ENTITY_TYPE_TRANSITION,
} from '../../containers/TransactionPage/TransactionPage.duck';

export const chatItemFabric = ({
    item,
    messageComponent,
    transitionComponent,
    appointmentComponent,
}) => {
    const config = {
        [CHAT_ENTITY_TYPE_MESSAGE]: message => messageComponent(message),
        [CHAT_ENTITY_TYPE_TRANSITION]: transition => transitionComponent(transition),
        [CHAT_ENTITY_TYPE_APPOINTMENT]: item => appointmentComponent(item),
    };
    const messageItem = isMessage(item) ? CHAT_ENTITY_TYPE_MESSAGE : null;
    const transtionItem = isTransition(item) ? CHAT_ENTITY_TYPE_TRANSITION : null;
    const appointmentItem = isAppointment(item) ? CHAT_ENTITY_TYPE_APPOINTMENT : null;

    const itemName = transtionItem || messageItem || appointmentItem;

    return config[itemName](item);
};

export const ActivityFeedComponent = props => {
    const {
        rootClassName,
        className,
        messages,
        transaction,
        currentUser,
        hasOlderMessages,
        onShowOlderMessages,
        fetchMessagesInProgress,
        onManageDisableScrolling,
        intl,
        appointmentEntities,
        handleImageCarouselVisibility,
        ...rest
    } = props;

    const currentTransaction = ensureTransaction(transaction);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentListing = ensureListing(currentTransaction.listing);

    const transactionId = currentTransaction.id.uuid;
    const lastVisit = resolveChatLastActivity(currentTransaction, currentUser);

    const otherParty = anonymizeOtherParty(
        resolveOtherPartyData(currentUser, currentCustomer, currentProvider)
    )(currentTransaction);

    const transitionsAvailable = !!(
        (currentUser && currentUser.id && currentCustomer.id && currentProvider.id)
        // && currentListing.id
    );

    const transitions = currentTransaction.attributes.transitions
        ? currentTransaction.attributes.transitions
        : [];

    const organizedItems = organizeItems(
        messages,
        transitions,
        (appointmentEntities || {})[transactionId],
        hasOlderMessages || fetchMessagesInProgress
    );
    const lastListItem = (organizedItems || [])[organizedItems.length - 1];
    const lastListItemIsTransition = lastListItem && lastListItem.transition;

    const dateGroups = resolveMessageDateGroups(organizedItems, intl).sort((a, b) => {
        if (b.index > 1 && a.index > 1) {
            return a.index - b.index;
        }
        return b.index - a.index;
    });

    const transitionComponent = transition =>
        isRelevantPastTransition(transition.transition) ? (
            <li className={css.transitionItem}>
                {transitionsAvailable ? (
                    <TransitionMessage
                        transition={transition}
                        transaction={transaction}
                        currentUser={currentUser}
                        intl={intl}
                        /**
                         * TODO check the prop
                         */
                        isCustomer={false}
                        {...rest}
                    />
                ) : (
                    <EmptyTransition />
                )}
            </li>
        ) : null;

    const appointmentComponent = message => {
        const { createdAt } = message;
        const messageIsRead = lastVisit ? new Date(lastVisit) > new Date(createdAt) : false;

        return (
            <li key={message._id} className={css.messageItem}>
                <AppointmentMessage
                    currentCustomer={currentCustomer}
                    currentProvider={currentProvider}
                    currentUser={currentUser}
                    currentListing={currentListing}
                    appointmentData={message}
                    {...rest}
                >
                    {isOwnMessage(message.sender.userId, currentUser) && (
                        <MessageStatus messageIsViewedByOtherParty={messageIsRead} />
                    )}
                </AppointmentMessage>
            </li>
        );
    };

    const messageComponent = message => (
        <li id={`msg-${message.id.uuid}`} key={message.id.uuid} className={css.messageItem}>
            <Message
                message={message}
                intl={intl}
                isOwnMessage={isOwnMessage(message.sender.id.uuid, currentUser)}
                handleImageCarouselVisibility={handleImageCarouselVisibility}
                otherParty={otherParty}
                messageIsViewedByOtherParty={
                    lastVisit ? new Date(lastVisit) > new Date(message.attributes.createdAt) : false
                }
            />
        </li>
    );

    const classes = classNames(rootClassName || css.feed, {
        [className]: !!className,
        [css.lastListItemIsTransition]: !!lastListItemIsTransition,
    });

    return (
        <ul className={classes}>
            {hasOlderMessages ? (
                <li className={css.showOlderWrapper} key="show-older-messages">
                    <InlineTextButton className={css.showOlderButton} onClick={onShowOlderMessages}>
                        <FormattedMessage id="ActivityFeed.showOlderMessages" />
                    </InlineTextButton>
                </li>
            ) : null}
            {dateGroups.map(
                (dateGroup, index) =>
                    !!dateGroup.items.length && (
                        <li key={index}>
                            <h4 className={css.messageGroup}>{dateGroup.group}</h4>
                            <ul>
                                {dateGroup.items.map(item =>
                                    chatItemFabric({
                                        item,
                                        transitionComponent,
                                        messageComponent,
                                        appointmentComponent,
                                    })
                                )}
                            </ul>
                        </li>
                    )
            )}
        </ul>
    );
};

ActivityFeedComponent.defaultProps = {
    rootClassName: null,
    className: null,
};

ActivityFeedComponent.propTypes = {
    rootClassName: string,
    className: string,

    currentUser: propTypes.currentUser,
    transaction: propTypes.transaction,
    messages: arrayOf(propTypes.message),
    hasOlderMessages: bool.isRequired,
    // onOpenReviewModal: func.isRequired,
    onShowOlderMessages: func,
    fetchMessagesInProgress: bool.isRequired,
    handleAppointmentAction: func.isRequired,
    onManageDisableScrolling: func,

    // from injectIntl
    intl: intlShape.isRequired,
};

const ActivityFeed = injectIntl(ActivityFeedComponent);

export default ActivityFeed;

import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { InlineTextButton, MenuItem, ExternalLink, NamedLink } from '..';

import AvailabilityBadge from '../AvailabilityBadge/AvailabilityBadge';
import { ensureCurrentUser } from '../../util/data';
import config from '../../config';
import { isAdmin } from '../../util/user';

import css from './ProfileMenuContent.css';
import { isCurrentPage } from './Topbar.helpers';

const { userTypeRider, userTypeHorseowner } = config;

const ProfileMenuContent = ({
    currentUser,
    currentPage,
    notificationCount,
    onLogout,
    wishlistListingStatusChanged,
    hasDraftListing,
    profileNotComplete,
    availabilityModalId,
    match: { params },
}) => {
    const {
        attributes: {
            profile: {
                publicData: { userType, availabilityStatus },
            },
        },
    } = ensureCurrentUser(currentUser);

    const currentPageClass = page =>
        isCurrentPage(page, currentPage, params, currentUser) ? css.currentPage : null;

    const userIsRider = userType === userTypeRider;
    const userIsOwner = userType === userTypeHorseowner;
    const userIsAdmin = isAdmin(currentUser);

    return (
        <>
            {userIsRider && (
                <MenuItem
                    key="RiderAvailability"
                    rootClassName={classNames(
                        css.topBarMenuItem,
                        css.topBarMenuAvailability,
                        css.desktopOnly
                    )}
                >
                    <div className={css.topBarMenuAvailabilityHolder}>
                        <AvailabilityBadge
                            user={currentUser}
                            modalId={availabilityModalId}
                            rootClassName={classNames(
                                css.topBarAvailabilityBadge,
                                css[`availability-${availabilityStatus}`]
                            )}
                            editAllowed
                        />
                    </div>
                </MenuItem>
            )}
            {/* Profile & Features */}
            <MenuItem
                key="ProfileAndFeaturesSubLabel"
                rootClassName={classNames(
                    css.topBarMenuItem,
                    css.topBarMenuSubLabel,
                    css.desktopOnly,
                    {
                        [css.topBarMenuFirstSubLabel]: !userIsRider,
                    }
                )}
            >
                <p>
                    <FormattedMessage id="TopbarDesktop.subLabel-ProfileAndFeatures" />
                </p>
            </MenuItem>
            <MenuItem
                key="InboxPage"
                rootClassName={classNames(
                    css.topBarMenuItem,
                    css.mobileOnly,
                    currentPageClass('OrderMessagesPage'),
                    currentPageClass('InboxPage')
                )}
            >
                <NamedLink className={css.topBarMenuLink} name="InboxPage">
                    <p>
                        <FormattedMessage id="TopbarDesktop.inbox" />
                        {!!notificationCount && <code className={css.notificationDot} />}
                    </p>
                </NamedLink>
                {!!notificationCount && (
                    <div className={css.topBarUnreadMessageBadge}>
                        <FormattedMessage
                            id="TopbarDesktop.newMessageBadge"
                            values={{
                                messageNum: notificationCount,
                            }}
                        />
                    </div>
                )}
            </MenuItem>
            {userIsOwner && (
                <MenuItem
                    key="ManageListingsPage"
                    rootClassName={classNames(
                        css.topBarMenuItem,
                        css.mobileOnly,
                        currentPageClass('ManageListingsPage')
                    )}
                >
                    <NamedLink className={css.topBarMenuLink} name="ManageListingsPage">
                        <p>
                            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
                            {hasDraftListing && <code className={css.notificationDot} />}
                        </p>
                    </NamedLink>
                </MenuItem>
            )}
            <MenuItem
                key="ProfileSettingsPage"
                rootClassName={classNames(
                    css.topBarMenuItem,
                    currentPageClass('ProfileSettingsPage')
                )}
            >
                <NamedLink
                    className={css.profileSettingsLink}
                    name="ProfileSettingsPage"
                    params={{ userType: userType || userTypeRider }}
                >
                    <p>
                        <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
                        {profileNotComplete && <code className={css.notificationDot} />}
                    </p>
                </NamedLink>
            </MenuItem>
            {/* <MenuItem
                key="profileVisitors"
                rootClassName={classNames(css.topBarMenuItem, css.topBarMenuItemDisabled)}
            >
                <div className={css.topBarMenuLink}>
                    <FormattedMessage id="TopbarDesktop.profileVisitors" />
                </div>
                <div className={css.topBarUpgradeBadge}>
                    <FormattedMessage id="TopbarDesktop.upgradeBadge" />
                </div>
            </MenuItem> */}
            <MenuItem
                key="FavoritePage"
                rootClassName={classNames(css.topBarMenuItem, currentPageClass('FavoritePage'))}
            >
                <NamedLink className={css.profileSettingsLink} name="FavoritePage">
                    <p>
                        <FormattedMessage id="TopbarDesktop.FavoriteLink" />
                        {wishlistListingStatusChanged && <code className={css.notificationDot} />}
                    </p>
                </NamedLink>
            </MenuItem>
            {/* <MenuItem
                key="Kalender"
                rootClassName={classNames(css.topBarMenuItem, css.topBarMenuItemDisabled)}
            >
                <div className={css.topBarMenuLink}>
                    <FormattedMessage id="TopbarDesktop.calendar" />
                </div>
                <div className={css.topBarUpgradeBadge}>
                    <FormattedMessage id="TopbarDesktop.upgradeBadge" />
                </div>
            </MenuItem> */}
            {/* Settings & Support */}
            <MenuItem
                key="SettingsAndSupport"
                rootClassName={classNames(css.topBarMenuItem, css.topBarMenuSubLabel)}
            >
                <p>
                    <FormattedMessage id="TopbarDesktop.subLabel-SettingsAndSupport" />
                </p>
            </MenuItem>
            <MenuItem
                key="AccountSettingsPage"
                rootClassName={classNames(
                    css.topBarMenuItem,
                    currentPageClass('AccountSettingsPage')
                )}
            >
                <NamedLink className={css.topBarMenuLink} name="AccountSettingsPage">
                    <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
                </NamedLink>
            </MenuItem>
            {userIsAdmin && (
                <MenuItem key="AdminPanelsPage" rootClassName={css.topBarMenuItem}>
                    <NamedLink name="AdminPanelsPage" className={css.topBarMenuLink}>
                        Admin panel
                    </NamedLink>
                </MenuItem>
            )}
            <MenuItem key="Kontakt" rootClassName={classNames(css.topBarMenuItem)}>
                <ExternalLink className={css.topBarMenuLink} href="/kontakt" target="">
                    <FormattedMessage id="TopbarDesktop.support" />
                </ExternalLink>
            </MenuItem>

            {/* My other accounts */}
            <MenuItem
                key="MyOtherAccounts"
                rootClassName={classNames(css.topBarMenuItem, css.topBarMenuSubLabel)}
            >
                <p>
                    <FormattedMessage id="TopbarDesktop.subLabel-MyOtherAccounts" />
                </p>
            </MenuItem>
            <MenuItem
                key="SwitchAccount"
                rootClassName={classNames(css.topBarMenuItem, css.topBarMenuItemDisabled)}
            >
                <div className={css.topBarMenuLink}>
                    <FormattedMessage id={`TopbarDesktop.switchAccountAs-${userType}`} />
                </div>
            </MenuItem>

            <MenuItem key="logout">
                <footer className={css.tobBarMenuFooter}>
                    <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
                        <FormattedMessage id="TopbarDesktop.logout" />
                    </InlineTextButton>
                </footer>
            </MenuItem>
        </>
    );
};

export default compose(withRouter)(ProfileMenuContent);

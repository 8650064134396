import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import {
    TRANSITION_APPOINTMENT_CANCEL,
    TRANSITION_APPOINTMENT_DECLINE,
    TRANSITION_APPOINTMENT_ACCEPT,
    TRANSITION_ENQUIRY_DECLINE,
    TRANSITION_ENQUIRY_DECLINE_OWNER,
    TRANSITION_ENQUIRY_ACCEPT,
    TRANSITION_ENQUIRY_ACCEPT_OWNER,
    TRANSITION_ENQUIRY_EXPIRE,
    TRANSITION_REQUEST_SUBSCRIPTION_AFTER_ENQUIRY,
    TRANSITION_DECLINE_SUBSCRIPTION,
    TRANSITION_ACTIVATE_SUBSCRIPTION,
    TRANSITION_FINALIZE_SUBSCRIPTION,
    TRANSITION_FINALIZE_SUBSCRIPTION_AND_REVIEWS_MAY_BE_SENT,
    TRANSITION_FINALIZE_SUBSCRIPTION_AND_REVIEWED_BY_CUSTOMER,
    TRANSITION_FINALIZE_SUBSCRIPTION_AND_REVIEWED_BY_PROVIDER,
    TRANSITION_FINALIZE_SUBSCRIPTION_AND_REVIEWED_BY_BOTH_PARTIES,
    TRANSITION_START_REVIEWS_PERIOD_AND_SUBSCRIPTION_ACTIVATED,
    TRANSITION_PAUSE_SUBSCRIPTION,
    TRANSITION_PAUSE_SUBSCRIPTION_AND_REVIEWS_MAY_BE_SENT,
    TRANSITION_PAUSE_SUBSCRIPTION_AND_REVIEWED_BY_PROVIDER,
    TRANSITION_PAUSE_SUBSCRIPTION_AND_REVIEWED_BY_CUSTOMER,
    TRANSITION_PAUSE_SUBSCRIPTION_AND_REVIEWED_BY_BOTH_PARTIES,
    TRANSITION_REACTIVATE_SUBSCRIPTION,
    TRANSITION_REACTIVATE_SUBSCRIPTION_AND_REVIEWS_MAY_BE_SENT,
    TRANSITION_REACTIVATE_SUBSCRIPTION_AND_REVIEWED_BY_CUSTOMER,
    TRANSITION_REACTIVATE_SUBSCRIPTION_AND_REVIEWED_BY_PROVIDER,
    TRANSITION_REACTIVATE_SUBSCRIPTION_AND_REVIEWED_BY_BOTH_PARTIES,
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_1_BY_CUSTOMER_AND_SUBSCRIPTION_FINALIZED,
    TRANSITION_REVIEW_1_BY_PROVIDER_AND_SUBSCRIPTION_FINALIZED,
    TRANSITION_REVIEW_2_BY_CUSTOMER_AND_SUBSCRIPTION_FINALIZED,
    TRANSITION_REVIEW_2_BY_PROVIDER_AND_SUBSCRIPTION_FINALIZED,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
    TRANSITION_ENQUIRE,
    resolveOtherPartyData,
    anonymizeOtherParty,
} from '../../util/transaction';
import { ensureCurrentUser } from '../../util/data';
import { UserDisplayName, NamedLink } from '../../components';
import * as log from '../../util/log';

import css from './TransitionMessage.css';
import { userAlreadyLeftAReview, usersHaveLeftReviews } from './TransitionMessage.helpers';

export const resolveTransitionMessage = props => {
    const {
        transition,
        intl,
        onOpenReviewModal,
        ownRole,
        currentTransaction,
        isCustomer,
        currentUser: user,
        suppressLinks = false,
    } = props;
    const customer = currentTransaction.customer;
    const provider = currentTransaction.provider;
    const currentUser = ensureCurrentUser(user);

    const { listing } = currentTransaction;
    const otherParty = anonymizeOtherParty(resolveOtherPartyData(currentUser, customer, provider))(
        currentTransaction
    );
    const {
        id: { uuid: otherPartyId },
        attributes: {
            profile: {
                publicData: { userType: otherPartyType },
            },
        },
    } = otherParty;

    const otherUsersName = <UserDisplayName user={otherParty} intl={intl} shouldBeTrimmed />;

    const isOwnTransition =
        transition.by === ownRole ||
        (transition.sender && transition.sender.userId === currentUser.id.uuid);

    const currentTransition = transition.transition;

    const displayName = otherUsersName || '';
    // const deletedListing = intl.formatMessage({
    //     id: 'ActivityFeed.deletedListing',
    // });
    const { attributes } = listing || { attributes: { deleted: true } };
    const listingTitle = attributes.title;

    if (attributes.deleted) {
        return intl.formatMessage({
            id: 'ActivityFeed.deletedListing',
        });
    }

    const otherPartyLinkProps = {
        name: 'ProfilePage',
        params: { id: otherPartyId, userType: otherPartyType },
    };

    const otherPartyLink = <NamedLink {...otherPartyLinkProps}>{displayName}</NamedLink>;

    const faqLink = isCustomer
        ? 'https://support.horsedeal.com/hc/de/articles/17568334877842'
        : 'https://support.horsedeal.com/hc/de/articles/17568334877842';

    const manageSubscriptionLink = 'Currently not available';

    const leaveReviewLink = (
        <>
            <span
                className={classNames(css.pseudoLink, css.leaveReviewLink)}
                onClick={onOpenReviewModal}
            >
                <FormattedMessage id="ActivityFeed.leaveReviewLink" values={{ displayName }} />
            </span>
            <span className={css.pseudoLinkPoint}>.</span>
        </>
    );

    switch (currentTransition) {
        case TRANSITION_ENQUIRE:
            return null;
        case TRANSITION_APPOINTMENT_CANCEL:
            return isOwnTransition ? (
                <FormattedMessage id="AppointmentMessage.ownTransitionMessageCanceled" />
            ) : (
                <FormattedMessage
                    id="AppointmentMessage.transitionMessageCanceled"
                    values={{ otherPartyName: displayName }}
                />
            );
        case TRANSITION_APPOINTMENT_DECLINE:
            return !isOwnTransition ? (
                <FormattedMessage id="AppointmentMessage.ownTransitionMessageDeclined" />
            ) : (
                <FormattedMessage
                    id="AppointmentMessage.transitionMessageDeclined"
                    values={{ otherPartyName: displayName }}
                />
            );
        case TRANSITION_APPOINTMENT_ACCEPT:
            return !isOwnTransition ? (
                <FormattedMessage id="AppointmentMessage.ownTransitionMessageApproved" />
            ) : (
                <FormattedMessage
                    id="AppointmentMessage.transitionMessageApproved"
                    values={{ otherPartyName: displayName }}
                />
            );
        case TRANSITION_REQUEST_SUBSCRIPTION_AFTER_ENQUIRY:
            return isOwnTransition ? (
                <FormattedMessage
                    id="ActivityFeed.ownTransitionRequest"
                    values={{ otherPartyLink }}
                />
            ) : (
                <FormattedMessage
                    id="ActivityFeed.transitionRequest"
                    values={{ displayName: otherPartyLink, listingTitle }}
                />
            );
        case TRANSITION_ENQUIRY_DECLINE:
        case TRANSITION_ENQUIRY_DECLINE_OWNER:
            return isOwnTransition ? (
                <FormattedMessage id="ActivityFeed.userDeclinesRequest" values={{ displayName }} />
            ) : (
                <FormattedMessage
                    id="ActivityFeed.providerDeclinesRequest"
                    values={{ displayName }}
                />
            );
        case TRANSITION_ENQUIRY_ACCEPT:
        case TRANSITION_ENQUIRY_ACCEPT_OWNER:
            return isOwnTransition ? (
                <React.Fragment>
                    <FormattedMessage
                        id="ActivityFeed.userAcceptsRequest"
                        values={{ displayName: displayName }}
                    />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <FormattedMessage
                        id="ActivityFeed.providerAcceptsRequest"
                        values={{ displayName: displayName }}
                    />
                </React.Fragment>
            );
        case TRANSITION_ENQUIRY_EXPIRE:
            return (
                <React.Fragment>
                    <FormattedMessage id="ActivityFeed.requestExpired" />{' '}
                    <span
                        className={classNames({
                            [css.pseudoLink]: !suppressLinks,
                        })}
                        onClick={() => (suppressLinks ? null : window.location.replace(faqLink))}
                    >
                        <FormattedMessage id="ActivityFeed.requestExpiredLink" />
                    </span>
                </React.Fragment>
            );
        case TRANSITION_DECLINE_SUBSCRIPTION:
            return isOwnTransition ? (
                <FormattedMessage id="ActivityFeed.ownTransitionDecline" values={{ displayName }} />
            ) : (
                <FormattedMessage id="ActivityFeed.transitionDecline" values={{ displayName }} />
            );
        case TRANSITION_ACTIVATE_SUBSCRIPTION:
            return isOwnTransition ? (
                <FormattedMessage
                    id="ActivityFeed.ownTransitionAccept"
                    values={{ manageSubscriptionLink }}
                />
            ) : (
                <FormattedMessage
                    id="ActivityFeed.transitionAccept"
                    values={{ displayName: otherPartyLink, manageSubscriptionLink }}
                />
            );
        case TRANSITION_PAUSE_SUBSCRIPTION:
            return <FormattedMessage id="ActivityFeed.transitionPaused" />;
        case TRANSITION_PAUSE_SUBSCRIPTION_AND_REVIEWS_MAY_BE_SENT:
            return <FormattedMessage id="ActivityFeed.transitionPaused" />;
        case TRANSITION_PAUSE_SUBSCRIPTION_AND_REVIEWED_BY_PROVIDER:
            return <FormattedMessage id="ActivityFeed.transitionPaused" />;
        case TRANSITION_PAUSE_SUBSCRIPTION_AND_REVIEWED_BY_CUSTOMER:
            return <FormattedMessage id="ActivityFeed.transitionPaused" />;
        case TRANSITION_PAUSE_SUBSCRIPTION_AND_REVIEWED_BY_BOTH_PARTIES:
            return <FormattedMessage id="ActivityFeed.transitionPaused" />;
        case TRANSITION_REACTIVATE_SUBSCRIPTION:
            return <FormattedMessage id="ActivityFeed.transitionReactivated" />;
        case TRANSITION_REACTIVATE_SUBSCRIPTION_AND_REVIEWS_MAY_BE_SENT:
            return <FormattedMessage id="ActivityFeed.transitionReactivated" />;
        case TRANSITION_REACTIVATE_SUBSCRIPTION_AND_REVIEWED_BY_CUSTOMER:
            return <FormattedMessage id="ActivityFeed.transitionReactivated" />;
        case TRANSITION_REACTIVATE_SUBSCRIPTION_AND_REVIEWED_BY_PROVIDER:
            return <FormattedMessage id="ActivityFeed.transitionReactivated" />;
        case TRANSITION_REACTIVATE_SUBSCRIPTION_AND_REVIEWED_BY_BOTH_PARTIES:
            return <FormattedMessage id="ActivityFeed.transitionReactivated" />;
        case TRANSITION_FINALIZE_SUBSCRIPTION:
            return <FormattedMessage id="ActivityFeed.transitionComplete" />;
        case TRANSITION_FINALIZE_SUBSCRIPTION_AND_REVIEWS_MAY_BE_SENT:
            return <FormattedMessage id="ActivityFeed.transitionComplete" />;
        case TRANSITION_FINALIZE_SUBSCRIPTION_AND_REVIEWED_BY_CUSTOMER:
            return <FormattedMessage id="ActivityFeed.transitionComplete" />;
        case TRANSITION_FINALIZE_SUBSCRIPTION_AND_REVIEWED_BY_PROVIDER:
            return <FormattedMessage id="ActivityFeed.transitionComplete" />;
        case TRANSITION_FINALIZE_SUBSCRIPTION_AND_REVIEWED_BY_BOTH_PARTIES:
            return <FormattedMessage id="ActivityFeed.transitionComplete" />;
        case TRANSITION_START_REVIEWS_PERIOD_AND_SUBSCRIPTION_ACTIVATED:
            const userHasLeftAReviewMaybe = userAlreadyLeftAReview(currentTransaction);

            if (userHasLeftAReviewMaybe) {
                return <FormattedMessage id="ActivityFeed.transitionUserAlreadySentReview" />;
            } else {
                return (
                    <FormattedMessage
                        id="ActivityFeed.transitionReviewPeroidStarted"
                        values={{ leaveReviewLink }}
                    />
                );
            }
        case TRANSITION_REVIEW_1_BY_PROVIDER:
        case TRANSITION_REVIEW_1_BY_CUSTOMER:
        case TRANSITION_REVIEW_1_BY_PROVIDER_AND_SUBSCRIPTION_FINALIZED:
        case TRANSITION_REVIEW_1_BY_CUSTOMER_AND_SUBSCRIPTION_FINALIZED:
            const usersHaveLeftReviewsMaybe = usersHaveLeftReviews(currentTransaction);
            /** current user left a review */
            if (isOwnTransition) {
                return (
                    <FormattedMessage
                        id="ActivityFeed.ownTransitionReview"
                        values={{ otherPartyLink }}
                    />
                );
            } else if (usersHaveLeftReviewsMaybe) {
                /** both users left reviews */
                return (
                    <FormattedMessage
                        id="ActivityFeed.transitionReview2"
                        values={{ otherPartyLink }}
                    />
                );
            } else {
                /** the other side left a review */
                const leaveReviewLink = (
                    <span
                        className={classNames(css.pseudoLink, css.leaveReviewLink)}
                        onClick={onOpenReviewModal}
                    >
                        <FormattedMessage
                            id="ActivityFeed.leaveReviewLink2"
                            values={{ displayName }}
                        />
                    </span>
                );
                return (
                    <FormattedMessage
                        id="ActivityFeed.transitionReview1"
                        values={{ otherPartyLink, leaveReviewLink }}
                    />
                );
            }
        case TRANSITION_REVIEW_2_BY_PROVIDER:
        case TRANSITION_REVIEW_2_BY_CUSTOMER:
        case TRANSITION_REVIEW_2_BY_CUSTOMER_AND_SUBSCRIPTION_FINALIZED:
        case TRANSITION_REVIEW_2_BY_PROVIDER_AND_SUBSCRIPTION_FINALIZED:
            if (isOwnTransition) {
                return (
                    <FormattedMessage
                        id="ActivityFeed.ownTransitionReview"
                        values={{ otherPartyLink }}
                    />
                );
            } else {
                return (
                    <FormattedMessage
                        id="ActivityFeed.transitionReview2"
                        values={{ otherPartyLink }}
                    />
                );
            }
        default:
            log.error(
                new Error(`Unknown transaction transition type - ${currentTransition}`),
                'unknown-transition-type',
                {
                    transitionType: currentTransition,
                }
            );
            return '';
    }
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ensureOwnListing } from '../../util/data';
import { EditListingLocationForm } from '../../forms';

import css from './EditListingLocationPanel.css';
import { DEFAULT_LOCATION } from '../EditListingWizard/EditListingWizardTab';

const extractOriginFromSP = entry =>
    entry.location && entry.location.selectedPlace ? entry.location.selectedPlace.origin : false;

const extractOriginFromLoc = entry =>
    entry.location && entry.location.lat && entry.location.lng ? entry.location : false;

class EditListingLocationPanel extends Component {
    constructor(props) {
        super(props);

        this.getInitialValues = this.getInitialValues.bind(this);

        this.state = {
            initialValues: this.getInitialValues(),
        };
    }

    getInitialValues() {
        const { listing } = this.props;
        const currentListing = ensureOwnListing(listing);
        const { geolocation, publicData } = currentListing.attributes;

        // Only render current search if full place object is available in the URL params
        // TODO bounds are missing - those need to be queried directly from Google Places
        const locationFieldsPresent =
            publicData && publicData.location && publicData.location.address && geolocation;
        const {
            furnishingRider,
            furnishingHorse,
            mobility,
            ridingLessons,
            services,
            addressDetails,
        } = publicData || {};
        const location = publicData && publicData.location ? publicData.location : {};

        const { address, building } = location;

        return {
            building,
            furnishingRider,
            furnishingHorse,
            mobility,
            ridingLessons,
            services,
            addressDetails,
            location: locationFieldsPresent
                ? {
                      search: address,
                      selectedPlace: { address, origin: geolocation },
                  }
                : null,
        };
    }

    render() {
        const {
            className,
            rootClassName,
            listing,
            onSubmit,
            onChange,
            submitButtonText,
            panelUpdated,
            updateInProgress,
            errors,
            listingId,
            externalListing,
            isNewListingFlow,
            ...rest
        } = this.props;

        const classes = classNames(rootClassName || css.root, className);
        const currentListing = ensureOwnListing(listing);

        const { geolocation, publicData } = currentListing.attributes;

        return (
            <div className={classes}>
                <EditListingLocationForm
                    className={css.form}
                    initialValues={this.state.initialValues}
                    location={publicData.location}
                    geolocation={geolocation}
                    listingId={listingId}
                    currentListing={currentListing}
                    isNewListingFlow={isNewListingFlow}
                    onSubmit={(values, form) => {
                        const origin =
                            extractOriginFromSP(values) ||
                            extractOriginFromLoc(values) ||
                            DEFAULT_LOCATION.origin;

                        const {
                            furnishingRider,
                            furnishingHorse,
                            mobility,
                            ridingLessons,
                            services,
                            streetAddress,
                            houseNumber,
                            postalCode,
                            city,
                            country,
                            isAutoSave,
                            redirectToListingPage,
                        } = values;

                        const addressChanged = streetAddress && houseNumber && postalCode && city;
                        const addressDetails = addressChanged
                            ? {
                                  street: streetAddress,
                                  number: houseNumber,
                                  postalCode: postalCode,
                                  location: city,
                                  country,
                              }
                            : values.addressDetails;

                        const addressUnits = (addressChanged
                            ? [values.postalCode, values.city]
                            : [addressDetails.postalCode, addressDetails.location]
                        ).filter(s => !!s);
                        const address = addressUnits.join(' ');

                        const updateValues = {
                            geolocation: origin,
                            publicData: {
                                furnishingRider,
                                furnishingHorse,
                                ridingLessons,
                                mobility,
                                services,
                                addressDetails,
                                location: { address },
                                publicAddress: address,
                                /**
                                 * furnishing is used for Search schema
                                 */
                                furnishing: [
                                    ...(furnishingRider || []),
                                    ...(furnishingHorse || []),
                                ],
                            },
                        };
                        this.setState({
                            initialValues: {
                                furnishingRider,
                                mobility,
                                ridingLessons,
                                furnishingHorse,
                                services,
                                addressDetails,
                                location: { search: address, selectedPlace: { address, origin } },
                            },
                        });

                        if (isAutoSave) {
                            updateValues.isAutoSave = isAutoSave;
                        }

                        if (redirectToListingPage) {
                            updateValues.redirectToListingPage = redirectToListingPage;
                        }

                        onSubmit(updateValues);
                    }}
                    onChange={onChange}
                    saveActionMsg={submitButtonText}
                    updated={panelUpdated}
                    updateInProgress={updateInProgress}
                    fetchErrors={errors}
                    externalListing={externalListing}
                    {...rest}
                />
            </div>
        );
    }
}

const { func, object, string, bool } = PropTypes;

EditListingLocationPanel.defaultProps = {
    className: null,
    rootClassName: null,
    listing: null,
    externalListing: false,
};

EditListingLocationPanel.propTypes = {
    className: string,
    rootClassName: string,

    // We cannot use propTypes.listing since the listing might be a draft.
    listing: object,

    onSubmit: func.isRequired,
    onChange: func.isRequired,
    submitButtonText: string.isRequired,
    panelUpdated: bool.isRequired,
    updateInProgress: bool.isRequired,
    errors: object.isRequired,
    externalListing: bool,
};

export default EditListingLocationPanel;

import {
    SpazierbeteiligungFindenPage,
    PflegebeteiligungFindenPage,
    ReitbeteiligungFindenPage
} from './index';

const routeConfiguration = () => {
    return [
        {
            path: '/landing/spazierbeteiligung-finden',
            name: 'SpazierbeteiligungFindenPage',
            component: SpazierbeteiligungFindenPage
        },
        {
            path: '/landing/pflegebeteiligung-finden',
            name: 'PflegebeteiligungFindenPage',
            component: PflegebeteiligungFindenPage
        },
        {
            path: '/landing/reitbeteiligung-finden',
            name: 'ReitbeteiligungFindenPage',
            component: ReitbeteiligungFindenPage
        }
    ];
};

export default routeConfiguration().map((routeConfiguration, index) => routeConfiguration);

import React from 'react';
import css from './Filters.css';

import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage } from '../../../util/reactIntl';

import {
  FieldCheckbox,
  SecondaryButton,
  OutsideClickHandler,
  Form,
  Button,
  ModalPortal,
} from '../../../components';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routeConfiguration';

export const FILTER_SEARCH_PARAM = 'filters';

export const REQUESTED_FILTER_KEY = 'requested';
export const ACCEPTED_FILTER_KEY = 'accepted';
export const DECLINED_FILTER_KEY = 'declined';
export const EXPIRED_FILTER_KEY = 'expired';
export const ARCHIEVED_FILTER_KEY = 'archieved';
export const UNREAD_FILTER_KEY = 'unread';

export const MESSAGES_STATUS_OPTIONS = [
  {
    label: 'Ungelesen',
    value: UNREAD_FILTER_KEY,
  },
  { label: 'Archiviert', value: ARCHIEVED_FILTER_KEY },
];

export const TX_STATUS_OPTIONS = [
  {
    label: 'Ausstehend',
    value: REQUESTED_FILTER_KEY,
  },
  { label: 'Akzeptiert', value: ACCEPTED_FILTER_KEY },
  { label: 'Abgelehnt', value: DECLINED_FILTER_KEY },
  { label: 'Abgelaufen', value: EXPIRED_FILTER_KEY },
];

const Filters = ({ onClose, isOpen, params, filters, onManageDisableScrolling, history }) => {
  const [isMobile, , computing] = useIsMobile(1024);

  const pageId = params.id;
  const isDetailsPage = !!pageId;

  const renderWrapperComponent = children =>
    isMobile ? (
      <ModalPortal
        id="filters-inbox-modal"
        isOpen={isOpen}
        onClose={onClose}
        onManageDisableScrolling={onManageDisableScrolling}
        containerClassName={css.modalContainer}
        contentClassName={css.modalContent}
        containerClassNameJoined
      >
        <h4>
          <FormattedMessage id="InboxPanel.filterModalHeading" />
        </h4>
        {children}
      </ModalPortal>
    ) : (
      <OutsideClickHandler onOutsideClick={onClose} className={css.overlay}>
        <section className={css.popup}>{children}</section>
      </OutsideClickHandler>
    );

  const redirectWithQueryParams = ({ messagesStatus, backToMessages }) => {
    onClose();
    const page = isDetailsPage && !backToMessages ? 'OrderDetailsPage' : 'OrderMessagesPage';
    const searchParamsSelected = messagesStatus && messagesStatus.length;

    history.push(
      createResourceLocatorString(
        page,
        routeConfiguration(),
        isDetailsPage ? { id: pageId } : {},
        searchParamsSelected
          ? {
              [FILTER_SEARCH_PARAM]: messagesStatus.join(','),
            }
          : {}
      )
    );
  };

  const filtersDefined = filters && filters.length;

  if (computing) {
    return null;
  }

  return renderWrapperComponent(
    <FinalForm
      onSubmit={redirectWithQueryParams}
      initialValues={filtersDefined ? { messagesStatus: [...filters] } : {}}
      render={fieldRenderProps => {
        const { handleSubmit, values } = fieldRenderProps;
        const { messagesStatus } = values;

        const disabled = !messagesStatus || messagesStatus.length === 0;

        return (
          <Form onSubmit={handleSubmit} className={css.form}>
            <main>
              <p className={css.subHeading}>
                <FormattedMessage id="InboxPanel.filterPopupMessageStatusHeading" />
              </p>
              {MESSAGES_STATUS_OPTIONS.map(({ label, value }) => (
                <FieldCheckbox
                  type="checkbox"
                  name="messagesStatus"
                  key={label}
                  id={value}
                  label={label}
                  value={value}
                  className={css.fullWidthField}
                />
              ))}
            </main>
            <main>
              <p className={css.subHeading}>
                <FormattedMessage id="InboxPanel.filterPopupTxStatusHeading" />
              </p>
              {TX_STATUS_OPTIONS.map(({ label, value }) => (
                <FieldCheckbox
                  type="checkbox"
                  name="messagesStatus"
                  key={label}
                  id={value}
                  label={label}
                  value={value}
                  className={css.fullWidthField}
                />
              ))}
            </main>
            <footer className={css.actionBar}>
              <SecondaryButton
                type="button"
                disabled={disabled}
                onClick={() =>
                  redirectWithQueryParams({ messagesStatus: [], backToMessages: true })
                }
              >
                <FormattedMessage id="InboxPanel.filterPopupActionClose" />
              </SecondaryButton>
              <Button disabled={disabled}>
                <FormattedMessage id="InboxPanel.filterPopupActionSubmit" />
              </Button>
            </footer>
          </Form>
        );
      }}
    />
  );
};

export default compose(withRouter)(Filters);

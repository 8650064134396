import React, { useEffect, useState } from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import { EditComponent } from '..';
import { LISTING_PAGE_PARAM_TYPE_EDIT_AND_REDIRECT } from '../../../../util/urlHelpers';
import { NamedLink } from '../../../../components';
import css from './SectionQualifications.css';
import ScoreBadge from '../../../../forms/ProfileSettingsForm/ScoreBadge';
import { qualificationsWithDropBoxes } from '../../../../util/disciplines';

const SectionQualifications = ({ publicData, isOwnListing, editParams, isMobile, scoreData }) => {
    const [qualifications, setQualifications] = useState([]);

    const { desiredDisciplines, desiredLevels = {} } = publicData || {};

    useEffect(() => {
        setQualifications(
            (desiredDisciplines || []).reduce((acc, discipline) => {
                const neededObject = qualificationsWithDropBoxes.find(q => q.id == discipline);

                if (!neededObject) {
                    return acc;
                }

                const { label: title, dropBoxes = [] } = neededObject;
                const [firstDropBox] = dropBoxes;

                const subValueKey = desiredLevels[discipline];
                const subValueTitle =
                    firstDropBox && firstDropBox.options
                        ? firstDropBox.options.find(o => o.key == subValueKey)?.label
                        : null;

                if (!subValueTitle || (subValueTitle && subValueTitle == 'Keine')) {
                    return acc;
                }
                return [...acc, { title, subValueTitle }];
            }, [])
        );
    }, [desiredDisciplines]);

    const dataAbsent = !isOwnListing && (!qualifications || qualifications.length === 0);

    return dataAbsent ? null : (
        <div className={css.root}>
            {scoreData && (
                <div className={css.matchingContainer}>
                    <ScoreBadge
                        scoreData={{
                            maxScore: 100,
                            total: scoreData.qualificationScore || 0,
                        }}
                    />
                </div>
            )}
            <h2 className={css.title}>
                <FormattedMessage id={`SectionQualifications.title${isMobile ? 'Mobile' : ''}`} />

                {isOwnListing ? (
                    <EditComponent pageName="qualifications" editParams={editParams} />
                ) : null}
            </h2>

            <div className={css.sectionContent}>
                <div className={css.qBoxes}>
                    {qualifications.map(({ title, subValueTitle }) => (
                        <p className={css.qBox}>
                            <span>{title}</span>
                            <span>{subValueTitle}</span>
                        </p>
                    ))}
                </div>

                {isOwnListing && (
                    <NamedLink
                        className={css.addBtn}
                        name="EditListingPage"
                        params={{
                            ...editParams,
                            type: LISTING_PAGE_PARAM_TYPE_EDIT_AND_REDIRECT,
                            tab: 'disciplines',
                        }}
                    >
                        <FormattedMessage id="SectionQualifications.addQualificagtionsBtn" />
                    </NamedLink>
                )}
            </div>
        </div>
    );
};

export default SectionQualifications;

import { currentUserShowSuccess, fetchCurrentUser } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/PersonalInfoPage.duck/SET_INITIAL_STATE';
export const SAVE_PERSONAL_INFO_DETAILS_REQUEST =
  'app/PersonalInfoPage.duck/SAVE_PERSONAL_INFO_DETAILS_REQUEST';
export const SAVE_PERSONAL_INFO_DETAILS_SUCCESS =
  'app/PersonalInfoPage.duck/SAVE_PERSONAL_INFO_DETAILS_SUCCESS';
export const SAVE_PERSONAL_INFO_DETAILS_ERROR =
  'app/PersonalInfoPage.duck/SAVE_PERSONAL_INFO_DETAILS_ERROR';

// ================ Reducer ================ //

const initialState = {
  personalInfoDetailsSaveInProgress: false,
  personalInfoDetailsSaved: false,
};

export default function payoutPreferencesPageReducer(state = initialState, action = {}) {
  const { type } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return initialState;

    case SAVE_PERSONAL_INFO_DETAILS_REQUEST:
      return { ...state, personalInfoDetailsSaveInProgress: true };
    case SAVE_PERSONAL_INFO_DETAILS_SUCCESS:
      return { ...state, personalInfoDetailsSaveInProgress: false };
    case SAVE_PERSONAL_INFO_DETAILS_ERROR:
      return { ...state, personalInfoDetailsSaveInProgress: false, personalInfoDetailsSaved: true };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});
export const savePersonalInfoDetailsRequest = () => ({
  type: SAVE_PERSONAL_INFO_DETAILS_REQUEST,
});
export const savePersonalInfoDetailsError = () => ({
  type: SAVE_PERSONAL_INFO_DETAILS_ERROR,
});
export const savePersonalInfoDetailsSuccess = () => ({
  type: SAVE_PERSONAL_INFO_DETAILS_SUCCESS,
});

// ================ Thunks ================ //

export const savePersonalInfoDetails = values => (dispatch, getState, sdk) => {
  const {
    user: { currentUser },
  } = getState();

  const {
    firstName,
    lastName,
    country,
    city,
    postalCode,
    streetAddress,
    birthDate,
    houseNumber,
    userLocation,
  } = values;
  dispatch(savePersonalInfoDetailsRequest());

  const params = {
    firstName,
    lastName,
    publicData: {
      birthDate,
      city,
      country,
      houseNumber,
      postalCode,
      streetAddress,
    },
  };

  if (userLocation) {
    params.publicData.userLocation = userLocation;
  }

  return sdk.currentUser
    .updateProfile(params)
    .then(response => {
      dispatch(savePersonalInfoDetailsSuccess());

      const user = {
        ...currentUser,
        attributes: {
          ...currentUser.attributes,
          profile: {
            ...currentUser.attributes.profile,
            firstName,
            lastName,
            publicData: {
              ...currentUser.attributes.profile.publicData,
              birthDate,
              city,
              country,
              houseNumber,
              postalCode,
              streetAddress,
            },
          },
        },
      };

      dispatch(currentUserShowSuccess(user));

      return response;
    })
    .catch(e => {
      dispatch(savePersonalInfoDetailsError());
      throw e;
    });
};

export const loadData = () => async (dispatch, getState, sdk) => {
  dispatch(setInitialState());

  return dispatch(fetchCurrentUser());
};

/**
 * Get time difference between two dates
 * @param {Date} first
 * @param {Date} today
 * @returns {milliseconds, minutes, hours, days}
 */
export const datediff = (first, today) => {
    const milliseconds = Math.round(today - first);
    const minutes = Math.round(((milliseconds % 86400000) % 3600000) / 60000);
    const hours = Math.floor((milliseconds % 86400000) / 3600000);
    const days = Math.floor((today - first) / (1000 * 60 * 60 * 24));

    return {
        milliseconds,
        minutes,
        hours,
        days,
    };
};
/**
 * Get days, or hours, or minutes text from today up to date provided
 * @param {Date} date - date to compare with current date
 * @returns { days: string, hours: string, minutes: string }
 */
export const getTimeDiffRepresentation = (firstDate, secondDate) => {
    const { hours, days, minutes } = datediff(firstDate, secondDate);
    const hoursAbs = Math.abs(hours);
    const daysAbs = Math.abs(days);
    const hoursLeft = !hoursAbs ? '' : hoursAbs === 1 ? '1 Stunde' : `${hoursAbs} Stunden`;
    const daysLeft = !daysAbs ? '' : daysAbs === 1 ? '1 Tag' : `${daysAbs} Tage`;

    const minutesLeft = !hoursLeft && !daysLeft ? `${minutes} Minuten` : null;
    return { days: daysLeft, hours: hoursLeft, minutes: minutesLeft };
};

import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
    Page,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    CreditsPurchasingModal,
    AvailableUnlocksModal,
    Footer,
    NoResultsBlock,
    Button,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { ensureCurrentUser } from '../../util/data';

import css from './VisitorsPage.css';
import VisitorCard from './VisitorCard';
import { usePaymentStatus } from '../../hooks/usePaymentStatus';
import { withdrawUserCredits } from '../../util/api';

export const VisitorsPage = ({
    scrollingDisabled,
    currentUser,
    assetsLoadingRequests,
    history,
    location,
}) => {
    const [unlockModalOpen, setUnlockModalOpen] = useState(false);
    const [buyCreditsModalOpen, setBuyCreditsModalOpen] = useState(false);
    const [cardParams, setCardParams] = useState(null);

    const {
        paymentError,
        navigateToErrorPage,
        navigateFromErrorPage,
        navigateToSuccessPage,
    } = usePaymentStatus({
        location,
        history,
        pageName: 'VisitorsPage',
    });

    const schemaTitle = 'Deine Ansichten';
    const schemaDescription = 'Hier siehst Du, wer vor Kurzem dein Profil angesehen hat.';

    const {
        protectedData: { representationListingId },
        publicData: { userType },
        privateData: { creditsAvailable = {}, visitorsData = {} },
    } = currentUser.attributes.profile;

    const creditsAvailableByType = Number(creditsAvailable[userType] || 0);
    const visitorsByType = !paymentError && visitorsData && visitorsData[userType];
    const visitorsNum = Array.isArray(visitorsByType) && visitorsByType.length;
    const minCreditsNum = 10;
    const insufficientCredits = creditsAvailableByType < minCreditsNum;
    const noResults = !visitorsByType && !paymentError;

    const handleAcceptOrDeclineRequest = async () => {
        const { visitedEntryId, visitorRepresentationId } = cardParams;
        const { uuid: userId } = currentUser.id;
        try {
            const response = await withdrawUserCredits(
                userId,
                minCreditsNum,
                visitedEntryId,
                visitorRepresentationId
            );
            const data = await response.json();
            const { error, message } = data;

            if (error) {
                throw new Error(message);
            }

            navigateToSuccessPage();
        } catch (error) {
            navigateToErrorPage(error.message);
        } finally {
            setUnlockModalOpen(false);
        }
    };

    return (
        <Page
            scrollingDisabled={scrollingDisabled}
            contentType="website"
            description={schemaDescription}
            title={schemaTitle}
            schema={{
                '@context': 'http://schema.org',
                '@type': 'WebPage',
                description: schemaDescription,
                name: schemaTitle,
                image: ['https://horsedeal.imgix.net/static/social-image-1200x630.webp'],
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain
                    className={css.layout}
                    blobBackground
                    blobProps={{
                        xDistance: 20,
                        baseWidth: 760,
                    }}
                >
                    {paymentError && (
                        <main className={css.container}>
                            <p className={css.error}>
                                Zahlung fehlgeschlagen. Versuchen Sie es später oder wenden Sie sich
                                an das Supportteam.
                            </p>
                            <Button type="button" onClick={() => navigateFromErrorPage()}>
                                Zurück
                            </Button>
                        </main>
                    )}
                    {noResults && (
                        <NoResultsBlock
                            headerId="Du hast noch keine Ansichten."
                            parapraphId="Es scheint so, als hättest Du noch keine Ansichten erhalten."
                            searchFormPlaceholder="Suche in deiner Nähe"
                            rootClass={css.noResults}
                            isSearchForm
                        />
                    )}
                    {visitorsByType && (
                        <main className={css.container}>
                            <header className={css.header}>
                                <h1 className={css.heading}>{visitorsNum} Ansichten</h1>
                                <p className={css.subHeading}>
                                    Hier siehst Du, wer vor Kurzem dein Profil angesehen hat.
                                </p>
                            </header>
                            <div className={css.cardHolder}>
                                {visitorsByType
                                    .sort(
                                        (a, b) =>
                                            new Date(b.visitedAt).getTime() -
                                            new Date(a.visitedAt).getTime()
                                    )
                                    .map(visitor => (
                                        <VisitorCard
                                            key={`${visitor.visitedEntryId}-${visitor.visitorRepresentationId}`}
                                            {...visitor}
                                            history={history}
                                            assetsLoadingRequests={assetsLoadingRequests}
                                            representationListingId={representationListingId}
                                            handleLockedCardClick={data => {
                                                if (insufficientCredits) {
                                                    return setBuyCreditsModalOpen(true);
                                                }

                                                setUnlockModalOpen(true);
                                                setCardParams(data);
                                            }}
                                        />
                                    ))}
                            </div>
                        </main>
                    )}
                    {unlockModalOpen && (
                        <AvailableUnlocksModal
                            isOpen
                            currentUser={currentUser}
                            onClose={() => setUnlockModalOpen(false)}
                            handleAcceptOrDeclineRequest={handleAcceptOrDeclineRequest}
                        />
                    )}
                    {buyCreditsModalOpen && (
                        <CreditsPurchasingModal
                            isOpen
                            heading="Alles aufgebraucht!"
                            description="Füge mehr Credits hinzu, um diese Ansicht aufzudecken."
                            sidenote={<p>Wähle die Anzahl Credits</p>}
                            currentUser={currentUser}
                            onClose={() => setBuyCreditsModalOpen(false)}
                            onError={() => {
                                setBuyCreditsModalOpen(false);
                                navigateToErrorPage();
                            }}
                        />
                    )}
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};

const mapStateToProps = ({ UI, user: { currentUser }, Assets: { assetsLoadingRequests } }) => ({
    scrollingDisabled: isScrollingDisabled({ UI }),
    currentUser: ensureCurrentUser(currentUser),
    assetsLoadingRequests,
});

export default compose(withRouter, connect(mapStateToProps))(VisitorsPage);

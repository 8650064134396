import React from 'react';
import T from 'prop-types';
import { Dot } from 'pure-react-carousel';
import SwipeableViews from 'react-swipeable-views';
import classNames from 'classnames';

import css from './GalleryCarouselPagination.css';

const GalleryCarouselPagination = ({ index, slideCount }) => {
  // const children = [];

  // for (let i = 0; i < slideCount; i += 1) {
  // Make pagination dot smaller if it is at extermities and there are more images to show after
  // let moreState = false;

  // // Case where index is small that 3
  // if (i === 4 && index < 3 && slideCount > 5) {
  //   moreState = true;
  //   // Case where index is larger than the number of slide -4
  // } else if (i === slideCount - 5 && index > slideCount - 4 && slideCount > 5) {
  //   moreState = true;
  //   // General case
  // } else if (
  //   (i > 2 || i < slideCount - 3) &&
  //   index > 2 &&
  //   index < slideCount - 3 &&
  //   (index >= i + 2 || index <= i - 2)
  // ) {
  //   moreState = true;
  // }

  //   const iconStyle = classNames({
  //     [css.dotIcon]: true,
  //     [css.dotIconActive]: i === index,
  //     // [css.dotIconMoreState]: moreState
  //   });

  //   children.push(
  //     <Dot key={i} slide={i} className={classNames(css.dotButton)} style={{ fontSize: 'inherit' }}>
  //       <span className={iconStyle} />
  //     </Dot>
  //   );
  // }

  const getFixedIndex = (index, slideCount) => {
    if (slideCount < 6) {
      return 0;
    } else if (index < 2) {
      return 2;
    } else if (index > slideCount - 3) {
      return slideCount - 3;
    }
    return index;
  };

  const maxNumVisible = 4;
  const shiftEnabled = slideCount > maxNumVisible;
  const stepsNum = Math.ceil(slideCount / maxNumVisible) - 1;
  const steps = Array.from({ length: stepsNum }, (_, i) => (i + 1) * 4);
  const dictionary = steps.reduce((acc, step) => ({ ...acc, [step]: step * 14 }), {});
  const firstMatch = steps.reverse().find(s => s <= index);
  const sectionXShift = dictionary && firstMatch ? dictionary[firstMatch] : 0;
  const isLastSection = index - stepsNum * maxNumVisible >= 0;
  const isOdd = !!(slideCount % maxNumVisible);
  const oddNumShift =
    (isLastSection && isOdd ? maxNumVisible - (slideCount % maxNumVisible) : 0) * 14;
  const shiftX = sectionXShift + oddNumShift;

  return (
    <div className={css.paginationWrapper} datatype="slider-pagination">
      <SwipeableViews
        index={getFixedIndex(index, slideCount)}
        style={{
          boxSizing: 'border-box',

          marginLeft: shiftX && shiftEnabled && `-${shiftX}px`,
        }}
        slideStyle={{ width: '14px', height: '14px' }}
        disabled
      >
        {Array.from({ length: slideCount }, (_, i) => (
          <Dot key={i} slide={i} className={classNames(css.dotButton)}>
            <span
              className={classNames({
                [css.dotIcon]: true,
                [css.dotIconActive]: i === index,
                // [css.dotIconMoreState]: moreState
              })}
            />
          </Dot>
        ))}
      </SwipeableViews>
    </div>
  );
};

GalleryCarouselPagination.propTypes = {
  index: T.number.isRequired,
  slideCount: T.number.isRequired,
};

export default GalleryCarouselPagination;

import React, { useState } from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import { EditComponent } from '..';
import { genders, breeds, colors } from '../../../../marketplace-custom-config';
import { horseTypeIcon, rassIcon, genderIcon, alterIcon, stockmassIcon, farbeIcon } from './icons';
import css from './SectionAbout.css';
import { ToggleText } from '../../../../components';
import { getHeightByNumberOfLines } from '../../../../util/data';

const DESKTOP_NUMBER_OF_LINES = 3;
const MOB_NUMBER_OF_LINES = 6;

const SectionAbout = ({ description, currentListing, isOwnListing, editParams, isMobile }) => {
    const [descRef, setDescRef] = useState(null);
    const {
        attributes: { publicData, title },
    } = currentListing;
    const { horseType, breed, gender, age, hight, color } = publicData || {};

    const linesNum = isMobile ? MOB_NUMBER_OF_LINES : DESKTOP_NUMBER_OF_LINES;

    const maxHeight = descRef
        ? getHeightByNumberOfLines(descRef, linesNum)
        : { isExceeded: true, refMaxHeight: 0 };

    return (
        <div className={css.root}>
            <h2 className={css.sectionTitle}>
                <FormattedMessage id="SectionAbout.title" values={{ listingTitle: title }} />

                {isOwnListing ? <EditComponent pageName="info" editParams={editParams} /> : null}
            </h2>

            <div className={css.sectionContent}>
                {horseType ? (
                    <p className={css.item}>
                        <img src={horseTypeIcon} />
                        <FormattedMessage
                            id={`EditListingDescriptionForm.horseType-${horseType}`}
                        />
                    </p>
                ) : null}

                {breed ? (
                    <p className={css.item}>
                        <img src={rassIcon} />
                        {breeds.find(r => r.key == breed)?.label}
                    </p>
                ) : null}

                {gender ? (
                    <p className={css.item}>
                        <img src={genderIcon} />
                        {genders.find(r => r.key == gender)?.label}
                    </p>
                ) : null}

                {age ? (
                    <p className={css.item}>
                        <img src={alterIcon} />
                        {age === 1 ? `1 Jahr` : `${age} Jahre`}
                    </p>
                ) : null}

                {hight ? (
                    <p className={css.item}>
                        <img src={stockmassIcon} />
                        {`${hight} cm`}
                    </p>
                ) : null}

                {color ? (
                    <p className={css.item}>
                        <img src={farbeIcon} />
                        {colors.find(r => r.key == color)?.label}
                    </p>
                ) : null}
            </div>
            {description && (
                <ToggleText key={isMobile} maxHeight={maxHeight.refMaxHeight}>
                    <p ref={setDescRef} className={css.listingDesc}>
                        {description}
                    </p>
                </ToggleText>
            )}
        </div>
    );
};

export default SectionAbout;

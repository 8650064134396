const getValueForNumberOrUnitString = value => {
    if (typeof value === 'number') return value;
    if (typeof value !== 'string') return undefined;
    const [stringValue, _unit] = value.split(' ');
    return parseInt(stringValue, 10);
};

const isNullish = value => value === null || value === undefined;

export const weightToBucket = weight => {
    const value = getValueForNumberOrUnitString(weight);
    if (isNullish(value)) return undefined;
    if (value < 55) return '<55 kg';
    if (value <= 70) return '55-70 kg';
    if (value <= 85) return '70-85 kg';
    if (value <= 100) return '85-100 kg';
    if (value > 100) return '>100 kg';
};

export const heightToBucket = height => {
    const value = getValueForNumberOrUnitString(height);
    if (isNullish(value)) return undefined;
    if (value < 155) return '<155 cm';
    if (value <= 170) return '155-170 cm';
    if (value <= 185) return '170-185 cm';
    if (value > 185) return '>185 cm';
};

export const distanceToBucket = distance => {
    const value = getValueForNumberOrUnitString(distance);
    if (isNullish(value)) return undefined;
    if (value < 5) return '<5 km';
    if (value <= 10) return '5-10 km';
    if (value <= 15) return '10-15 km';
    if (value <= 20) return '15-20 km';
    if (value <= 30) return '20-30 km';
    if (value <= 40) return '30-40 km';
    if (value <= 50) return '40-50 km';
    if (value > 50) return '>50 km';
};

export const matchingScoreToBucket = score => {
    const value = getValueForNumberOrUnitString(score);
    if (isNullish(value)) return undefined;
    if (value <= 30) return '0-30%';
    if (value <= 69) return '31-69%';
    if (value > 50) return '70-100%';
};

export const startAndEndDateToBucket = (startDate, endDate) =>
    startDate && endDate ? 'Befristet' : startDate ? 'Ab Datum' : 'Ab Sofort';

import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { arrayOf, bool, func, oneOfType, shape, string } from 'prop-types';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { priceData } from '../../util/currency';
import config from '../../config';
import { ModalPortal, PrimaryButton } from '../../components';
import { BookingDatesForm } from '../../forms';
import { SelectStartDateIcon } from './Icons';
import css from './BookingPanel.css';

const BookingPanel = props => {
  const {
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    onManageDisableScrolling,
    timeSlots,
    fetchTimeSlotsError,
    intl,
    isOpen,
    onToggle,
  } = props;

  const price = listing.attributes.price;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingDatesForm = hasListingState && !isClosed;
  const { formattedPrice, priceTitle } = priceData(price, intl);

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'BookingPanel.perNight'
    : isDaily
    ? 'BookingPanel.perDay'
    : 'BookingPanel.perUnit';

  return (
    <React.Fragment>
      <ModalPortal
        contentClassName={css.modalContent}
        id="BookingDatesFormInModal"
        isOpen={isOpen}
        onClose={() => onToggle(false)}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <SelectStartDateIcon rootClassName={css.modalIcon} />
        <h1>
          <FormattedMessage id="BookingPanel.mobileModalHeading" />
        </h1>
        <p>
          <FormattedMessage id="BookingPanel.mobileModalBody" />
        </p>
        {showBookingDatesForm ? (
          <BookingDatesForm
            className={css.bookingForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            isOwnListing={isOwnListing}
            timeSlots={timeSlots}
            fetchTimeSlotsError={fetchTimeSlotsError}
          />
        ) : null}
      </ModalPortal>
      <div className={css.openBookingForm}>
        <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>
        {showBookingDatesForm ? (
          <PrimaryButton rootClassName={css.bookButton} onClick={() => onToggle(true)}>
            <FormattedMessage id="BookingPanel.ctaButtonMessage" />
          </PrimaryButton>
        ) : (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  isOwnListing: false,
  unitType: config.bookingUnitType,
  timeSlots: null,
  fetchTimeSlotsError: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(BookingPanel);

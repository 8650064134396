/**
 * Non recurrent actions
 */
export const CREDITS_ACTION_USER_NOT_AVAILABLE = 'user_not_available';
export const CREDITS_ACTION_JOIN_DATE_REACHED_THRESHOLD = 'joindate_threshold_reached';
export const CREDITS_ACTION_USER_BIRTHDAY = 'user_birthday';
export const CREDITS_ACTION_LISTING_PUBLISHED = 'listing_published';
export const CREDITS_ACTION_PROFILE_VERIFIED = 'profile_verified';
export const CREDITS_ACTION_PROFILE_COMPLETE = 'profile_complete';
export const CREDITS_ACTION_AVAILABILITY_CONFIRMED = 'availability_confirmed';

export const CREDITS_ACTION_INITIAL_PURCHASE = 'initial_purchase';
export const CREDITS_ACTION_NEW_RECOMMENDATION_RECEIVED = 'recommendation_received';
export const CREDITS_ACTION_REFERRAL_INVITER = 'referral_to_inviter';
export const CREDITS_ACTION_REFERRAL_INVITED_USER = 'referral_invited_user';
/**
 * Recurrent actions
 */
export const CREDITS_ACTION_TOP_UP = 'top_up';
export const CREDITS_ACTION_WITHDRAW = 'withdraw';

export const getCreditsLabel = data => {
    const config = {
        [CREDITS_ACTION_USER_NOT_AVAILABLE]: 'Bonus-Credits',
        [CREDITS_ACTION_JOIN_DATE_REACHED_THRESHOLD]: 'Treuegeschenk',
        [CREDITS_ACTION_USER_BIRTHDAY]: 'Geburtstagsgeschenk',
        [CREDITS_ACTION_LISTING_PUBLISHED]: 'Erstes Inserat',
        [CREDITS_ACTION_PROFILE_VERIFIED]: 'Profil verifiziert',
        [CREDITS_ACTION_AVAILABILITY_CONFIRMED]: 'Verfügbarkeit bestätigt',
        [CREDITS_ACTION_PROFILE_COMPLETE]: 'Profil vervollständigt',
        [CREDITS_ACTION_NEW_RECOMMENDATION_RECEIVED]: 'Empfehlung erhalten',
        [CREDITS_ACTION_REFERRAL_INVITER]: 'Weiterempfehlung',
        [CREDITS_ACTION_REFERRAL_INVITED_USER]: 'Weiterempfehlung',
        [CREDITS_ACTION_TOP_UP]: 'Kauf',
        [CREDITS_ACTION_WITHDRAW]: 'Freischaltung',
        [CREDITS_ACTION_INITIAL_PURCHASE]: 'Startguthaben',
    };

    return config[data.eventName];
};

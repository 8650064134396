import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';
import { ArrowNextIcon } from '../../icons';
import { NamedLink } from '../../components';

import css from './SignupSettings.css';

const SignupSettings = ({ riderClickHandler, horseownerClickHandler }) => (
    <main>
        <section className={css.optionHolder}>
            <div className={css.option} onClick={riderClickHandler}>
                <div
                    className={css.presudoAvatar}
                    style={{
                        backgroundImage: `url('https://horsedeal.imgix.net/static/signUp/ich-bin-pferdemensch.svg')`,
                    }}
                />
                <div>
                    <h3>
                        <FormattedMessage id="SignupSettings.userTypeRider" />
                    </h3>
                    <p>
                        <FormattedMessage id="SignupSettings.userTypeRiderDesc" />
                    </p>
                </div>
                <ArrowNextIcon />
            </div>
            <div className={css.option} onClick={horseownerClickHandler}>
                <div
                    className={css.presudoAvatar}
                    style={{
                        backgroundImage: `url('https://horsedeal.imgix.net/static/signUp/ich-bin-pferdebesitzer-in.svg')`,
                    }}
                />
                <div>
                    <h3>
                        <FormattedMessage id="SignupSettings.userTypeHorseowner" />
                    </h3>
                    <p>
                        <FormattedMessage id="SignupSettings.userTypeHorseownerDesc" />
                    </p>
                </div>
                <ArrowNextIcon />
            </div>
        </section>

        <p className={css.changeAuthDesc}>
            <FormattedMessage id="SignupSettings.alreadyHasAcc" />{' '}
            <NamedLink name="LoginPage">
                <FormattedMessage id="SignupSettings.loginLink" />
            </NamedLink>
        </p>
    </main>
);

export default SignupSettings;

import React from 'react';
import { NamedLink } from '../../../../components';
import { ClockIcon, IconCheckmark, MapMarkerIcon } from '../../../../icons';
import {
    IconCalendar,
    IconFlash,
    IconPersonSafety,
    IconRadius,
    IconThumbUp,
    IconWalking,
    IconRuler,
    IconWeight,
    IconCertificate,
    IconEducation,
} from './icons';
import { IconChart, IconPencilLetter } from '../../icons';

import css from './SectionMatchingCriteria.css';

export default () => (
    <section className={css.root}>
        <header id="kriterien">
            <h2 className={css.subHeading}>Matching-Kriterien</h2>
            <p className={css.heading}>Das sind die Matching-Kriterien</p>
            <p className={css.description}>
                {`Unser Algorithmus kann anhand verschiedener Kriterien die Übereinstimmung berechnen. \nDazu verwenden wir uns auf die Daten aus deinem Profil oder Inserat.`}
            </p>
        </header>

        <main className={css.content}>
            <div className={css.criteriaBlock}>
                <h3>Diese Kriterien werden berücksichtigt</h3>
                <div className={css.criteriaItemHolder}>
                    <div className={css.criteriaItem}>
                        <MapMarkerIcon />
                        <p>Standort</p>
                    </div>
                    <div className={css.criteriaItem}>
                        <IconRadius />
                        <p>Radius</p>
                    </div>
                    <div className={css.criteriaItem}>
                        <IconWalking />
                        <p>Mobilität</p>
                    </div>
                    <div className={css.criteriaItem}>
                        <ClockIcon />
                        <p>Verfügbarkeit</p>
                    </div>
                    <div className={css.criteriaItem}>
                        <IconCalendar />
                        <p>Alter</p>
                    </div>
                    <div className={css.criteriaItem}>
                        <IconCheckmark />
                        <p>Interessen</p>
                    </div>
                    <div className={css.criteriaItem}>
                        <IconFlash />
                        <p>Disziplinen</p>
                    </div>
                    <div className={css.criteriaItem}>
                        <IconChart />
                        <p>Niveau</p>
                    </div>
                    <div className={css.criteriaItem}>
                        <IconPersonSafety />
                        <p>Qualifikationen</p>
                    </div>
                    <div className={css.criteriaItem}>
                        <IconThumbUp />
                        <p>Besondere Skills</p>
                    </div>
                </div>
            </div>
            <div className={css.criteriaBlock}>
                <h3>Diese Kriterien werden nicht berücksichtigt</h3>
                <div className={css.criteriaItemHolder}>
                    <div className={css.criteriaItem}>
                        <IconPencilLetter />
                        <p>Beschreibung</p>
                    </div>
                    <div className={css.criteriaItem}>
                        <IconRuler />
                        <p>Grösse</p>
                    </div>
                    <div className={css.criteriaItem}>
                        <IconWeight />
                        <p>Gewicht</p>
                    </div>
                    <div className={css.criteriaItem}>
                        <IconThumbUp />
                        <p>Empfehlungen</p>
                    </div>
                    <div className={css.criteriaItem}>
                        <IconCertificate />
                        <p>Auszeichnungen</p>
                    </div>
                    <div className={css.criteriaItem}>
                        <IconEducation />
                        <p>Ausbildungen</p>
                    </div>
                </div>
            </div>
        </main>

        <footer className={css.footer}>
            <NamedLink name="SignupPage" className={css.subLinkPrimary}>
                Kostenlos registrieren
            </NamedLink>
        </footer>
    </section>
);

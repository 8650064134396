import { getTempListingData } from './localStorage';
import { LISTING_STATE_DRAFT, LISTING_STATE_PENDING_APPROVAL } from './types';
import {
    createSlug,
    LISTING_PAGE_DRAFT_VARIANT,
    LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from './urlHelpers';

/**
 * Insert author and author profile image into listing's data
 * might be used for listing card
 * @param {Object} listing sdk listing object
 * @param {Array} included sdk included array
 * @returns sdk listing | null
 */
export const insertAuthorDataIntoListing = (listing, included, skipCheck = false) => {
    const {
        relationships = {},
        attributes: {
            state,
            publicData: { userRepresentationId: isUserListing },
        },
    } = listing;

    const isValidListing = state === 'published';
    if (!isValidListing) {
        return null;
    }

    const { images: relationshipsImage, author: relationshipsAuthor } = relationships || {
        images: { data: [] },
        author: { data: { id: {} } },
    };

    if (isUserListing || skipCheck) {
        const {
            data: {
                id: { uuid: authorId },
            },
        } = relationshipsAuthor;

        const dictionary = (included || []).reduce(
            (acc, item) => {
                const {
                    type,
                    id: { uuid },
                } = item;

                acc[type] = { ...acc[type], [uuid]: item };
                return acc;
            },
            { image: {}, user: {} }
        );
        /**
         * if a listing is a user-listing (rider or horseowner)
         * add author profile image to a listing's data
         */

        const authorDataIncluded = dictionary.user[authorId];
        const authorImageId =
            authorDataIncluded &&
            authorDataIncluded.relationships &&
            authorDataIncluded.relationships.profileImage &&
            authorDataIncluded.relationships.profileImage.data
                ? authorDataIncluded.relationships.profileImage.data.id.uuid
                : undefined;
        const authorImageIncluded = dictionary.image[authorImageId];

        return {
            ...listing,
            author: {
                ...authorDataIncluded,
                profileImage: authorImageIncluded,
            },
        };
    }
    /**
     * listing's images are stored inside included field
     */
    return {
        ...listing,
        images:
            relationshipsImage && relationshipsImage.data
                ? (included || []).filter(
                      ({ type, id: { uuid: imageId } }) =>
                          type === 'image' &&
                          relationshipsImage.data.some(({ id: { uuid } }) => uuid === imageId)
                  )
                : [],
    };
};

/**
 * change author name Pferdemensch upon certain condition
 * @param {object} author sdk user
 * @return object sdk user
 */
export const anonymizeListingAuthor = author => ({
    ...author,
    attributes: {
        ...author.attributes,
        profile: {
            ...author.attributes.profile,
            abbreviatedName: 'BS',
            displayName: 'Besitzer:in',
            isAnonym: true,
        },
    },
});

/**
 * resolve listing state and get corresp. params & name for navigation
 * @param {object} currentListing
 * @returns params: object, name: string
 */
export const getListingNavParams = currentListing => {
    const {
        id: { uuid: id },
        attributes: { state },
    } = currentListing;

    const slug = createSlug(currentListing);

    const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
    const isDraft = state === LISTING_STATE_DRAFT;
    const variant = isPendingApproval
        ? LISTING_PAGE_PENDING_APPROVAL_VARIANT
        : isDraft
        ? LISTING_PAGE_DRAFT_VARIANT
        : null;

    const params = !!variant
        ? {
              id,
              slug,
              variant,
          }
        : {
              id,
              slug,
          };

    return { params, name: !!variant ? 'ListingPageVariant' : 'ListingPage' };
};
/**
 * Calculates progress taking into account fields filled
 * @returns string
 */
export const calculateProgressForOfflineMode = () => {
    const tabNum = 1;
    const initialProgress = 0;

    const tempListingData = getTempListingData();

    if (!tempListingData || Object.keys(tempListingData).length === 0) {
        return `${tabNum}-${initialProgress}`;
    }

    const calculateValue = value => (!!value ? 1 : 0);

    const {
        horseType,
        startDate,
        availability,
        desiredExperience,
        activities = [],
        desiredDisciplines = [],
        desiredLevels = {},
        skills = [],
    } = tempListingData;

    const progress = [
        { value: horseType },
        { value: startDate },
        { value: availability },
        { value: desiredExperience },
        { value: activities && activities.length },
        { value: desiredDisciplines && desiredDisciplines.length },
        { value: desiredLevels && Object.keys(desiredLevels).length },
        { value: skills && skills.length },
    ].reduce((acc, { value }) => acc + calculateValue(value), 0);

    return `${tabNum}-${progress}`;
};

/**
 * Combine sdk user data with included profileImage
 * @param {*} user - sdk user data with included field
 * @param {*} state - redux store state
 * @returns sdk user
 */
export const insertProfileImageToUserData = (user, state) => {
    const {
        relationships: { profileImage },
    } = user || { relationships: { profileImage: null } };

    const {
        marketplaceData: {
            entities: { image: imageEntities },
        },
    } = state;

    if (!profileImage || !profileImage.data || !imageEntities[profileImage.data.id.uuid]) {
        return user;
    }

    return {
        ...user,
        profileImage: {
            ...imageEntities[profileImage.data.id.uuid],
        },
    };
};

import React from 'react';

export default ({ rootClassName }) => (
    <svg
        width="193"
        height="40"
        viewBox="0 0 193 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={rootClassName}
    >
        <path
            d="M10.0942 11.0056H14.2202V29.1098H10.0942V21.9606H4.12591V29.1098H0V11.0056H4.12591V18.2615H10.0942V11.0056Z"
            fill="#1A2B49"
        />
        <path
            d="M17.343 20.0471C17.343 14.9252 21.4191 10.6926 26.5907 10.6926C31.6912 10.6926 35.8171 14.9252 35.8171 20.0471C35.8171 25.1689 31.6912 29.4299 26.5907 29.4299C21.412 29.4299 17.343 25.176 17.343 20.0471ZM31.6628 20.0471C31.6628 17.0238 29.5856 14.5838 26.5623 14.5838C23.539 14.5838 21.4903 17.0309 21.4903 20.0471C21.4903 23.0704 23.5105 25.5388 26.5623 25.5388C29.5856 25.5388 31.6628 23.0704 31.6628 20.0471Z"
            fill="#1A2B49"
        />
        <path
            d="M48.0384 29.1169L43.884 22.7573H43.0446V29.1169H38.9187V11.0056H46.3311C50.5353 11.0056 52.4275 14.0005 52.4275 16.9953C52.4275 19.542 50.5353 21.9891 48.1949 22.5155L52.8472 29.1098H48.0384V29.1169ZM43.0446 14.6122V19.4993H45.6695C47.4835 19.4993 48.2731 18.3184 48.2731 17.1091C48.2731 15.8998 47.4551 14.6122 45.7762 14.6122H43.0446Z"
            fill="#1A2B49"
        />
        <path
            d="M54.8389 23.8315H58.9648C58.9648 24.991 60.1172 25.8802 61.5399 25.8802C62.8773 25.8802 63.9017 25.1973 63.9017 24.0165C63.9017 23.3051 63.5104 22.6506 61.9027 22.1242L59.7473 21.4698C55.7494 20.3885 54.9883 17.9485 54.9883 16.2413C54.9883 12.9832 57.7768 10.6926 61.3763 10.6926C64.8478 10.6926 67.5012 12.7698 67.5012 16.2911H63.3753C63.3753 15.2169 62.7208 14.2921 61.2981 14.2921C60.0105 14.2921 59.1711 15.16 59.1711 16.1559C59.1711 16.5258 59.2778 17.3368 60.9566 17.8134L62.8489 18.418C65.424 19.2076 68.0489 20.5237 68.0489 23.8031C68.0489 27.6942 64.6842 29.4299 61.3763 29.4299C57.5136 29.4299 54.8318 27.1678 54.8318 23.8315H54.8389Z"
            fill="#1A2B49"
        />
        <path
            d="M75.3405 25.5175H83.0659V29.117H71.2146V11.0128H82.8312V14.6123H75.3405V18.3469H81.9135V22.0531H75.3405V25.5246V25.5175Z"
            fill="#1A2B49"
        />
        <path
            d="M93.5728 11.0056C98.8013 11.0056 101.825 14.9466 101.825 20.0968C101.825 25.2187 98.8013 29.1098 93.5728 29.1098H86.4236V11.0056H93.5728ZM93.5728 25.5174C96.2831 25.5174 97.6702 22.9138 97.6702 20.104C97.6702 17.2656 96.276 14.6122 93.5728 14.6122H90.5495V25.5174H93.5728Z"
            fill="#1A2B49"
        />
        <path
            d="M109.13 25.5175H116.856V29.117H105.004V11.0128H116.621V14.6123H109.13V18.3469H115.703V22.0531H109.13V25.5246V25.5175Z"
            fill="#1A2B49"
        />
        <path
            d="M131.417 29.117L130.052 24.9128H123.692L122.355 29.117H117.937L124.638 11.0128H129.134L135.806 29.117H131.417ZM124.816 21.3346H128.892L126.843 14.9466L124.823 21.3346H124.816Z"
            fill="#1A2B49"
        />
        <path d="M141.767 25.5175H148.419V29.117H137.642V11.0128H141.767V25.5175Z" fill="#1A2B49" />
        <path
            d="M176.696 13.0473C176.66 13.0473 176.625 13.0473 176.589 13.0401C176.191 12.9832 175.913 12.6062 175.97 12.2078C176.127 11.155 177.03 10.3085 178.026 10.28C179.086 10.2516 180.06 11.1052 180.224 12.2007C180.288 12.5991 180.011 12.9761 179.605 13.0401C179.207 13.097 178.83 12.8267 178.766 12.4213C178.709 12.0656 178.353 11.7312 178.061 11.7455C177.763 11.7526 177.464 12.0727 177.407 12.4213C177.35 12.784 177.037 13.0473 176.681 13.0473H176.696Z"
            fill="#8E2992"
        />
        <path
            d="M169.326 21.2564C169.864 21.2564 170.3 20.6576 170.3 19.919C170.3 19.1804 169.864 18.5817 169.326 18.5817C168.788 18.5817 168.351 19.1804 168.351 19.919C168.351 20.6576 168.788 21.2564 169.326 21.2564Z"
            fill="#8E2992"
        />
        <path
            d="M169.326 39.9226C169.034 39.9226 168.757 39.8087 168.543 39.6025L155.945 27.0042C152.04 23.0917 152.04 16.7463 155.945 12.8481C157.837 10.9558 160.348 9.91725 163.023 9.91725C165.328 9.91725 167.512 10.6926 169.276 12.1082L175.152 6.23238C174.028 4.81677 173.523 2.98857 173.786 1.15325C173.829 0.847363 174.007 0.569931 174.263 0.39209C174.526 0.221363 174.846 0.164454 175.145 0.242704L185.367 2.83918C185.474 2.86764 185.659 2.91743 185.766 2.94589L185.844 2.96723C185.844 2.96723 185.872 2.96723 185.879 2.97434C187.444 3.45095 188.888 4.3117 190.055 5.47834C193.96 9.38373 193.96 15.7291 190.055 19.6345L170.087 39.6025C169.881 39.8087 169.603 39.9226 169.305 39.9226H169.326ZM163.03 12.1154C160.946 12.1154 158.983 12.9263 157.51 14.406C154.466 17.4506 154.466 22.4017 157.51 25.4463L169.333 37.2692L188.526 18.0766C191.57 15.032 191.563 10.0809 188.526 7.03623C187.622 6.13279 186.498 5.457 185.282 5.08709C185.26 5.08709 185.239 5.07286 185.204 5.06575C185.104 5.03729 184.933 4.9875 184.841 4.96616L175.984 2.71825C176.127 3.75684 176.632 4.71718 177.435 5.42854C177.67 5.63484 177.805 5.94073 177.805 6.25373C177.805 6.56673 177.663 6.87261 177.443 7.07179L170.891 13.6235L178.325 21.0572C178.751 21.484 178.751 22.1883 178.325 22.6151L176.141 24.799C174.441 26.4991 172.029 27.1892 169.689 26.6414C168.437 26.3497 167.284 25.7166 166.367 24.799C163.713 22.1527 163.678 17.8063 166.281 15.1173C166.367 15.032 166.637 14.7545 167.711 13.6804C166.367 12.6702 164.738 12.1225 163.03 12.1225V12.1154ZM167.868 16.6325C166.089 18.4607 166.118 21.42 167.925 23.2269C168.551 23.8529 169.333 24.2868 170.187 24.486C171.78 24.8559 173.423 24.3864 174.583 23.234L175.984 21.8326L169.333 15.1813C168.721 15.7931 167.96 16.5543 167.875 16.6396L167.868 16.6325Z"
            fill="#8E2992"
        />
    </svg>
);

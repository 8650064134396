import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ModalPortal } from '../../components';
import { ReviewForm } from '../../forms';

import css from './ReviewModal.css';

const ReviewModal = ({
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onClose: onCloseModal,
    onManageDisableScrolling,
    onrespondOnCustomerInquiry,
    revieweeName,
    reviewSent,
    sendReviewInProgress,
    sendReviewError,
    otherPartyUserType,
    otherParty,
    currentUser,
    currentListing,
    inquiryError,
    transactionRequestInProgress,
}) => {
    const [refreshModalScrollPosKey, setRefreshModalScrollPosKey] = useState();
    const classes = classNames(rootClassName || css.root, className);
    const closeButtonMessage = intl.formatMessage({ id: 'Modal.close' });

    const reviewee = <span className={css.reviewee}>{revieweeName}</span>;

    return (
        <ModalPortal
            id={id}
            containerClassName={classes}
            contentClassName={css.modalContent}
            isOpen={isOpen}
            onClose={onCloseModal}
            onManageDisableScrolling={onManageDisableScrolling}
            closeButtonMessage={closeButtonMessage}
            unsetKey={refreshModalScrollPosKey}
            containerClassNameJoined
            isSticky
        >
            <h3 className={css.modalTitle}>
                <FormattedMessage id="ReviewModal.title" values={{ revieweeName: reviewee }} />
            </h3>
            <p className={css.modalMessage}>
                <FormattedMessage id="ReviewModal.description" />
            </p>
            {inquiryError && (
                <p className={css.error}>
                    {(typeof inquiryError === 'string' ? inquiryError : inquiryError.message) ||
                        'Something went wrong. Try later.'}
                </p>
            )}
            <ReviewForm
                onSubmit={onrespondOnCustomerInquiry}
                reviewSent={reviewSent}
                sendReviewInProgress={sendReviewInProgress}
                sendReviewError={sendReviewError}
                otherPartyUserType={otherPartyUserType}
                otherParty={otherParty}
                currentUser={currentUser}
                currentListing={currentListing}
                transactionRequestInProgress={transactionRequestInProgress}
                setRefreshModalScrollPosKey={setRefreshModalScrollPosKey}
            />
        </ModalPortal>
    );
};

const { bool, string } = PropTypes;

ReviewModal.defaultProps = {
    className: null,
    rootClassName: null,
    reviewSent: false,
    sendReviewInProgress: false,
    sendReviewError: null,
};

ReviewModal.propTypes = {
    className: string,
    rootClassName: string,
    intl: intlShape.isRequired,
    reviewSent: bool,
    sendReviewInProgress: bool,
    sendReviewError: propTypes.error,
};

export default injectIntl(ReviewModal);

import React, { useState } from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney, formatPriceWithApostropheSeparators } from '../../util/currency';
import { propTypes } from '../../util/types';
import { isInteger, hundredthsIsZero } from '../../util/data';
import { Tooltip } from '../../components';
import { TooltipIcon } from './Icons';
import { TOOLTIP_DATA_REQUEST_ATTR } from '../../components/Tooltip/Tooltip';
import css from './BookingBreakdown.css';


const LineItemBasePriceMaybe = props => {
  const { transaction, unitType, intl, isCustomer } = props;

  const faqLink = isCustomer ? 'https://www.horsedeal.com/reitbeteiligung-finden' : "https://www.horsedeal.com/reitbeteiligung-anbieten";
  const [tooltipVisible, setTooltipVisibility] = useState(false);
  // Find correct line-item for given unitType prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
  // These are defined in '../../util/types';
  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );

  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : 0;
  const numericPrice = unitPurchase ? unitPurchase.lineTotal.amount / 100 : 0
  const summary = isInteger(numericPrice) || !hundredthsIsZero(numericPrice) ? total : `${total}`;
  const formattedPrice = formatPriceWithApostropheSeparators(summary);

  const tooltipContent = (
    <div className={css.tooltipContent}>
      <h5>
        <FormattedMessage id={'BookingBreakdown.baseUnitNight'} />
      </h5>
      <p>
        <FormattedMessage id={'BookingBreakdown.tooltipFlexPriceText'} />
      </p>
      <span
        className={css.tooltipLink}
        onClick={() => window.location.replace(faqLink)}
        data-role={TOOLTIP_DATA_REQUEST_ATTR}
      >
        <FormattedMessage id={'BookingBreakdown.tooltipLinkText'} />
      </span>
    </div>
  );

  return quantity && total ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id={'BookingBreakdown.baseUnitNight'} />
      </span>
      <Tooltip
        content={tooltipContent}
        rootClassName={css.tooltipWrapper}
        tooltipClassName={css.tooltip}
        staticTooltipState={tooltipVisible}
        outsideClickHandler={setTooltipVisibility}
        isStatic
      >
        <div
          className={css.infoItem}
          data-role={TOOLTIP_DATA_REQUEST_ATTR}
          onClick={() => setTooltipVisibility(prevState => !prevState)}
        >
          <TooltipIcon />
        </div>
      </Tooltip>
      <span className={css.dayInfo}>{formattedPrice}</span>
    </div>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;

import { denormalisedResponseEntities, ensureOwnListing } from '../util/data';
import config from '../config';
import { storableError } from '../util/errors';
import { LISTING_STATE_DRAFT } from '../util/types';
import * as log from '../util/log';
import { authInfo } from './Auth.duck';
import { stripeAccountCreateSuccess } from './stripe.duck.js';
import { addMarketplaceEntities } from './marketplaceData.duck';
import { denormalize } from '../util/api';

const { userTypeHorseowner, userTypeRider } = config;

// ================ Action types ================ //

export const CURRENT_USER_SHOW_REQUEST = 'app/user/CURRENT_USER_SHOW_REQUEST';
export const CURRENT_USER_SHOW_SUCCESS = 'app/user/CURRENT_USER_SHOW_SUCCESS';
export const CURRENT_USER_SHOW_ERROR = 'app/user/CURRENT_USER_SHOW_ERROR';

export const CLEAR_CURRENT_USER = 'app/user/CLEAR_CURRENT_USER';

export const FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST =
    'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST';
export const FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS =
    'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_LISTINGS_ERROR =
    'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_ERROR';

export const FETCH_CURRENT_USER_HAS_ORDERS_REQUEST =
    'app/user/FETCH_CURRENT_USER_HAS_ORDERS_REQUEST';
export const FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS =
    'app/user/FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_ORDERS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_ERROR';

export const FETCH_CURRENT_USER_UNREAD_MESSAGES_IN_PROGRESS =
    'app/user/FETCH_CURRENT_USER_UNREAD_MESSAGES_IN_PROGRESS';
export const FETCH_CURRENT_USER_UNREAD_MESSAGES_SUCCESS =
    'app/user/FETCH_CURRENT_USER_UNREAD_MESSAGES_SUCCESS';
export const FETCH_CURRENT_USER_UNREAD_MESSAGES_ERROR =
    'app/user/FETCH_CURRENT_USER_UNREAD_MESSAGES_ERROR';
export const SET_CURRENT_USER_LISTINGS_INFO = 'app/user/SET_CURRENT_USER_LISTINGS_INFO';

export const MARK_UNREAD_MESSAGES_AS_VIEWED_SALE = 'app/user/MARK_UNREAD_MESSAGES_AS_VIEWED_SALE';
export const MARK_UNREAD_MESSAGES_AS_VIEWED_ORDER = 'app/user/MARK_UNREAD_MESSAGES_AS_VIEWED_ORDER';

export const SEND_VERIFICATION_EMAIL_REQUEST = 'app/user/SEND_VERIFICATION_EMAIL_REQUEST';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'app/user/SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_ERROR = 'app/user/SEND_VERIFICATION_EMAIL_ERROR';

export const SEND_VERIFICATION_OTP_REQUEST = 'app/user/SEND_VERIFICATION_OTP_REQUEST';
export const SEND_VERIFICATION_OTP_SUCCESS = 'app/user/SEND_VERIFICATION_OTP_SUCCESS';
export const SEND_VERIFICATION_OTP_ERROR = 'app/user/SEND_VERIFICATION_OTP_ERROR';

export const UPDATE_USER_PROFILE_INFO_REQUEST = 'app/user/UPDATE_USER_PROFILE_INFO_REQUEST';
export const UPDATE_USER_PROFILE_INFO_SUCCESS = 'app/user/UPDATE_USER_PROFILE_INFO_SUCCESS';
export const UPDATE_USER_PROFILE_INFO_FAILURE = 'app/user/UPDATE_USER_PROFILE_INFO_FAILURE';

export const SET_USER_MAIN_LISTING_DATA = 'app/user/SET_USER_MAIN_LISTING_DATA';

// ================ Reducer ================ //

const mergeCurrentUser = (oldCurrentUser, newCurrentUser) => {
    const { id: oId, type: oType, attributes: oAttr, ...oldRelationships } = oldCurrentUser || {};
    const { id, type, attributes, ...relationships } = newCurrentUser || {};

    // Passing null will remove currentUser entity.
    // Only relationships are merged.
    // TODO figure out if sparse fields handling needs a better handling.
    return newCurrentUser === null
        ? null
        : oldCurrentUser === null
        ? newCurrentUser
        : { id, type, attributes, ...oldRelationships, ...relationships };
};

const initialState = {
    currentUser: null,
    currentUserRequestInProgress: null,
    currentUserShowError: null,
    currentUserHasListings: false,
    currentUserHasPublishedListings: false,
    currentUserHasListingsError: null,
    currentUserHasOrders: null, // This is not fetched unless unverified emails exist
    currentUserHasOrdersError: null,
    sendVerificationEmailInProgress: false,
    sendVerificationEmailError: null,
    sendVerificationOtpInProgress: false,
    verificationCode: null,
    sendVerificationOtpError: null,
    updateUserProfileInfoInProgress: false,
    updateUserProfileInfoError: null,
    unreadMessagesInProgress: false,
    currentUserUnreadMessagesRider: 0,
    currentUserUnreadMessagesOwner: 0,
    currentUserMainListing: null,
    unreadMessagesData: null,
};

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case CURRENT_USER_SHOW_REQUEST:
            return { ...state, currentUserShowError: null, currentUserRequestInProgress: true };
        case CURRENT_USER_SHOW_SUCCESS:
            return {
                ...state,
                currentUser: mergeCurrentUser(state.currentUser, payload),
                currentUserRequestInProgress: false,
            };
        case CURRENT_USER_SHOW_ERROR:
            return { ...state, currentUserShowError: payload, currentUserRequestInProgress: false };

        case SET_USER_MAIN_LISTING_DATA:
            return {
                ...state,
                currentUserMainListing: payload,
            };

        case CLEAR_CURRENT_USER:
            return {
                ...state,
                currentUser: null,
                currentUserShowError: null,
                currentUserHasListings: false,
                currentUserHasPublishedListings: false,
                currentUserHasListingsError: null,
                unreadMessagesInProgress: false,
                currentUserUnreadMessagesRider: 0,
                currentUserUnreadMessagesOwner: 0,
                currentUserMainListing: null,
                unreadMessagesData: null,
            };

        case FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST:
            return {
                ...state,
                currentUserHasListingsError: null,
                currentUserHasListings: false,
                currentUserHasPublishedListings: false,
            };
        case FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS:
            return { ...state, currentUserHasListingsError: null };
        case FETCH_CURRENT_USER_HAS_LISTINGS_ERROR:
            return {
                ...state,
                currentUserHasListingsError: payload,
                currentUserHasListings: false,
                currentUserHasPublishedListings: false,
            };
        case SET_CURRENT_USER_LISTINGS_INFO:
            return {
                ...state,
                currentUserHasListings: payload.hasListings,
                currentUserHasPublishedListings: payload.hasPublishedListings,
            };

        case FETCH_CURRENT_USER_UNREAD_MESSAGES_IN_PROGRESS:
            return { ...state, unreadMessagesInProgress: payload };
        case FETCH_CURRENT_USER_UNREAD_MESSAGES_SUCCESS: {
            const {
                messages: {
                    horseowner: { transactions: horseownerTxs },
                    rider: { transactions: riderTxs },
                },
            } = payload;

            const currentUserUnreadMessagesRider = riderTxs.ids.length;
            const currentUserUnreadMessagesOwner = horseownerTxs.ids.length;

            return {
                ...state,
                unreadMessagesData: payload,
                currentUserUnreadMessagesRider,
                currentUserUnreadMessagesOwner,
            };
        }
        case FETCH_CURRENT_USER_UNREAD_MESSAGES_ERROR:
            return { ...state, unreadMessagesError: payload };

        case FETCH_CURRENT_USER_HAS_ORDERS_REQUEST:
            return { ...state, currentUserHasOrdersError: null };
        case FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS:
            return { ...state, currentUserHasOrders: payload.hasOrders };
        case FETCH_CURRENT_USER_HAS_ORDERS_ERROR:
            console.error(payload); // eslint-disable-line
            return { ...state, currentUserHasOrdersError: payload };

        case SEND_VERIFICATION_EMAIL_REQUEST:
            return {
                ...state,
                sendVerificationEmailInProgress: true,
                sendVerificationEmailError: null,
            };
        case SEND_VERIFICATION_EMAIL_SUCCESS:
            return {
                ...state,
                sendVerificationEmailInProgress: false,
            };
        case SEND_VERIFICATION_EMAIL_ERROR:
            return {
                ...state,
                sendVerificationEmailInProgress: false,
                sendVerificationEmailError: payload,
            };
        case SEND_VERIFICATION_OTP_REQUEST:
            return {
                ...state,
                sendVerificationOtpInProgress: true,
                sendVerificationOtpError: null,
            };
        case SEND_VERIFICATION_OTP_SUCCESS:
            return {
                ...state,
                sendVerificationOtpInProgress: false,
                verificationCode: payload.code,
            };
        case SEND_VERIFICATION_OTP_ERROR:
            return {
                ...state,
                sendVerificationOtpInProgress: false,
                sendVerificationOtpError: payload.error,
            };

        case UPDATE_USER_PROFILE_INFO_REQUEST:
            return {
                ...state,
                updateUserProfileInfoInProgress: true,
                updateUserProfileInfoError: null,
            };
        case UPDATE_USER_PROFILE_INFO_SUCCESS:
            return {
                ...state,
                updateUserProfileInfoInProgress: false,
                updateUserProfileInfoError: null,
            };
        case UPDATE_USER_PROFILE_INFO_FAILURE:
            return {
                ...state,
                updateUserProfileInfoInProgress: false,
                updateUserProfileInfoError: payload,
            };
        default:
            return state;
    }
}

// ================ Selectors ================ //

export const hasCurrentUserErrors = ({ user, FavoritePage }) =>
    user.currentUserShowError ||
    user.currentUserHasListingsError ||
    user.currentUserHasOrdersError ||
    FavoritePage.wishlistError;

export const verificationSendingInProgress = state => {
    return state.user.sendVerificationEmailInProgress;
};

export const verificationOtpSendingInProgress = state => {
    return state.user.sendVerificationOtpInProgress;
};

// ================ Action creators ================ //

export const currentUserShowRequest = () => ({ type: CURRENT_USER_SHOW_REQUEST });

export const currentUserShowSuccess = user => ({
    type: CURRENT_USER_SHOW_SUCCESS,
    payload: user,
});

export const currentUserShowError = e => ({
    type: CURRENT_USER_SHOW_ERROR,
    payload: e,
    error: true,
});

export const clearCurrentUser = () => ({ type: CLEAR_CURRENT_USER });

const fetchCurrentUserHasListingsRequest = () => ({
    type: FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST,
});

export const fetchCurrentUserHasListingsSuccess = () => ({
    type: FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS,
});

export const setCurrentUserHasListingsInfo = payload => ({
    type: SET_CURRENT_USER_LISTINGS_INFO,
    payload,
});

const fetchCurrentUserHasListingsError = e => ({
    type: FETCH_CURRENT_USER_HAS_LISTINGS_ERROR,
    error: true,
    payload: e,
});

const fetchCurrentUserUnreadMessagesInProgress = isInProgress => ({
    type: FETCH_CURRENT_USER_UNREAD_MESSAGES_IN_PROGRESS,
    payload: isInProgress,
});

const fetchCurrentUserUnreadMessagesSuccess = unreadMessagesData => ({
    type: FETCH_CURRENT_USER_UNREAD_MESSAGES_SUCCESS,
    payload: unreadMessagesData,
});

const fetchCurrentUserUnreadMessagesFailure = unreadMessagesError => ({
    type: FETCH_CURRENT_USER_UNREAD_MESSAGES_ERROR,
    payload: unreadMessagesError,
});

const fetchCurrentUserHasOrdersRequest = () => ({
    type: FETCH_CURRENT_USER_HAS_ORDERS_REQUEST,
});

export const fetchCurrentUserHasOrdersSuccess = hasOrders => ({
    type: FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS,
    payload: { hasOrders },
});

const fetchCurrentUserHasOrdersError = e => ({
    type: FETCH_CURRENT_USER_HAS_ORDERS_ERROR,
    error: true,
    payload: e,
});

export const sendVerificationEmailRequest = () => ({
    type: SEND_VERIFICATION_EMAIL_REQUEST,
});

export const sendVerificationEmailSuccess = () => ({
    type: SEND_VERIFICATION_EMAIL_SUCCESS,
});

export const sendVerificationEmailError = e => ({
    type: SEND_VERIFICATION_EMAIL_ERROR,
    error: true,
    payload: e,
});

export const sendVerificationOtpRequest = () => ({
    type: SEND_VERIFICATION_OTP_REQUEST,
});

export const sendVerificationOtpSuccess = code => ({
    type: SEND_VERIFICATION_OTP_SUCCESS,
    payload: { code },
});

export const sendVerificationOtpError = error => ({
    type: SEND_VERIFICATION_OTP_ERROR,
    error: true,
    payload: { error },
});

export const updateUserProfileInfoRequest = () => ({
    type: UPDATE_USER_PROFILE_INFO_REQUEST,
});

export const updateUserProfileInfoSuccess = () => ({
    type: UPDATE_USER_PROFILE_INFO_SUCCESS,
});

export const updateUserProfileInfoFailure = error => ({
    type: UPDATE_USER_PROFILE_INFO_FAILURE,
    payload: error,
});

export const setOwnerMainListing = payload => ({
    type: SET_USER_MAIN_LISTING_DATA,
    payload,
});

// ================ Thunks ================ //

export const fetchOwnerMainListing = () => (dispatch, getState, sdk) => {
    const { currentUser } = getState().user;

    if (!currentUser) {
        return;
    }

    const { userType, mainHorseId } = currentUser.attributes.profile.publicData;

    const isOwner = userType === userTypeHorseowner;

    if (!isOwner || !mainHorseId) {
        return;
    }

    return sdk.ownListings
        .show({
            id: mainHorseId,
        })
        .then(({ data: { data } }) => dispatch(setOwnerMainListing(data)))
        .catch(e => {
            // to nothing
        });
};

export const fetchCurrentUserHasListings = () => (dispatch, getState, sdk) => {
    dispatch(fetchCurrentUserHasListingsRequest());
    const { currentUser } = getState().user;

    if (!currentUser) {
        dispatch(fetchCurrentUserHasListingsSuccess());
        return Promise.resolve(null);
    }

    const params = {
        // Since we are only interested in if the user has
        // listings, we only need at most one result.
        page: 1,
        per_page: 1,
    };

    return sdk.ownListings
        .query(params)
        .then(response => {
            const hasListings = response.data.data && response.data.data.length > 0;

            const hasPublishedListings =
                hasListings &&
                ensureOwnListing(response.data.data[0]).attributes.state !== LISTING_STATE_DRAFT;
            dispatch(fetchCurrentUserHasListingsSuccess());
            dispatch(setCurrentUserHasListingsInfo({ hasListings, hasPublishedListings }));
        })
        .catch(e => dispatch(fetchCurrentUserHasListingsError(storableError(e))));
};

export const fetchCurrentUserHasOrders = () => (dispatch, getState, sdk) => {
    dispatch(fetchCurrentUserHasOrdersRequest());

    if (!getState().user.currentUser) {
        dispatch(fetchCurrentUserHasOrdersSuccess(false));
        return Promise.resolve(null);
    }

    const params = {
        only: 'order',
        page: 1,
        per_page: 1,
    };

    return sdk.transactions
        .query(params)
        .then(response => {
            const hasOrders = response.data.data && response.data.data.length > 0;
            dispatch(fetchCurrentUserHasOrdersSuccess(!!hasOrders));
        })
        .catch(e => dispatch(fetchCurrentUserHasOrdersError(storableError(e))));
};

export const fetchUnreadMessages = () => async (dispatch, getState, sdk) => {
    const { currentUser, unreadMessagesInProgress } = getState().user;

    if (unreadMessagesInProgress || !currentUser || !currentUser.id) {
        return;
    }

    const { uuid } = currentUser.id;
    try {
        dispatch(fetchCurrentUserUnreadMessagesInProgress(true));
        fetchCurrentUserUnreadMessagesFailure(null);

        const response = await fetch(`/api/unread-messages/${uuid}/messages`);
        const { unreadMessageData, error, message } = await response.json();

        if (error) {
            return fetchCurrentUserUnreadMessagesFailure(
                message || 'Fetch unread messages data failed.'
            );
        }

        const unreadMessage = unreadMessageData[0];
        const unreadMessageDataNormalized = {
            ...unreadMessage,
            messages: {
                [userTypeRider]: {
                    transactions: denormalize(
                        'transactionId',
                        unreadMessage.messages[userTypeRider].transactions
                    ),
                },
                [userTypeHorseowner]: {
                    transactions: denormalize(
                        'transactionId',
                        unreadMessage.messages[userTypeHorseowner].transactions
                    ),
                },
            },
        };
        return dispatch(fetchCurrentUserUnreadMessagesSuccess(unreadMessageDataNormalized));
    } catch (e) {
        dispatch(
            fetchCurrentUserUnreadMessagesFailure(e.message || 'Fetch unread messages data failed.')
        );
        console.log(e);
    } finally {
        dispatch(fetchCurrentUserUnreadMessagesInProgress(false));
    }
};

export const markUnreadMessageAsViewed = transactionId => async (dispatch, getState, sdk) => {
    const {
        user: {
            currentUser: {
                id: { uuid: currentUserId },
                attributes: {
                    profile: {
                        publicData: { userType },
                    },
                },
            },
            unreadMessagesInProgress,
        },
    } = getState();

    if (!transactionId || !userType || unreadMessagesInProgress) {
        return;
    }

    try {
        dispatch(fetchCurrentUserUnreadMessagesInProgress(true));
        fetchCurrentUserUnreadMessagesFailure(null);

        const response = await fetch(
            `/api/unread-messages/${currentUserId}/${transactionId}/${userType}/delete-message`,
            { method: 'PATCH' }
        );
        const { error, message } = await response.json();

        if (error) {
            throw message || 'Fetch unread messages data failed.';
        }
    } catch (e) {
        console.log('Failed to remove unread messages data.');
    } finally {
        dispatch(fetchCurrentUserUnreadMessagesInProgress(false));
        dispatch(fetchUnreadMessages());
    }
};

export const fetchCurrentUser = (params = null) => (dispatch, getState, sdk) => {
    dispatch(currentUserShowRequest());
    const { isAuthenticated } = getState().Auth;

    if (!isAuthenticated) {
        // Make sure current user is null
        dispatch(currentUserShowSuccess(null));
        return Promise.resolve({});
    }

    const parameters = params || {
        include: ['profileImage', 'stripeAccount'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.currentUser
        .show(parameters)
        .then(async response => {
            const entities = denormalisedResponseEntities(response);
            if (entities.length !== 1) {
                throw new Error('Expected a resource in the sdk.currentUser.show response');
            }
            const currentUser = entities[0];
            // Save stripeAccount to store.stripe.stripeAccount if it exists
            if (currentUser.stripeAccount) {
                dispatch(stripeAccountCreateSuccess(currentUser.stripeAccount));
            }
            // set current user id to the logger
            log.setUserId(currentUser.id.uuid);
            dispatch(currentUserShowSuccess(currentUser));
            dispatch(fetchOwnerMainListing());

            await dispatch(fetchCurrentUserHasListings());

            if (!currentUser.attributes.emailVerified) {
                dispatch(fetchCurrentUserHasOrders());
            }

            return currentUser;
        })
        .then(currentUser => {
            // Make sure auth info is up to date
            dispatch(authInfo());
            return currentUser;
        })
        .catch(e => {
            // Make sure auth info is up to date
            dispatch(authInfo());
            log.error(e, 'fetch-current-user-failed');
            dispatch(currentUserShowError(storableError(e)));
        });
};

export const sendVerificationEmail = () => (dispatch, getState, sdk) => {
    if (verificationSendingInProgress(getState())) {
        return Promise.reject(new Error('Verification email sending already in progress'));
    }
    dispatch(sendVerificationEmailRequest());
    return sdk.currentUser
        .sendVerificationEmail()
        .then(() => dispatch(sendVerificationEmailSuccess()))
        .catch(e => dispatch(sendVerificationEmailError(storableError(e))));
};

export const sendVerificationOtp = phoneNumber => (dispatch, getState, sdk) => {
    if (verificationOtpSendingInProgress(getState())) {
        return Promise.reject(new Error('Verification otp sending already in progress'));
    }

    let otp = Math.floor(100000 + Math.random() * 900000);

    dispatch(sendVerificationOtpRequest());

    const body = {
        to: phoneNumber,
        message: `Dein Bestätigungscode für HorseDeal lautet: ${otp}`,
    };

    return fetch('/api/otp', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then(res => res.json())
        .then(data => {
            if (data.success) {
                dispatch(sendVerificationOtpSuccess(otp));
                return otp;
            } else {
                dispatch(sendVerificationOtpError(data.error));
                throw data.error;
            }
        })
        .catch(e => {
            dispatch(sendVerificationOtpError(e));
            throw e.message || e.data || e;
        });
};

export const updateUserProfileInfo = (params, dataToInsert) => (dispatch, getState, sdk) => {
    const {
        user: {
            currentUser: { updateUserProfileInfoInProgress },
        },
    } = getState();

    if (updateUserProfileInfoInProgress) {
        return Promise.reject({});
    }

    dispatch(updateUserProfileInfoRequest());

    return sdk.currentUser
        .updateProfile(params, {
            expand: true,
            include: ['profile', 'profileImage'],
        })
        .then(response => {
            dispatch(
                addMarketplaceEntities(
                    dataToInsert
                        ? {
                              data: {
                                  data: {
                                      ...dataToInsert,
                                      type: 'currentUser',
                                  },
                              },
                          }
                        : response
                )
            );
            dispatch(updateUserProfileInfoSuccess());
            return response;
        })
        .catch(e => {
            dispatch(updateUserProfileInfoFailure(e));
        });
};

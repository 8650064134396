import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { func, node, number, shape, string, object } from 'prop-types';
import isEqual from 'lodash/isEqual';
import { types as sdkTypes } from '../../util/sdkLoader';
import { encodeLatLngBounds, parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { sdkBoundsToFixedCoordinates, hasSameSDKBounds } from '../../util/maps';
import config from '../../config';
import { MapWithGoogleMap } from './MapWithGoogleMap';
import {
    BOUNDS_FIXED_PRECISION,
    getMapBounds,
    getMapCenter,
    LABEL_HANDLE,
    INFO_CARD_HANDLE,
    fitMapToBounds,
    hasParentWithClassName,
} from './SearchMap.helpers.js';
import { encodeBoundsToStr } from '../TopbarDesktop/TopbarDesktopSearchPanel.helpers';
import {
    markListingAsVisited,
    setActiveListingId,
    setMapBounds,
} from '../../containers/SearchPage/SearchPage.duck';

class SearchMapWithGoogleMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mapInitialized: false,
            infoCardOpen: null,
        };
        this.mouseEventCoord = null;
        this.map = null;
        this.viewportBounds = null;
        this.viewport = null;
        this.onMapLoad = this.onMapLoad.bind(this);
        this.onIdle = this.onIdle.bind(this);

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        // this.onTouchEnd = this.onTouchEnd.bind(this);
        // this.handleMouseUpTouchEnd = this.handleMouseUpTouchEnd.bind(this);

        this.observer = null;
        this.observerDelayId = null;
        this.searchMap = null;
        this.topbarComponent = null;
    }

    componentDidMount() {
        const {
            props: { containerElement },
        } = this;

        const mapContainer = containerElement.props.id
            ? document.getElementById(containerElement.props.id)
            : null;

        if (mapContainer) {
            mapContainer.addEventListener('mousedown', this.onMouseDown);
            mapContainer.addEventListener('mouseup', this.onMouseUp);
            // mapContainer.addEventListener('touchend', this.onTouchEnd);
        }
    }

    async componentDidUpdate(prevProps) {
        const {
            isSearchMapOpenOnVisible,
            isMobileLayout,
            bounds, // uri bounds
            mapBoundsString, // store received from google map bounds
            location,
            searchInProgress,
            mapPanelOpen,
        } = this.props;
        const mapViewChanged = mapPanelOpen !== prevProps.mapPanelOpen;

        if (mapViewChanged) {
            fitMapToBounds({
                map: this.map,
                bounds,
            });

            return;
        }
        if (searchInProgress) return;

        const { mapInitialized } = this.state;

        const locationChanged = !isEqual(prevProps.location, location);

        // If no mapSearch url parameter is given, this is original location search
        const { mapSearch, address } = parse(this.props.location.search, {
            latlng: ['origin'],
        });
        if (locationChanged && !mapSearch) {
            this.viewportBounds = null;
        }

        const fitBoundsIfMapVisibleOnMob =
            !isMobileLayout || locationChanged || (isMobileLayout && isSearchMapOpenOnVisible);

        if (this.map && fitBoundsIfMapVisibleOnMob) {
            const mapBounds = getMapBounds(this.map);
            const currentBounds = mapBounds
                ? sdkBoundsToFixedCoordinates(mapBounds, BOUNDS_FIXED_PRECISION)
                : null;
            // const currentBounds = getMapBounds(this.map);
            // const boundsChanged = !isEqual(bounds, currentBounds);
            const boundsChanged =
                !isEqual(bounds, currentBounds) &&
                (currentBounds
                    ? mapBoundsString !== encodeBoundsToStr(currentBounds, false)
                    : true);

            // const addressIsNotSelected = address === initialSearchAddress[code].address;
            if (!mapBoundsString && mapBounds) {
                this.props.onMapBoundsSetting(mapBounds);
            }

            // Do not call fitMapToBounds if bounds are the same.
            // Our bounds are viewport bounds, and fitBounds will try to add margins around those bounds
            // that would result to zoom-loop (bound change -> fitmap -> bounds change -> ...)
            if (
                // !addressIsNotSelected &&
                !this.viewportBounds &&
                boundsChanged &&
                mapInitialized
            ) {
                fitMapToBounds({
                    map: this.map,
                    bounds,
                });
            }
        }
    }

    onMapViewportChange() {
        const { isMobileLayout, containerElement } = this.props;
        if (isMobileLayout) {
            /** prevent map reloading on mobile keyboard opening;
             * opening mobile keyboard changes viewport height
             * and due to this map coords change, which triggers
             * map reloading
             */
            const mapContainer = containerElement.props.id
                ? document.getElementById(containerElement.props.id)
                : null;

            if (mapContainer) {
                const { innerHeight: ih } = window;
                const height = `${ih}px`;

                mapContainer.style.height = height;
                mapContainer.style.minHeight = height;
            }
        }
    }

    // handleMouseUpTouchEnd(target) {
    //     // Close open listing popup / infobox, unless the click is attached to a price label
    //     const labelClicked = hasParentWithClassName(target, LABEL_HANDLE);
    //     const infoCardClicked = hasParentWithClassName(target, INFO_CARD_HANDLE);

    //     if (this.state.infoCardOpen != null && !labelClicked && !infoCardClicked) {
    //         this.setState({
    //             infoCardOpen: null,
    //         });
    //         this.props.onSettingActiveListingId(null);
    //     }
    // }

    onMouseDown({ x, y }) {
        this.mouseEventCoord = { x, y };
    }

    // onTouchEnd({ target }) {
    //     this.handleMouseUpTouchEnd(target);
    // }

    onMouseUp({ x, y, target }) {
        const { x: evX, y: evY } = this.mouseEventCoord || {};
        const mapMoved = evX && evY ? x !== evX || y !== evY : false;

        if (mapMoved) return;
        // Close open listing popup / infobox, unless the click is attached to a price label
        const labelClicked = hasParentWithClassName(target, LABEL_HANDLE);
        const infoCardClicked = hasParentWithClassName(target, INFO_CARD_HANDLE);

        if (this.state.infoCardOpen != null && !labelClicked && !infoCardClicked) {
            this.setState({
                infoCardOpen: null,
            });
            this.props.onSettingActiveListingId(null);
        }
    }

    onMapLoad(map) {
        this.map = map;
        this.onMapViewportChange();
    }

    onIdle(e) {
        const {
            map,
            props: {
                searchInProgress,
                containerElement,
                reusableMapHiddenHandle,
                onMapBoundsSetting,
                mapBoundsString,
                mapVerticalSpacingAdjusted,
                mapFullScreenViewed,
                setMapFullScreenViewed,
            },
            state: { mapInitialized },
        } = this;

        if (!mapInitialized) {
            this.setState({
                mapInitialized: true,
            });
        }
        if (map && !searchInProgress && mapVerticalSpacingAdjusted) {
            // Let's try to find the map container element
            const mapContainer = containerElement.props.id
                ? document.getElementById(containerElement.props.id)
                : null;

            // If reusableMapHiddenHandle is given and parent element has that class,
            // we don't listen moveend events.
            // This fixes mobile Chrome bug that sends map events to invisible map components.
            const isHiddenByReusableMap =
                reusableMapHiddenHandle &&
                mapContainer &&
                mapContainer.parentElement.classList.contains(reusableMapHiddenHandle);

            if (isHiddenByReusableMap) return;

            const viewportMapBounds = getMapBounds(this.map);

            if (mapFullScreenViewed) {
                /**
                 * do not trigger the map movement handler on map full view toggle
                 */
                // this.viewportBounds = null;
                return setMapFullScreenViewed(false);
            }

            const viewportMapCenter = getMapCenter(this.map);
            const viewportBounds = viewportMapBounds
                ? sdkBoundsToFixedCoordinates(viewportMapBounds, BOUNDS_FIXED_PRECISION)
                : null;

            // ViewportBounds from (previous) rendering differ from viewportBounds currently set to map
            // I.e. user has changed the map somehow: moved, panned, zoomed, resized
            const viewportBoundsChanged =
                this.viewportBounds &&
                viewportBounds &&
                !hasSameSDKBounds(this.viewportBounds, viewportBounds) &&
                (mapBoundsString
                    ? mapBoundsString !== encodeBoundsToStr(viewportBounds, false)
                    : true);

            this.props.onMapMoveEnd(viewportBoundsChanged && mapBoundsString, {
                viewportBounds,
                viewportMapCenter,
            });

            if (mapInitialized) {
                this.viewportBounds = viewportBounds;
                viewportBounds && onMapBoundsSetting(viewportBounds);
            }
        }
    }
    render() {
        const { onMapMoveEnd, ...rest } = this.props;
        return (
            <MapWithGoogleMap
                mapEl={this.map}
                mapInitialized={this.state.mapInitialized}
                onMapLoad={this.onMapLoad}
                onIdle={this.onIdle}
                infoCardOpen={this.state.infoCardOpen}
                onListingClicked={listingBeingClicked =>
                    this.setState({
                        infoCardOpen: listingBeingClicked,
                    })
                }
                {...rest}
            />
        );
    }
}

SearchMapWithGoogleMap.defaultProps = {
    center: new sdkTypes.LatLng(0, 0),
    activeListingId: null,
    zoom: 11,
    reusableMapHiddenHandle: null,
    mapsConfig: config.maps,
};

SearchMapWithGoogleMap.propTypes = {
    containerElement: node.isRequired,
    center: propTypes.latlng,
    location: shape({
        search: string.isRequired,
    }).isRequired,
    activeListingId: string,

    onMapMoveEnd: func.isRequired,
    zoom: number,
    reusableMapHiddenHandle: string,

    mapsConfig: object,
};

const mapDispatchToProps = dispatch => ({
    onSettingActiveListingId: id => dispatch(setActiveListingId(id)),
    onMarkingListingsAsVisited: id => dispatch(markListingAsVisited(id)),
    onMapBoundsSetting: bounds => dispatch(setMapBounds(bounds)),
});

const mapStateToProps = ({
    SearchPage: {
        searchMapListingIds,
        searchInProgress,
        searchMapListingInProgess,
        extraSortInProgess,
        preflightSearchInProgress,
        activeListingId,
        listingsScores,
        listingsVisited,
        mapBounds,
    },
}) => ({
    searchMapListingIdsStr: (searchMapListingIds || []).map(s => s.uuid).join(','),
    listingsVisited,
    searchMapListingInProgess,
    searchInProgress:
        searchMapListingInProgess ||
        searchInProgress ||
        extraSortInProgess ||
        preflightSearchInProgress,
    activeListingId,
    listingsScores,
    mapBoundsString: mapBounds ? encodeLatLngBounds(mapBounds) : null,
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(SearchMapWithGoogleMap);

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Modal } from '..';

const ModalPortal = props => {
  const { children, ...rest } = props;
  const [portalRoot, setPortalRoot] = useState(null);

  useEffect(() => {
    setPortalRoot(document.getElementById('portal-root'));
  }, []);

  const modalComponent = <Modal {...rest}>{children}</Modal>;

  const modal = () => {
    return ReactDOM.createPortal(modalComponent, portalRoot);
  };

  return portalRoot ? modal() : null;
};

export default ModalPortal;

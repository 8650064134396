import React, { useState } from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import { EditComponent } from '..';
import css from './SectionPricing.css';
import { ArrowNextIcon, ClockIconTiny, PriceTagIcon } from '../../../../icons';
import classNames from 'classnames';

const PricePackageComponent = ({ currentPackage, packageNum }) => {
    const isMainPackage = packageNum == 1;
    const [isOpen, setIsOpen] = useState(isMainPackage);

    const { availability, currency, price, desc } = currentPackage;

    return (
        <div className={css.packageRoot}>
            <h3
                className={css.packageHeader}
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                <div
                    className={classNames(css.packageTitle, {
                        [css.packageTitleOpen]: isOpen,
                    })}
                >
                    {isMainPackage ? 'Basispaket' : `Paket ${packageNum}`}
                </div>
                <div className={classNames(css.packageArrow, { [css.isOpen]: isOpen })}>
                    <ArrowNextIcon />
                </div>
            </h3>

            {isOpen ? (
                <div className={css.packageContent}>
                    <p className={css.subItem}>
                        <ClockIconTiny />
                        <FormattedMessage
                            id={
                                availability
                                    ? `EditListingDescriptionForm.desiredAvailabilityRadioButton-${availability}`
                                    : 'SectionPricing.noLabelFound'
                            }
                        />
                    </p>
                    <p className={css.subItem}>
                        <PriceTagIcon />
                        {price / 100} {currency} pro Monat
                    </p>
                    {desc ? <p className={css.packageDesc}>{desc}</p> : null}
                </div>
            ) : null}
        </div>
    );
};

const SectionPricing = props => {
    const { isOwnListing, publicData, editParams } = props;
    const { package1, package2, package3 } = publicData || {};
    const allPackages = [package1, package2, package3];

    if (!package1) {
        return null;
    }

    return (
        <div className={css.root}>
            <h2 className={css.sectionTitle}>
                <FormattedMessage id="SectionPricing.title" />
                {isOwnListing ? <EditComponent pageName="pricing" editParams={editParams} /> : null}
            </h2>

            {allPackages.map((p, i) => {
                if (p && p.active) {
                    return <PricePackageComponent currentPackage={p} packageNum={i + 1} />;
                }
            })}
        </div>
    );
};

export default SectionPricing;

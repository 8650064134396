import isArray from 'lodash/isArray';
import reduce from 'lodash/reduce';
import { sanitizeEntity } from './sanitize';

/**
 * Combine the given relationships objects
 *
 * See: http://jsonapi.org/format/#document-resource-object-relationships
 */
export const combinedRelationships = (oldRels, newRels) => {
    if (!oldRels && !newRels) {
        // Special case to avoid adding an empty relationships object when
        // none of the resource objects had any relationships.
        return null;
    }
    return { ...oldRels, ...newRels };
};

/**
 * Combine the given resource objects
 *
 * See: http://jsonapi.org/format/#document-resource-objects
 */
export const combinedResourceObjects = (oldRes, newRes) => {
    const { id, type } = oldRes;
    if (newRes.id.uuid !== id.uuid || newRes.type !== type) {
        throw new Error('Cannot merge resource objects with different ids or types');
    }
    const attributes = newRes.attributes || oldRes.attributes;
    const attrs = attributes ? { attributes: { ...attributes } } : null;
    const relationships = combinedRelationships(oldRes.relationships, newRes.relationships);
    const rels = relationships ? { relationships } : null;
    return { id, type, ...attrs, ...rels };
};

/**
 * Combine the resource objects form the given api response to the
 * existing entities.
 */
export const updatedEntities = (oldEntities, apiResponse) => {
    const { data, included = [] } = apiResponse;
    const objects = (Array.isArray(data) ? data : [data]).concat(included);

    const newEntities = objects.reduce((entities, curr) => {
        const { id, type } = curr;

        // Some entities (e.g. listing and user) might include extended data,
        // you should check if src/util/sanitize.js needs to be updated.
        const current = sanitizeEntity(curr);

        entities[type] = entities[type] || {};
        const entity = entities[type][id.uuid];
        entities[type][id.uuid] = entity
            ? combinedResourceObjects({ ...entity }, current)
            : current;

        return entities;
    }, oldEntities);

    return newEntities;
};

/**
 * Denormalise the entities with the resources from the entities object
 *
 * This function calculates the dernormalised tree structure from the
 * normalised entities object with all the relationships joined in.
 *
 * @param {Object} entities entities object in the SDK Redux store
 * @param {Array<{ id, type }} resources array of objects
 * with id and type
 * @param {Boolean} throwIfNotFound wheather to skip a resource that
 * is not found (false), or to throw an Error (true)
 *
 * @return {Array} the given resource objects denormalised that were
 * found in the entities
 */
export const denormalisedEntities = (entities, resources, throwIfNotFound = true) => {
    const denormalised = resources.map(res => {
        const { id, type } = res;
        const entityFound = entities[type] && id && entities[type][id.uuid];
        if (!entityFound) {
            if (throwIfNotFound) {
                throw new Error(
                    `Entity with type "${type}" and id "${id ? id.uuid : id}" not found`
                );
            }
            return null;
        }
        const entity = entities[type] && entities[type][id.uuid];
        const { relationships, ...entityData } = entity || {};

        if (relationships) {
            // Recursively join in all the relationship entities
            return reduce(
                relationships,
                (ent, relRef, relName) => {
                    // A relationship reference can be either a single object or
                    // an array of objects. We want to keep that form in the final
                    // result.
                    const hasMultipleRefs = Array.isArray(relRef.data);
                    const multipleRefsEmpty = hasMultipleRefs && relRef.data.length === 0;
                    if (!relRef.data || multipleRefsEmpty) {
                        ent[relName] = hasMultipleRefs ? [] : null;
                    } else {
                        const refs = hasMultipleRefs ? relRef.data : [relRef.data];

                        // If a relationship is not found, an Error should be thrown
                        const rels = denormalisedEntities(entities, refs, true);

                        ent[relName] = hasMultipleRefs ? rels : rels[0];
                    }
                    return ent;
                },
                entityData
            );
        }
        return entityData;
    });
    return denormalised.filter(e => !!e);
};

/**
 * Denormalise the data from the given SDK response
 *
 * @param {Object} sdkResponse response object from an SDK call
 *
 * @return {Array} entities in the response with relationships
 * denormalised from the included data
 */
export const denormalisedResponseEntities = sdkResponse => {
    const apiResponse = sdkResponse.data;
    const data = apiResponse.data;
    const resources = Array.isArray(data) ? data : [data];

    if (!data || resources.length === 0) {
        return [];
    }

    const entities = updatedEntities({}, apiResponse);
    return denormalisedEntities(entities, resources);
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} transaction entity object, which is to be ensured against null values
 */
export const ensureTransaction = (transaction, booking = null, listing = null, provider = null) => {
    const empty = {
        id: null,
        type: 'transaction',
        attributes: {},
        booking,
        listing,
        provider,
    };
    return { ...empty, ...transaction };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} booking entity object, which is to be ensured against null values
 */
export const ensureBooking = booking => {
    const empty = { id: null, type: 'booking', attributes: {} };
    return { ...empty, ...booking };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureListing = listing => {
    const empty = {
        id: null,
        type: 'listing',
        attributes: { publicData: {} },
        images: [],
    };
    return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureOwnListing = listing => {
    const empty = {
        id: null,
        type: 'ownListing',
        attributes: { publicData: {} },
        images: [],
    };
    return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} user entity object, which is to be ensured against null values
 */
export const ensureUser = user => {
    const empty = { id: null, type: 'user', attributes: { profile: { publicData: {} } } };
    return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} current user entity object, which is to be ensured against null values
 */
export const ensureCurrentUser = user => {
    const deleted = user && user.attributes && user.attributes.deleted;

    const empty = {
        id: null,
        type: 'currentUser',
        attributes: { profile: { publicData: {}, protectedData: {}, privateData: {} } },
        profileImage: {},
    };

    if (deleted) {
        return empty;
    }

    return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} time slot entity object, which is to be ensured against null values
 */
export const ensureTimeSlot = timeSlot => {
    const empty = { id: null, type: 'timeSlot', attributes: {} };
    return { ...empty, ...timeSlot };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureDayAvailabilityPlan = availabilityPlan => {
    const empty = { type: 'availability-plan/day', entries: [] };
    return { ...empty, ...availabilityPlan };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureAvailabilityException = availabilityException => {
    const empty = { id: null, type: 'availabilityException', attributes: {} };
    return { ...empty, ...availabilityException };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensureStripeCustomer = stripeCustomer => {
    const empty = { id: null, type: 'stripeCustomer', attributes: {} };
    return { ...empty, ...stripeCustomer };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensurePaymentMethodCard = stripePaymentMethod => {
    const empty = {
        id: null,
        type: 'stripePaymentMethod',
        attributes: { type: 'stripe-payment-method/card', card: {} },
    };
    const cardPaymentMethod = { ...empty, ...stripePaymentMethod };

    if (cardPaymentMethod.attributes.type !== 'stripe-payment-method/card') {
        throw new Error(`'ensurePaymentMethodCard' got payment method with wrong type.
      'stripe-payment-method/card' was expected, received ${cardPaymentMethod.attributes.type}`);
    }

    return cardPaymentMethod;
};

/**
 * Get the display name of the given user as string. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned or deleted users, a translated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayNameAsString = (user, defaultUserDisplayName) => {
    const hasAttributes = user && user.attributes;
    const hasProfile = hasAttributes && user.attributes.profile;
    const hasDisplayName = hasProfile && user.attributes.profile.displayName;

    if (hasDisplayName) {
        return user.attributes.profile.displayName;
    } else {
        return defaultUserDisplayName || '';
    }
};

/**
 * DEPRECATED: Use userDisplayNameAsString function or UserDisplayName component instead
 *
 * @param {propTypes.user} user
 * @param {String} bannedUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayName = (user, bannedUserDisplayName) => {
    console.warn(
        `Function userDisplayName is deprecated!
User function userDisplayNameAsString or component UserDisplayName instead.`
    );

    return userDisplayNameAsString(user, bannedUserDisplayName);
};

/**
 * Get the abbreviated name of the given user. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned  or deleted users, a default abbreviated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserAbbreviatedName
 *
 * @return {String} abbreviated name that can be rendered in the UI
 * (e.g. in Avatar initials)
 */
export const userAbbreviatedName = (user, defaultUserAbbreviatedName) => {
    const hasAttributes = user && user.attributes;
    const hasProfile = hasAttributes && user.attributes.profile;
    const hasDisplayName = hasProfile && user.attributes.profile.abbreviatedName;

    if (hasDisplayName) {
        return user.attributes.profile.abbreviatedName;
    } else {
        return defaultUserAbbreviatedName || '';
    }
};

/**
 * A customizer function to be used with the
 * mergeWith function from lodash.
 *
 * Works like merge in every way exept that on case of
 * an array the old value is completely overridden with
 * the new value.
 *
 * @param {Object} objValue Value of current field, denoted by key
 * @param {Object} srcValue New value
 * @param {String} key Key of the field currently being merged
 * @param {Object} object Target object that is receiving values from source
 * @param {Object} source Source object that is merged into object param
 * @param {Object} stack Tracks merged values
 *
 * @return {Object} New value for objValue if the original is an array,
 * otherwise undefined is returned, which results in mergeWith using the
 * standard merging function
 */
export const overrideArrays = (objValue, srcValue, key, object, source, stack) => {
    if (isArray(objValue)) {
        return srcValue;
    }
};

/**
 * Humanizes a line item code. Strips the "line-item/" namespace
 * definition from the beginnign, replaces dashes with spaces and
 * capitalizes the first character.
 *
 * @param {string} code a line item code
 *
 * @return {string} returns the line item code humanized
 */

export const humanizeLineItemCode = code => {
    if (!/^line-item\/.+/.test(code)) {
        throw new Error(`Invalid line item code: ${code}`);
    }
    const lowercase = code.replace(/^line-item\//, '').replace(/-/g, ' ');

    return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};

export const trimLongText = (text, num) => [text.slice(0, num), '...'].join('');

export const isInteger = num => +num === Math.ceil(+num);

export const hundredthsIsZero = num => isInteger(+num * 10);

export const addTrailingZero = num =>
    num
        .split(/\s/)
        .map((s, i) => (!i ? s + '0' : s))
        .join(' ');

const findValueWithKeysArrays = (keysArray, dataObj) => {
    let current = dataObj;
    for (let i = 0; i < keysArray.length; i++) {
        current = current[keysArray[i]];
    }
    return current;
};

const resolveSenderRole = (tx, sender) =>
    tx.relationships.customer.data.id.uuid === sender.id.uuid ? 'customer' : 'provider';

/** get the latest message from a transaction */
export const getMessagesByTXsIds = async ({ txs, sdk, keysArray = null, sender }) => {
    const messagesObj = {};

    for (const tx of txs) {
        const role = resolveSenderRole(tx, sender);

        const messages = await sdk.messages.query({
            transactionId: tx.id,
            include: ['sender'],
            per_page: 1,
        });

        const dataIsValid = messages.data && messages.data.data && messages.data.data.length;

        const data = dataIsValid ? messages.data.data : null;

        const currentUserIsSender = messages.data.included[0].id.uuid === sender.id.uuid;

        if (dataIsValid) {
            messagesObj[tx.id.uuid] = {
                msg_id: keysArray ? findValueWithKeysArrays(keysArray, data[0]) : data,
                role,
                lastTransition: tx.attributes.lastTransition,
                isSender: currentUserIsSender,
            };
        }
    }

    return messagesObj;
};

// export const findEarliestTxUnreadMessage = ({ msg_id, id, sdk, currentUser }) => {
//     return new Promise(resolve => {
//         const unreadMessages = {
//             earliestUnreadMessage: null,
//             page: 1,
//         };
//         /** pass to the function the id of the message which is
//          * stored in current user's data (msg_id); fetch transacrtion's messages
//          * one by one until the id (msg_id) is found
//          * the next message is the earliest unread one
//          */
//         const findUnreadMessage = async () => {
//             const { page } = unreadMessages;
//             const messages = await sdk.messages.query({
//                 transactionId: id,
//                 include: ['sender'],
//                 per_page: 1,
//                 page,
//             });

//             if (!messages || !messages.data || messages.data.data.length === 0) {
//                 return resolve(null);
//             }

//             const messagesData = messages.data.data;
//             const message = messagesData[messagesData.length - 1];
//             const messageIsNotRead = message.id.uuid !== msg_id;

//             if (messageIsNotRead) {
//                 unreadMessages.page = page + 1;
//                 unreadMessages.earliestUnreadMessage = message;
//                 findUnreadMessage();
//             } else {
//                 const { earliestUnreadMessage } = unreadMessages;

//                 if (!earliestUnreadMessage) {
//                     return resolve(null);
//                 }

//                 const currentUserIsSender =
//                     earliestUnreadMessage.relationships.sender.data.id.uuid === currentUser.id.uuid;

//                 earliestUnreadMessage.isSender = currentUserIsSender;
//                 return resolve(earliestUnreadMessage);
//             }
//         };

//         findUnreadMessage();
//     });
// };

export const humanyzeTxError = (error, customError) => {
    try {
        return error.data.errors[0].title;
    } catch (e) {
        return customError ? customError : error;
    }
};

export const trimDisplayNameLastWord = displayName =>
    displayName ? displayName.split(' ').slice(0, 1)[0] : displayName;

export const checkMarketplaceCurrentUser = state => {
    const { currentUser } = state.user;
    const { entities } = state.marketplaceData;

    const userId = currentUser && currentUser.id.uuid;
    const marketplaceUser =
        userId && entities && entities.currentUser && entities.currentUser[userId];

    return marketplaceUser ? marketplaceUser : currentUser;
};

export const getHeightByNumberOfLines = (ref, linesNum) => {
    const ownHeight = ref.offsetHeight;
    const lineHeight = parseInt(getComputedStyle(ref).lineHeight);
    const refMaxHeight = lineHeight * linesNum;

    return {
        isExceeded: ownHeight > refMaxHeight,
        refMaxHeight,
    };
};

export const getHeightByLinesNumberMargins = (ref, linesNum) => {
    const ownHeight = ref.offsetHeight;
    const refStyles = getComputedStyle(ref);
    const verticalMargins = parseInt(refStyles.marginTop) + parseInt(refStyles.marginBottom);

    return (ownHeight + verticalMargins) * linesNum;
};
/**
 * creates 53-bit hash from a provided string
 *
 * @param {string} str a string to get hash from
 *
 * @return {string} returns 53-bit hash
 */
export const hash53 = str => {
    if (!str) {
        throw Error('str parameter has to be a string');
    }
    const seed = 0;

    let h1 = 0xdeadbeef ^ seed,
        h2 = 0x41c6ce57 ^ seed;

    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }

    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return (4294967296 * (2097151 & h2) + (h1 >>> 0)).toString();
};

export const parseJSONInfo = jsonStr => {
    try {
        return JSON.parse(jsonStr);
    } catch (error) {
        console.error('Faield to parse JSON from the following string provided ', jsonStr);
        return null;
    }
};

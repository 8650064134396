import React, { useState } from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_PROVIDER_COMMISSION, propTypes } from '../../util/types';
import css from './BookingBreakdown.css';
import config from '../../config';
import { Tooltip } from '../../components';
import { TooltipIcon } from './Icons';
import { TOOLTIP_DATA_REQUEST_ATTR } from '../../components/Tooltip/Tooltip';
import { resolveFormattedTotalPrice } from './helpers';

const { Money } = sdkTypes;
const { platformCommission } = config;

// Validate the assumption that the commission exists and the amount
// is zero or negative.
const isValidCommission = commissionLineItem => {
  return commissionLineItem.lineTotal instanceof Money && commissionLineItem.lineTotal.amount <= 0;
};

const LineItemProviderCommissionMaybe = props => {
  const { transaction, isProvider, intl, isCustomer } = props;
  const [tooltipVisible, setTooltipVisibility] = useState(false);
  const faqLink = isCustomer
    ? 'https://support.horsedeal.com/hc/de/articles/17568230507282'
    : 'https://support.horsedeal.com/hc/de/articles/17568256572690';
  if (!isProvider) {
    return null;
  }

  const providerCommissionLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_PROVIDER_COMMISSION && !item.reversal
  );

  // Flex Template for Web is using the default transaction process (https://www.sharetribe.com/docs/background/transaction-process/#sharetribe-flex-default-transaction-process)
  // which containt provider commissions so by default the providerCommissionLineItem should exist.
  // If you are not using provider commisison you might want to remove this whole component from BookingBreakdown.js file.
  if (providerCommissionLineItem && !isValidCommission(providerCommissionLineItem)) {
    // eslint-disable-next-line no-console
    console.error('invalid commission line item:', providerCommissionLineItem);
    throw new Error('Commission should be present and the value should be zero or negative');
  }
  const commission = providerCommissionLineItem.lineTotal;
  const commissionAbs = Number(Math.abs(commission.amount).toFixed(2));

  const formattedPrice = resolveFormattedTotalPrice(
    new Money(commissionAbs, commission.currency),
    intl
  );

  const tooltipContent = (
    <div className={css.tooltipContent}>
      <h5>
        <FormattedMessage id={'BookingBreakdown.tooltipFlexComissionHeading'} />
      </h5>
      <p>
        <FormattedMessage id={'BookingBreakdown.tooltipFlexComissionText'} />
      </p>
      <span
        className={css.tooltipLink}
        onClick={() => window.location.replace(faqLink)}
        data-role={TOOLTIP_DATA_REQUEST_ATTR}
      >
        <FormattedMessage id={'BookingBreakdown.tooltipLinkText'} />
      </span>
    </div>
  );

  return (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage
          id="BookingBreakdown.commission"
          values={{ commission: platformCommission }}
        />
      </span>
      <Tooltip
        content={tooltipContent}
        rootClassName={css.tooltipWrapper}
        tooltipClassName={css.tooltip}
        staticTooltipState={tooltipVisible}
        outsideClickHandler={setTooltipVisibility}
        // isStatic
      >
        <div
          className={css.infoItem}
          data-role={TOOLTIP_DATA_REQUEST_ATTR}
          onClick={() => setTooltipVisibility(prevState => !prevState)}
        >
          <TooltipIcon />
        </div>
      </Tooltip>
      <span className={css.itemValue}>-{formattedPrice}</span>
    </div>
  );
};

LineItemProviderCommissionMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemProviderCommissionMaybe;

import { CREATE_RIDER_LISTING_AFTER_AUTH } from '../ducks/Auth.duck';
import { createRiderListing } from '../util/api';
import config from '../config';

const { userTypeRider } = config;

/**
 * handle rider listing creation after rider is logged in
 */
export const createRiderListingMiddleware = () => ({ getState }) => next => action => {
    const { type } = action;

    if (type !== CREATE_RIDER_LISTING_AFTER_AUTH) {
        return next(action);
    }

    const {
        user: { currentUser },
        Auth: { createRiderListingAfterAuthInitialized },
    } = getState();

    const userDefined = currentUser && currentUser.id;

    if (!userDefined || createRiderListingAfterAuthInitialized) {
        return next(action);
    }

    const {
        id: { uuid },
        attributes: {
            profile: {
                publicData: { userType },
            },
        },
    } = currentUser;

    const isRider = userType === userTypeRider;

    if (!isRider) {
        return next(action);
    }

    createRiderListing(uuid);

    return next(action);
};

import React, { useState } from 'react';
import css from './AdminPageSuperDeals.css';
import EditForm from './EditForm';
import { SectionSuperDeals } from '../LandingPage/sectionsSuperDeals';
import AdminHOC from './AdminHOC';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import classNames from 'classnames';

const changeDateToEndOfDay = date => {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);

    return date;
};

const Panel = ({
    currentUser,
    error,
    showList,
    handleAdminAction: handleAdminActionParent,
    goBack,
    showForm,
    handleSubmit: handleSubmitParent,
    initialValues,
    action,
    history,
}) => {
    const [showOutdated, setShowOutdated] = useState(false);

    const handleSubmit = values => {
        const endOfDayDate = new Date(values.endDate.date);
        changeDateToEndOfDay(endOfDayDate);

        handleSubmitParent({
            ...values,
            endDate: {
                date: endOfDayDate,
            },
        });
    };

    const handleAdminAction = (action, initialValues) => {
        const isEdit = action === 'edit';
        const endDateMaybe = initialValues && initialValues.endDate && initialValues.endDate.date;
        const values =
            isEdit && endDateMaybe
                ? {
                      ...initialValues,
                      endDate: {
                          date: new Date(initialValues.endDate.date),
                      },
                  }
                : initialValues;

        handleAdminActionParent(action, values);
    };

    return (
        <>
            {error && <p className={css.error}>Failed to fetch data: {error}</p>}
            <nav>
                <p className={css.badge} onClick={() => handleAdminActionParent('add')}>
                    admin::add
                </p>
                <p
                    className={css.badge}
                    onClick={() =>
                        history.push(
                            createResourceLocatorString('SuperDeals', routeConfiguration())
                        )
                    }
                >
                    admin::watch::list
                </p>
                <p
                    className={classNames(css.badge, {
                        [css.selected]: showOutdated,
                    })}
                    onClick={() => setShowOutdated(prevState => !prevState)}
                >
                    admin::outdated
                </p>
            </nav>
            {showList && (
                <SectionSuperDeals
                    handleAdminAction={handleAdminAction}
                    currentUser={currentUser}
                    onManageDisableScrolling={() => null}
                    showOutdated={showOutdated}
                />
            )}
            {showForm && (
                <p type="button" onClick={goBack} className={css.backButton}>
                    Go back
                </p>
            )}
            {showForm && (
                <EditForm onSubmit={handleSubmit} initialValues={initialValues} action={action} />
            )}
        </>
    );
};

export default AdminHOC(Panel);

import React, { useState, useEffect } from 'react';
import { useListings } from './useListings';

export const useRiderListing = ({ params }) => {
    const [riderListing, setRiderListing] = useState(null);

    const [riderListings, riderListingsRequestInProgress, included] = useListings({
        params,
        allowed: !!params.authorId,
    });

    useEffect(() => {
        if (Array.isArray(riderListings)) {
            setRiderListing(
                riderListings.find(
                    ({
                        attributes: {
                            publicData: { userRepresentationId },
                        },
                    }) => userRepresentationId === params.authorId
                )
            );
        }
    }, [riderListings, params.authorId]);

    return [riderListing, riderListingsRequestInProgress, included];
};

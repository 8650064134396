import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';
import { Button } from '../../components';
import { ArrowNextIcon } from '../../icons';

import css from './Footer.css';

export const Footer = ({
  disabled,
  navigateToPreviousForm = null,
  updateInfoInProgress,
  handleSubmit,
  isLast,
}) => {
  return (
    <footer className={css.footer}>
      <div className={css.buttonHolder}>
        <Button
          className={css.prevPageButton}
          inProgress={updateInfoInProgress}
          disabled={!navigateToPreviousForm}
          onClick={navigateToPreviousForm ? navigateToPreviousForm : () => null}
        >
          <FormattedMessage id="OnboardingPage.prevPage" />
        </Button>
        <Button
          className={css.nextPageButton}
          inProgress={updateInfoInProgress}
          disabled={disabled}
          onClick={handleSubmit}
        >
          {isLast ? (
            <FormattedMessage id="OnboardingPage.lastPage" />
          ) : (
            <>
              <FormattedMessage id="OnboardingPage.nextPage" />
              <ArrowNextIcon />
            </>
          )}
        </Button>
      </div>
    </footer>
  );
};

/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { array, arrayOf, bool, func, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import config from '../../config';
import { apiBaseUrl, updateUserVisitorsData } from '../../util/api';
import routeConfiguration from '../../routeConfiguration';
import { LISTING_STATE_PENDING_APPROVAL, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
    LISTING_PAGE_DRAFT_VARIANT,
    LISTING_PAGE_PENDING_APPROVAL_VARIANT,
    LISTING_PAGE_PARAM_TYPE_DRAFT,
    LISTING_PAGE_PARAM_TYPE_EDIT,
    createSlug,
    findDisciplinePathName,
    resolveActivitiesSlug,
    isLocalhost,
    parse,
    resolveBreedsSlug,
} from '../../util/urlHelpers';
import { priceData } from '../../util/currency';
import { capitalize, sanitizeSpecChars } from '../../util/text';
import { createResourceLocatorString } from '../../util/routes';
import {
    ensureListing,
    ensureOwnListing,
    ensureUser,
    userDisplayNameAsString,
    trimDisplayNameLastWord,
    ensureCurrentUser,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import {
    Page,
    NamedRedirect,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    MissingPhoneNumberModal,
    NoResultsBlock,
    Button,
    ReportProfile,
    IconSpinner,
} from '../../components';
import {
    saveContactDetails,
    verifyPhoneNumber,
} from '../ContactDetailsPage/ContactDetailsPage.duck';
import { sendVerificationOtp } from '../../ducks/user.duck';
import { TopbarContainer } from '../../containers';

import { sendEnquiry, loadData, setInitialValues } from './ListingPage.duck';
import SectionImages from './Sections/SectionImages/SectionImages.js';
import ExternalHorseModal from './components/ExternalHorseModal';

import ActionBarMaybe from './components/ActionBarMaybe.js';

import css from './ListingPage.css';
import DirectionsMaybe from './components/DirectionsMaybe.js';
import TopInfoMaybe from './components/TopInfoMaybe';
import InquiryModal from '../../forms/ProfileSettingsForm/InquiryModal.js';
import SharingViaSocialNetworkModal from './components/SharingViaSocialNetworkModal.js';
import useBestMatchingListing from '../../hooks/useBestMatchingListing.js';
import { useListings } from '../../hooks/useListings.js';
import MatchingRateSection from '../../forms/ProfileSettingsForm/sections/MatchingRateSection.js';
import { useIsMobile } from '../../hooks/useIsMobile.js';
import ArrowNextIcon from '../../icons/ArrowNextIcon.js';
import PageLayout from './components/PageLayout.js';
import RiderNotAvailableModal from './components/RiderNotAvailableModal.js';
import {
    DEFAULT_LISTING_TITLE,
    RIDER_AVAILABILITY_NOT_AVAILABLE,
} from '../../marketplace-custom-config.js';
import { isProfileComplete } from '../../util/user.js';
import { useListingsWithDistance } from '../../hooks/useListingsWithDistance.js';
import { getCurrentUserLocation, setLastSeenListings } from '../../util/localStorage.js';
import commmonCss from './Sections/common.css';
import {
    SectionPricing,
    SectionSkills,
    SectionAvailability,
    SectionActivities,
    SectionStable,
    SectionAbout,
    SectionLocation,
    SectionQualifications,
    SectionDisciplines,
    SectionContact,
    SectionBoosting,
    ListingCardsCarouselSection,
} from './Sections/index.js';
import { anonymizeListingAuthor } from '../../util/listings';
import ConfirmationModal from './components/ConfirmationModal';
import { useIsMounted } from '../../hooks/useIsMounted';
import EmailConfirmationBar from '../../components/Topbar/EmailConfirmationBar';
import { useTrackListingPage } from './useTrackListingPage.js';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;
const { userTypeHorseowner, userTypeRider, listingTypeHorse, initialSearchAddress } = config;

const windowDefined = typeof window !== 'undefined';

const ListingPageComponent = ({
    currentUser,
    getListing,
    getOwnListing,
    assetsData,
    listingAuthor,
    activeTransactionsData,
    onSendEnquiry,
    similarDisciplineListings,
    similarDisciplineError,
    onSubmitContactDetails,
    onSendVerificationOtp,
    onVerifyPhoneNumber,
    showListingLoading,
    onManageDisableScrolling,
    scrollingDisabled,
    showListingError,
    searchPageListings,
    intl,
    history,
    params,
    location,
}) => {
    const [isMobile, , computing] = useIsMobile(1024);
    const [breed, setBreed] = useState('');
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [isExploding, setIsExploding] = useState(false);
    const [imageCarouselOpen, setImageCarouselOpen] = useState();
    const [enquiryModalOpen, setEnquiryModalOpen] = useState();
    const [isSocialNetworkModalOpen, setIsSocialNetworkModalOpen] = useState();
    const [externalListingModalOpen, setExternalListingModalOpen] = useState();
    const [verifyPhoneNumberModalOpen, setVerifyPhoneNumberModalOpen] = useState();
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [riderNotAvailableStatus, setRiderNotAvailableStatus] = useState(null);
    const [retriggerKey, setRetriggerKey] = useState(null);
    const [listingsWithSimilarDiscipline, setListingsWithSimilarDiscipline] = useState([]);

    const appIsMounted = useIsMounted();
    const { goBack } = history;
    const { id, slug: propsSlug } = params;
    const {
        attributes: {
            profile: {
                publicData: { userType, userLocation },
            },
        },
    } = currentUser;

    const listingId = new UUID(id);
    const authorId = listingAuthor && listingAuthor.id ? listingAuthor.id.uuid : undefined;
    const currentUserId = currentUser && currentUser.id ? currentUser.id.uuid : null;

    const currentUserIsRider = userType === userTypeRider;

    const isPendingApprovalVariant = params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = params.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
        isPendingApprovalVariant || isDraftVariant
            ? ensureOwnListing(getOwnListing(listingId))
            : ensureListing(getListing(listingId));

    const isOwnListing =
        /*userAndListingAuthorAvailable &&*/ currentUserId &&
        authorId &&
        authorId === currentUserId;

    const { anonymousListing: anonymousPackagePurchased } =
        currentListing.attributes.metadata || {};
    const isAnonymousListing = anonymousPackagePurchased;

    const ensuredAuthor = isAnonymousListing
        ? anonymizeListingAuthor(ensureUser(listingAuthor))
        : ensureUser(listingAuthor);

    const { loc } = getCurrentUserLocation() || {};

    const currentUserOrigin =
        typeof userLocation === 'object' ? Object.values(userLocation).join(',') : loc;

    const searchParams = parse(location.search);
    const isConfirmationPage = searchParams && searchParams.hasOwnProperty('confirmation');
    const { description = '', price = null, title = '', publicData } = currentListing.attributes;

    const {
        publicAddress = '',
        breed: pbBreed = '',
        gender = '',
        starredDisciplines,
        activities,
        externalListing,
        externalSourceLink,
        externalSourceName,
        userRepresentationId,
        updatedAt,
        addressDetails = { postalCode: '', location: '' },
        mainListingImage,
    } = publicData;

    useEffect(() => {
        id && setLastSeenListings(id);
    }, [id]);

    useEffect(() => {
        if (!isConfirmationPage) {
            return;
        }
        /**
         * a user successfuly created a new listing;
         * ?confirmation redirect is carried out by
         * payrexx system; the param is adde at
         * EditListingPricingPage/Form
         */
        setConfirmationModalOpen(true);
        setIsExploding(true);
    }, [isConfirmationPage]);

    useEffect(() => {
        setListingsWithSimilarDiscipline(
            similarDisciplineListings
                ? similarDisciplineListings.filter(l => l.id.uuid !== listingId.uuid).slice(0, 8)
                : []
        );
    }, [listingId.uuid, similarDisciplineListings]);

    /** useEffect */
    const listingsWithDistance = useListingsWithDistance(
        listingsWithSimilarDiscipline,
        currentUserOrigin
    );

    useEffect(() => {
        const listingDataLoaded = getListing(listingId);
        if (!listingDataLoaded) {
            return;
        }
        const currentListing = ensureListing(listingDataLoaded);

        const slug = createSlug(currentListing);
        const slugMalformed = slug !== propsSlug;

        if (slugMalformed) {
            history.push(
                createResourceLocatorString('ListingPage', routeConfiguration(), { id, slug }, {})
            );
        }
    }, [propsSlug, listingId]);

    useEffect(() => {
        if (!pbBreed) return;

        const seoBreed = sanitizeSpecChars(resolveBreedsSlug(pbBreed))
            .split(' ')
            .map(capitalize)
            .join(' ');

        setBreed(seoBreed);
    }, [pbBreed]);

    useEffect(() => {
        if (!currentUserId) return;
        if (isOwnListing) return;
        if (!currentUserIsRider) return;
        if (!authorId) return;
        /**
         * visitedEntryId - listingId
         * visitorRepresentationId - riderId
         */
        updateUserVisitorsData(authorId, listingId.uuid, currentUserId);
    }, [currentUserId, currentUserIsRider, authorId, listingId.uuid]);

    /**
     * isOwnListing - owner looks on its own listing
     * !currentUserIsRider - other owner looks on a listing
     */
    const riderListingAuthorId = isOwnListing || !currentUserIsRider ? null : currentUserId;

    const [riderListings, riderListingsRequestInProgress] = useListings({
        params: {
            authorId: riderListingAuthorId,
        },
        retriggerKey,
        allowed: riderListingAuthorId && currentUserIsRider,
    });

    const riderListing = Array.isArray(riderListings)
        ? riderListings.find(
              ({
                  attributes: {
                      publicData: { userRepresentationId },
                  },
              }) => !!userRepresentationId
          )
        : null;

    const [scoreReqInProgress, score] = useBestMatchingListing({
        riderListing,
        ownerId: isOwnListing || !currentUserIsRider ? null : authorId,
        mainHorseId: listingId.uuid,
    });

    useTrackListingPage({
        currentUser,
        score,
        currentListing,
        currentUserOrigin,
    });

    const setSocialNetworkModal = flag => setIsSocialNetworkModalOpen(flag);
    const setVerifyPhoneNumberModalVisibility = flag => setVerifyPhoneNumberModalOpen(flag);

    const redirectToSignUpPage = () => {
        const { pathname, search, hash } = location;
        const state = { from: `${pathname}${search}${hash}` };

        // We need to log in before showing the modal, but first we need to ensure
        // that modal does open when user is redirected back to this listingpage
        const listingIdMaybe = listingId ? listingId.uuid : null;
        history.push(
            createResourceLocatorString(
                'SignupRider',
                routeConfiguration(),
                {},
                listingIdMaybe ? { listingId: listingId.uuid } : {}
            ),
            state
        );
    };

    const onContactUser = (isExternal, isOwnListing) => {
        if (!currentUser || !currentUser.id) {
            return redirectToSignUpPage();
        }

        if (isExternal) {
            return setExternalListingModalOpen(true);
        }

        if (isOwnListing) {
            return;
        }

        const { availabilityStatus } = currentUser.attributes.profile.publicData;
        const riderNotAvailable =
            !availabilityStatus ||
            (availabilityStatus === RIDER_AVAILABILITY_NOT_AVAILABLE && 'riderNotAvailable');

        const { isPrimaryFieldsComplete } = isProfileComplete(currentUser);

        if (riderNotAvailable || !isPrimaryFieldsComplete) {
            return setRiderNotAvailableStatus(riderNotAvailable || 'profileComplete');
        }

        const activeTransactionId = activeTransactionsData[listingId.uuid];

        if (activeTransactionId) {
            history.push(
                createResourceLocatorString(
                    'OrderDetailsPage',
                    routeConfiguration(),
                    { id: activeTransactionId.txId },
                    {}
                )
            );
        }
        setEnquiryModalOpen(true);
    };

    const onSubmitEnquiry = ({ comment, listingId, listingSubstitution }) =>
        onSendEnquiry(listingId, comment.trim(), listingSubstitution)
            .then(txId => {
                setEnquiryModalOpen(false);

                // Redirect to OrderDetailsPage
                history.push(
                    createResourceLocatorString(
                        'OrderDetailsPage',
                        routeConfiguration(),
                        { id: txId.uuid },
                        {}
                    )
                );
            })
            .catch(() => {
                // Ignore, error handling in duck file
            });

    const onSubmitEnquiryDecorator = values => {
        if (isLocalhost()) return onSubmitEnquiry(values);

        if (windowDefined) {
            window.checkRecaptchaBeforeSubmit(() => onSubmitEnquiry(values));
        }
    };

    const listingIdMalformed =
        showListingError &&
        showListingError.apiErrors.length &&
        showListingError.apiErrors.some(s => s.source && s.source.path.includes('id'));
    const listingClosed =
        currentListing && currentListing.id && currentListing.attributes.state === 'closed';
    const listingNotFound = showListingError && showListingError.status === 404;

    const redirectToNotFoundPage = listingIdMalformed || listingNotFound;
    const noIndexTag = [
        {
            name: 'robots',
            content: 'noindex',
        },
    ];
    if (showListingLoading) {
        // Still loading the listing
        const loadingTitle = intl.formatMessage({
            id: 'ListingPage.loadingListingTitle',
        });

        return (
            <PageLayout
                /** no topbar when loading is in progress  */
                showTopBar={false}
                title={loadingTitle}
                scrollingDisabled={scrollingDisabled}
                metaTags={noIndexTag}
            >
                <IconSpinner />
            </PageLayout>
        );
    } else if (redirectToNotFoundPage || !currentListing.id) {
        // 404 listing not found

        const notFoundTitle = intl.formatMessage({
            id: 'ListingPage.notFoundListingTitle',
        });

        return (
            <PageLayout
                showTopBar={appIsMounted}
                title={notFoundTitle}
                scrollingDisabled={scrollingDisabled}
                metaTags={noIndexTag}
                blobBackground
            >
                <div className={css.noResultsContainer}>
                    <NoResultsBlock
                        headerId="Dieses Inserat existiert nicht mehr."
                        parapraphId="Das aufgerufene Inserat wurde gelöscht und existiert nicht mehr."
                        searchFormPlaceholder="Suche in deiner Nähe"
                        isSearchForm
                        rootClass={css.noResultsBlock}
                    />
                </div>
            </PageLayout>
        );
    } else if (showListingError) {
        // Other error in fetching listing

        const errorTitle = intl.formatMessage({
            id: 'ListingPage.errorLoadingListingTitle',
        });

        return (
            <PageLayout
                showTopBar={appIsMounted}
                title={errorTitle}
                scrollingDisabled={scrollingDisabled}
                metaTags={noIndexTag}
            >
                <p className={css.errorText}>
                    <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
                </p>
            </PageLayout>
        );
    }

    const listingSlug = params.slug || createSlug(currentListing);

    const listingType = isDraftVariant
        ? LISTING_PAGE_PARAM_TYPE_DRAFT
        : LISTING_PAGE_PARAM_TYPE_EDIT;
    // const listingTab = isDraftVariant ? 'photos' : 'overview';

    const isApproved =
        currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
        (isPendingApprovalVariant || isDraftVariant) &&
        showListingError &&
        showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
        return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const isUserListing = !!userRepresentationId;

    if (isUserListing) {
        return (
            <NamedRedirect
                name="ProfilePage"
                params={{ id: userRepresentationId, userType: 'rider' }}
            />
        );
    }

    const richTitle = (
        <span>
            {richText(title == '__title__' ? DEFAULT_LISTING_TITLE : title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
                longWordClass: css.longWord,
            })}
        </span>
    );

    const handleViewPhotosClick = (e, index = 0) => {
        // Stop event from bubbling up to prevent image click handler
        // trying to open the carousel as well.
        e.stopPropagation();

        setImageCarouselOpen(true);
        setSelectedImageIndex(index);
    };

    // const extReviews = ensuredAuthor?.attributes?.profile?.publicData?.externalReviews;
    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = trimDisplayNameLastWord(userDisplayNameAsString(ensuredAuthor, ''));

    const { formattedPrice } = priceData(price, intl);

    const getDefaultOGImages = name => [
        {
            name,
            url: 'https://www.horsedeal.com/static/icons/android-chrome-512x512.png',
            width: 400,
            height: 400,
        },
    ];

    const listingImages = name => {
        if (!mainListingImage) {
            return getDefaultOGImages(name);
        }
        return [
            {
                name,
                url: mainListingImage,
                width: 400,
                height: 400,
            },
        ];
    };

    const listingSeoActivity = capitalize(resolveActivitiesSlug(activities));

    const facebookImages = listingImages('facebook');
    const twitterImages = listingImages('twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url).filter(s => !!s));
    const siteTitle = config.siteTitle;
    const facebookDescription = `${listingSeoActivity} gesucht für ${title} in ${addressDetails.postalCode} ${addressDetails.location}! 🐴💜`;
    const schemaTitle = intl.formatMessage(
        { id: 'ListingPage.schemaTitle' },
        {
            activity: listingSeoActivity,
            address: publicAddress,
            mainDiscipline: capitalize(findDisciplinePathName((starredDisciplines || [])[0])),
            breed,
            gender: capitalize(gender),
            siteTitle,
        }
    );

    const mainContentClasses = classNames(css.mainContent, isOwnListing && css.fullWidth);

    const editParams = {
        id: listingId.uuid,
        slug: isDraftVariant ? LISTING_PAGE_PARAM_TYPE_DRAFT : listingSlug,
    };

    const renderReportProfile = rootClass =>
        !isOwnListing && !isDraftVariant ? (
            <ReportProfile
                user={ensuredAuthor}
                actionId="ListingPage.reportProfileAction"
                headerId="ListingPage.reportProfileHeading"
                reportProfileMessageId="ListingPage.reportProfileMessageId"
                initialValues={{
                    profileLink: `${apiBaseUrl()}${location.pathname}`,
                }}
                rootClassName={classNames(rootClass, css.reportingMobileSection)}
                options={[
                    'spam',
                    'unrealistic-price',
                    'offensive-content',
                    'wrong-information',
                    'default',
                ]}
            />
        ) : null;

    const showDirectionsBar = !computing && searchPageListings && searchPageListings.length > 0;

    return (
        <>
            {isExploding && isOwnListing && (
                <Confetti
                    width={windowDefined ? window.innerWidth : 1000}
                    height={windowDefined ? window.innerHeight : 1000}
                    style={{ zIndex: 999 }}
                />
            )}

            <Page
                title={schemaTitle}
                scrollingDisabled={scrollingDisabled}
                author={authorDisplayName}
                contentType="website"
                description={description}
                facebookImages={facebookImages}
                twitterImages={twitterImages}
                ogDescription={facebookDescription}
                metaTags={listingClosed ? noIndexTag : null}
                schema={{
                    '@context': 'http://schema.org',
                    '@type': 'ItemPage',
                    description: description,
                    name: schemaTitle,
                    image: schemaImages,
                }}
            >
                <LayoutSingleColumn className={css.pageRoot}>
                    <LayoutWrapperTopbar>
                        {isMobile && <EmailConfirmationBar />}
                        {confirmationModalOpen && isOwnListing && (
                            <ConfirmationModal
                                onClose={() => {
                                    if (windowDefined) {
                                        const url = new URL(window.location.href);

                                        url.searchParams.delete('confirmation');
                                        window.history.pushState({}, document.title, url);

                                        setConfirmationModalOpen(false);
                                        setIsExploding(false);
                                    }
                                }}
                                onManageDisableScrolling={onManageDisableScrolling}
                                authorDisplayName={authorDisplayName}
                            />
                        )}{' '}
                        {isMobile && !computing && (
                            <TopInfoMaybe
                                richTitle={richTitle}
                                setSocialNetworkModal={setSocialNetworkModal}
                                currentListing={currentListing}
                                isAnonymousListing={isAnonymousListing}
                                actionWrapperClassName={css.mobileNavPanel}
                                rootClassName={css.mobileNavPanelRoot}
                                listingAuthor={ensuredAuthor}
                                currentUser={currentUser}
                            >
                                <Button onClick={() => goBack()}>
                                    <ArrowNextIcon />
                                    <span>Zurück</span>
                                </Button>
                            </TopInfoMaybe>
                        )}
                        {!isMobile && !computing && <TopbarContainer />}
                    </LayoutWrapperTopbar>
                    <LayoutWrapperMain>
                        <div className={css.pageWrapper}>
                            {currentListing.id ? (
                                <ActionBarMaybe
                                    isOwnListing={isOwnListing}
                                    listing={currentListing}
                                    currentUser={currentUser}
                                    clickHandler={e => e.stopPropagation()}
                                    editParams={{
                                        ...editParams,
                                        type: listingType,
                                        tab: 'overview',
                                    }}
                                />
                            ) : null}

                            <section className={css.navigationSection}>
                                {showDirectionsBar && (
                                    <DirectionsMaybe
                                        searchPageListings={searchPageListings}
                                        currentListing={currentListing}
                                        history={history}
                                    />
                                )}

                                <TopInfoMaybe
                                    richTitle={richTitle}
                                    authorDisplayName={authorDisplayName}
                                    setSocialNetworkModal={setSocialNetworkModal}
                                    currentListing={currentListing}
                                    isAnonymousListing={isAnonymousListing}
                                    listingAuthor={ensuredAuthor}
                                    currentUser={currentUser}
                                />
                            </section>

                            <SectionImages
                                title={title}
                                listing={currentListing}
                                selectedImageIndex={selectedImageIndex}
                                imageCarouselOpen={imageCarouselOpen}
                                onImageCarouselClose={() => setImageCarouselOpen(false)}
                                handleViewPhotosClick={handleViewPhotosClick}
                                onManageDisableScrolling={onManageDisableScrolling}
                                isMobile={isMobile}
                            >
                                <MissingPhoneNumberModal
                                    id="ListingPage.missingPhoneNumberModal"
                                    isOpen={verifyPhoneNumberModalOpen}
                                    onClose={() => setVerifyPhoneNumberModalVisibility(false)}
                                    onSubmitContactDetails={onSubmitContactDetails}
                                    onSendVerificationOtp={onSendVerificationOtp}
                                    onVerifyPhoneNumber={onVerifyPhoneNumber}
                                    redirectOnCLose={false}
                                    currentUser={currentUser}
                                />
                                <SharingViaSocialNetworkModal
                                    id={id}
                                    slug={listingSlug}
                                    title={title}
                                    emailSharingDescription={facebookDescription}
                                    isOpen={isSocialNetworkModalOpen}
                                    onCloseEnquiryModal={() => setSocialNetworkModal(false)}
                                    intl={intl}
                                />
                            </SectionImages>

                            <main className={css.mainSection}>
                                <SectionContact
                                    formattedPrice={formattedPrice}
                                    onContactUser={() =>
                                        onContactUser(externalListing, isOwnListing)
                                    }
                                    listingAuthor={ensuredAuthor}
                                    currentUser={currentUser}
                                    riderListing={riderListing}
                                    currentListing={currentListing}
                                    isAnonymousListing={isAnonymousListing}
                                    isOwnListing={isOwnListing}
                                    loading={riderListingsRequestInProgress}
                                />

                                <div className={css.contentContainer}>
                                    <div className={mainContentClasses}>
                                        <div className={css.leftSectionWrapper}>
                                            {listingAuthor && (
                                                <MatchingRateSection
                                                    currentUser={currentUser}
                                                    profileSettingsDescId="ListingPage.profileSettingsDesc"
                                                    signUpLinkDescId="ListingPage.signUpLinkDescId"
                                                    viewProfileRequestModalDescId="ListingPage.viewProfileRequestModalDesc"
                                                    signUpLinkAttributes={{ name: 'SignupRider' }}
                                                    /** force a user to be an owner */
                                                    user={{
                                                        attributes: {
                                                            createdAt:
                                                                ensuredAuthor.attributes.createdAt,
                                                            profile: {
                                                                publicData: {
                                                                    userType: userTypeHorseowner,
                                                                    /**
                                                                     * monitor listing's update info
                                                                     */
                                                                    updatedAt,
                                                                },
                                                            },
                                                        },
                                                    }}
                                                    isLoggedOut={!currentUserId}
                                                    isRiderPage
                                                    isSameUser={isOwnListing}
                                                    listingToRedirect={currentListing}
                                                    onManageDisableScrolling={
                                                        onManageDisableScrolling
                                                    }
                                                    scoreReqInProgress={scoreReqInProgress}
                                                    scoreData={score}
                                                    createdAtDesc={`ListingPage.${
                                                        updatedAt
                                                            ? 'updatedAtDesc'
                                                            : 'createdAtDesc'
                                                    }`}
                                                    rootClassName={commmonCss.root}
                                                />
                                            )}
                                            {isOwnListing && (
                                                <SectionBoosting
                                                    currentListing={currentListing}
                                                    currentUser={currentUser}
                                                    editParams={editParams}
                                                    history={history}
                                                />
                                            )}
                                            <SectionAvailability
                                                publicData={publicData}
                                                isOwnListing={isOwnListing}
                                                editParams={editParams}
                                                scoreData={score}
                                                isMobile={isMobile}
                                            />

                                            <SectionActivities
                                                activities={publicData.activities}
                                                isOwnListing={isOwnListing}
                                                editParams={editParams}
                                                scoreData={score}
                                            />
                                            {renderReportProfile(css.desktop)}
                                        </div>

                                        <div className={css.rightSectionWrapper}>
                                            <SectionAbout
                                                description={description}
                                                currentListing={currentListing}
                                                isOwnListing={isOwnListing}
                                                editParams={editParams}
                                                isMobile={isMobile}
                                            />

                                            <SectionDisciplines
                                                publicData={publicData}
                                                isOwnListing={isOwnListing}
                                                editParams={editParams}
                                                isMobile={isMobile}
                                                scoreData={score}
                                            />

                                            <SectionQualifications
                                                publicData={publicData}
                                                isOwnListing={isOwnListing}
                                                editParams={editParams}
                                                isMobile={isMobile}
                                                scoreData={score}
                                            />

                                            <SectionSkills
                                                skills={publicData.skills}
                                                isOwnListing={isOwnListing}
                                                editParams={editParams}
                                                isMobile={isMobile}
                                                scoreData={score}
                                            />

                                            <SectionLocation
                                                intl={intl}
                                                publicData={publicData}
                                                isOwnListing={isOwnListing}
                                                editParams={editParams}
                                                currentUser={currentUser}
                                                scoreData={score}
                                            />

                                            <SectionStable
                                                publicData={publicData}
                                                isOwnListing={isOwnListing}
                                                editParams={editParams}
                                            />

                                            <SectionPricing
                                                publicData={publicData}
                                                isOwnListing={isOwnListing}
                                                editParams={editParams}
                                            />

                                            {renderReportProfile(css.mob)}

                                            {similarDisciplineError ||
                                                (!isOwnListing && (
                                                    <ListingCardsCarouselSection
                                                        isMobile={isMobile}
                                                        listings={listingsWithDistance}
                                                        scoreData={score}
                                                        riderListing={riderListing}
                                                        minMatchingRate={69}
                                                        isLoggedIn={!!currentUserId}
                                                        hideButtonsOnEndStart
                                                    />
                                                ))}

                                            {externalListing ? (
                                                <ExternalHorseModal
                                                    onManageDisableScrolling={
                                                        onManageDisableScrolling
                                                    }
                                                    onCloseModal={() =>
                                                        setExternalListingModalOpen(false)
                                                    }
                                                    isModalOpen={externalListingModalOpen}
                                                    externalSourceLink={externalSourceLink}
                                                    externalSourceName={externalSourceName}
                                                />
                                            ) : null}
                                        </div>

                                        {enquiryModalOpen && (
                                            <InquiryModal
                                                isOpen
                                                onClose={() => setEnquiryModalOpen(false)}
                                                onManageDisableScrolling={onManageDisableScrolling}
                                                currentUser={currentUser}
                                                publicUser={ensuredAuthor}
                                                initialListing={currentListing}
                                                ridingListingAuthorId={
                                                    isOwnListing || !currentUserId
                                                        ? null
                                                        : currentUserId
                                                }
                                                onSendEnquiry={(
                                                    listingId,
                                                    comment,
                                                    listingSubstitution
                                                ) =>
                                                    onSubmitEnquiryDecorator({
                                                        listingId,
                                                        comment,
                                                        listingSubstitution,
                                                    })
                                                }
                                            />
                                        )}
                                        {!!riderNotAvailableStatus && (
                                            <RiderNotAvailableModal
                                                isOpen
                                                onClose={() => setRiderNotAvailableStatus(null)}
                                                authorDisplayName={authorDisplayName}
                                                riderNotAvailableStatus={riderNotAvailableStatus}
                                                setRetriggerKey={setRetriggerKey}
                                                currentUserId={currentUserId}
                                                riderListing={riderListing}
                                                riderListingsRequestInProgress={
                                                    riderListingsRequestInProgress
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                            </main>
                        </div>
                    </LayoutWrapperMain>
                    <LayoutWrapperFooter>
                        <Footer className={css.bottomSeperator} />
                    </LayoutWrapperFooter>
                </LayoutSingleColumn>
            </Page>
        </>
    );
};

ListingPageComponent.defaultProps = {
    unitType: config.bookingUnitType,
    currentUser: null,
    showListingError: null,
    reviews: [],
    fetchReviewsError: null,
    timeSlots: null,
    fetchTimeSlotsError: null,
    sendEnquiryError: null,
    categoriesConfig: config.custom.categories,
    // amenitiesConfig: config.custom.amenities,
};

ListingPageComponent.propTypes = {
    // from withRouter
    history: shape({
        push: func.isRequired,
    }).isRequired,
    location: shape({
        search: string,
    }).isRequired,

    unitType: propTypes.bookingUnitType,
    // from injectIntl
    intl: intlShape.isRequired,

    params: shape({
        id: string.isRequired,
        slug: string,
        variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
    }).isRequired,

    isAuthenticated: bool.isRequired,
    currentUser: propTypes.currentUser,
    getListing: func.isRequired,
    getOwnListing: func.isRequired,
    onManageDisableScrolling: func.isRequired,
    scrollingDisabled: bool.isRequired,
    showListingError: propTypes.error,
    callSetInitialValues: func.isRequired,
    reviews: arrayOf(propTypes.review),
    fetchReviewsError: propTypes.error,
    timeSlots: arrayOf(propTypes.timeSlot),
    fetchTimeSlotsError: propTypes.error,
    sendEnquiryInProgress: bool.isRequired,
    sendEnquiryError: propTypes.error,
    onSendEnquiry: func.isRequired,
    categoriesConfig: array,
    // amenitiesConfig: array,
};

const mapStateToProps = state => {
    const { isAuthenticated } = state.Auth;
    const { wishlistData } = state.FavoritePage;
    const { activeTransactionsData } = state.ProfilePage;
    const { assetsData } = state.Assets;
    const {
        showListingError,
        fetchReviewsError,
        fetchTimeSlotsError,
        sendEnquiryError,
        reviews,
        timeSlots,
        sendEnquiryInProgress,
        similarDisciplineListings,
        similarDisciplineError,
        listingAuthor,
        showListingLoading,
    } = state.ListingPage;

    const { currentPageResultIds } = state.SearchPage;
    /**
     * listing for back and force navigation, see DirectionsMaybe
     */
    const searchPageListings = getListingsById(state, currentPageResultIds);

    const { currentUser } = state.user;

    const pageListings = (
        getListingsById(
            state,
            similarDisciplineListings.map(s => s.id)
        ) || []
    ).filter(s => s && s.id && s.attributes.publicData.type === listingTypeHorse);

    const getListing = id => {
        const ref = { id, type: 'listing' };
        const listings = getMarketplaceEntities(state, [ref]);
        return listings.length === 1 ? listings[0] : null;
    };

    const getOwnListing = id => {
        const ref = { id, type: 'ownListing' };
        const listings = getMarketplaceEntities(state, [ref]);
        return listings.length === 1 ? listings[0] : null;
    };

    return {
        activeTransactionsData,
        isAuthenticated,
        currentUser: ensureCurrentUser(currentUser),
        getListing,
        getOwnListing,
        scrollingDisabled: isScrollingDisabled(state),
        showListingError,
        reviews,
        fetchReviewsError,
        timeSlots,
        fetchTimeSlotsError,
        sendEnquiryInProgress,
        sendEnquiryError,
        similarDisciplineListings: pageListings,
        similarDisciplineError,
        listingAuthor,
        wishlistData,
        showListingLoading,
        searchPageListings,
        assetsData,
    };
};

const mapDispatchToProps = dispatch => ({
    onManageDisableScrolling: (componentId, disableScrolling) =>
        dispatch(manageDisableScrolling(componentId, disableScrolling)),
    callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
        dispatch(setInitialValues(values, saveToSessionStorage)),
    onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
    onSubmitContactDetails: values => dispatch(saveContactDetails(values)),
    onSendVerificationOtp: phoneNumber => dispatch(sendVerificationOtp(phoneNumber)),
    onVerifyPhoneNumber: params => dispatch(verifyPhoneNumber(params)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(ListingPageComponent);

ListingPage.setInitialValues = initialValues => setInitialValues(initialValues);
ListingPage.loadData = loadData;

export default ListingPage;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import Cookies from 'js-cookie';
import {
    Page,
    NamedRedirect,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    Modal,
    TermsOfService,
    AuthForms,
    ConfirmAuthBlock,
    Alert,
} from '../../components';
import { TopbarContainer } from '../../containers';
import {
    login,
    authenticationInProgress,
    signup,
    signupWithIdp,
    signupWithNativeIdp,
} from '../../ducks/Auth.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { fetchListingById } from './AuthenticationPage.duck';
import css from './AuthenticationPage.css';
import EmailVerification from './EmailVerification';
import { createSlug, LISTING_PAGE_PARAM_TYPE_DRAFT, parse } from '../../util/urlHelpers';
import {
    getTempListingLink,
    removeTempListingLink,
    TEMP_LISTING_SLUG_DATA_KEY,
} from '../../util/localStorage';
import { ReviewsWidget } from './ReviewsWidget';
import { allocateCreditsOnReferralSignUp } from './AuthenticationPage.helpers';

export class AuthenticationPageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tosModalOpen: false,
            authError: Cookies.get('st-autherror')
                ? JSON.parse(Cookies.get('st-autherror').replace('j:', ''))
                : null,
            authInfo: Cookies.get('st-authinfo')
                ? JSON.parse(Cookies.get('st-authinfo').replace('j:', ''))
                : null,
        };
        this.recaptchaRef = React.createRef();
    }
    componentDidMount() {
        // Remove the autherror cookie once the content is saved to state
        // because we don't want to show the error message e.g. after page refresh
        Cookies.remove('st-autherror');
        /** handle cases when login\signup is carried out
         * inside the mobile app not in the browser
         * document.addEventListener - is for android WebView
         * window.addEventListener - is for iOS WebView
         */
        document.addEventListener('message', this.handleNativeMessage);
        window.addEventListener('message', this.handleNativeMessage);

        const listingId = new URL(window.location.href).searchParams.get('listingId');

        if (listingId) {
            this.props.fetchListing(listingId);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('message', this.handleNativeMessage);
        window.removeEventListener('message', this.handleNativeMessage);
    }

    // check for relevant message event and trigger login
    handleNativeMessage = e => {
        try {
            const socialResponse = typeof e.data === 'string' ? JSON.parse(e.data) : {};

            if (socialResponse.idpId === 'facebook' || socialResponse.idpId === 'google') {
                const { submitSingupWithNativeIdp, location } = this.props;
                const { referral } = parse(location.search);
                // recieve response to complete login/registration on sharetribe
                submitSingupWithNativeIdp(socialResponse).then(userData => {
                    if (!referral || !userData || !userData.id) return;
                    allocateCreditsOnReferralSignUp(referral, userData.id.uuid);
                });
            }
        } catch (error) {
            console.warn('Unable to parse window message event data', error);
        }
    };

    render() {
        const {
            authInProgress,
            currentUser,
            intl,
            isAuthenticated,
            location,
            loginError,
            scrollingDisabled,
            signupError,
            submitLogin,
            submitSignup,
            confirmError,
            tab,
            sendVerificationEmailInProgress,
            sendVerificationEmailError,
            onResendVerificationEmail,
            onManageDisableScrolling,
            submitSingupWithIdp,
            listingData,
            listingDataFetchingInProgress,
            tempDataListingRequestSuccess,
            tmpDLPreflightRequestInProgress,
        } = this.props;

        const isConfirm = tab === 'confirm';
        const isLogin = tab === 'login';
        const isEmailVerification = tab === 'email-verification';
        const isSignup = tab === 'signup';
        const isSignupRider = tab === 'signup-rider';
        const isSignupHorseowner = tab === 'signup-horse-owner';

        const locationFrom = location.state && location.state.from ? location.state.from : null;
        const authinfoFrom =
            this.state.authInfo && this.state.authInfo.from ? this.state.authInfo.from : null;
        const from = locationFrom ? locationFrom : authinfoFrom ? authinfoFrom : null;
        const { tempListingInfoSaved, referral } = parse(location.search);

        const user = ensureCurrentUser(currentUser);
        const currentUserLoaded = !!user.id;

        const { [TEMP_LISTING_SLUG_DATA_KEY]: tempListing } = getTempListingLink() || {};

        if (isAuthenticated && tempDataListingRequestSuccess && tempListing) {
            /**
             * user being logged out finished 1st step of listing editing;
             * redirect him after he finishes onboarding flow with user type horseowner
             */
            removeTempListingLink();

            return tempListing.id ? (
                <NamedRedirect
                    name="EditListingPage"
                    params={{
                        id: tempListing.id.uuid,
                        slug: createSlug(tempListing),
                        type: LISTING_PAGE_PARAM_TYPE_DRAFT,
                        tab: 'overview',
                    }}
                />
            ) : (
                <NamedRedirect name="LandingPage" />
            );
        }
        // We only want to show the email verification dialog in the signup
        // tab if the user isn't being redirected somewhere else
        // (i.e. `from` is present). We must also check the `emailVerified`
        // flag only when the current user is fully loaded.
        const showEmailVerification =
            isEmailVerification && currentUserLoaded && !user.attributes.emailVerified;
        // Already authenticated, redirect away from auth page
        const notAuthenticatedForEmailVerification = !isAuthenticated && isEmailVerification;
        /**
         * tempDataListingRequestSuccess do not redirect user until
         * temp listing is created (if  needed)
         */
        if (
            isAuthenticated &&
            !isEmailVerification &&
            from &&
            (tempListing ? tempDataListingRequestSuccess : true)
        ) {
            // do not redirect a user to the pages which also have
            // redirection cases, e.g. onboarding page

            // WARNING !!!
            // before change the if statement
            // address to the redirectFromCollectingInfo variable at Routes.js file
            return <Redirect to={from} />;
        } else if (
            isAuthenticated &&
            currentUserLoaded &&
            !showEmailVerification &&
            !tmpDLPreflightRequestInProgress &&
            (tempListing ? tempDataListingRequestSuccess : true)
        ) {
            return <NamedRedirect name="LandingPage" />;
        } else if (notAuthenticatedForEmailVerification) {
            return <NamedRedirect name="SignupPage" params={{ tab: 'signup' }} />;
        }

        const loginErrorMessage = (
            <div className={css.error}>
                <span>{intl.formatMessage({ id: 'AuthenticationPage.loginFailed' })} </span>
            </div>
        );

        const signupErrorMessage = (
            <div className={css.error}>
                <span>
                    {intl.formatMessage({ id: 'AuthenticationPage.signupFailedEmailAlreadyTaken' })}{' '}
                </span>
            </div>
        );

        // eslint-disable-next-line no-confusing-arrow
        const errorMessage = (error, message) => (error ? message : null);
        const loginOrSignupError = isLogin
            ? errorMessage(loginError, loginErrorMessage)
            : errorMessage(signupError, signupErrorMessage);

        const idp = this.state.authInfo
            ? this.state.authInfo.idpId.replace(/^./, str => str.toUpperCase())
            : null;

        const formContent = isConfirm ? (
            <ConfirmAuthBlock
                idp={idp}
                authInfo={this.state.authInfo}
                authInProgress={authInProgress}
                submitSingupWithIdp={submitSingupWithIdp}
                confirmError={confirmError}
                currentUser={currentUser}
            />
        ) : (
            <AuthForms
                tab={tab}
                error={loginOrSignupError}
                submitLogin={submitLogin}
                submitSignup={submitSignup}
                authInProgress={authInProgress}
                from={from}
                onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
                listingData={listingData}
                listingDataFetchingInProgress={listingDataFetchingInProgress}
            />
        );

        const siteTitle = config.siteTitle;
        const schemaTitle = isLogin
            ? intl.formatMessage({ id: 'AuthenticationPage.schemaTitleLogin' }, { siteTitle })
            : intl.formatMessage({ id: 'AuthenticationPage.schemaTitleSignup' }, { siteTitle });

        const topbarClasses = classNames({
            [css.hideOnMobile]: showEmailVerification,
        });

        const hideBlobBackground = isSignupRider || isSignupHorseowner;
        const ogProps = referral
            ? {
                  ogDescription: `
Werde Mitglied von HorseDeal 🐴💜

Nutze meinen Link, um kostenlos Mitglied zu werden und 100 Credits zu erhalten.`,
              }
            : {};

        return (
            <Page
                title={schemaTitle}
                scrollingDisabled={scrollingDisabled}
                schema={{
                    '@context': 'http://schema.org',
                    '@type': 'WebPage',
                    name: schemaTitle,
                }}
                {...ogProps}
            >
                <LayoutSingleColumn>
                    <LayoutWrapperTopbar>
                        <TopbarContainer className={topbarClasses} />
                    </LayoutWrapperTopbar>
                    <LayoutWrapperMain
                        className={classNames(css.layoutWrapperMain, {
                            [css.hideBlobBackground]: hideBlobBackground,
                        })}
                        blobBackground
                    >
                        {tempListingInfoSaved && (
                            <Alert
                                header="AuthenticationPage.tempListingInfoSavedHeading"
                                description="AuthenticationPage.tempListingInfoSavedDesc"
                                rootClassName={css.tempListingInfoSaved}
                                closeClassName={css.tempListingInfoSavedCloseBtn}
                                type="success"
                            />
                        )}
                        <div className={css.root}>
                            {showEmailVerification ? (
                                <EmailVerification
                                    user={user}
                                    sendVerificationEmailError={sendVerificationEmailError}
                                    sendVerificationEmailInProgress={
                                        sendVerificationEmailInProgress
                                    }
                                    onResendVerificationEmail={onResendVerificationEmail}
                                />
                            ) : (
                                formContent
                            )}
                        </div>
                        {isSignup && <ReviewsWidget />}
                        <Modal
                            id="AuthenticationPage.tos"
                            isOpen={this.state.tosModalOpen}
                            onClose={() => this.setState({ tosModalOpen: false })}
                            onManageDisableScrolling={onManageDisableScrolling}
                        >
                            <div className={css.termsWrapper}>
                                <h2 className={css.termsHeading}>
                                    <FormattedMessage id="AuthenticationPage.termsHeading" />
                                </h2>
                                <TermsOfService />
                            </div>
                        </Modal>
                    </LayoutWrapperMain>
                    <LayoutWrapperFooter>
                        <Footer />
                    </LayoutWrapperFooter>
                </LayoutSingleColumn>
            </Page>
        );
    }
}

AuthenticationPageComponent.defaultProps = {
    currentUser: null,
    loginError: null,
    signupError: null,
    confirmError: null,
    tab: 'signup',
    sendVerificationEmailError: null,
    showSocialLoginsForTests: false,
};

const { bool, func, object, oneOf, shape } = PropTypes;

AuthenticationPageComponent.propTypes = {
    authInProgress: bool.isRequired,
    currentUser: propTypes.currentUser,
    isAuthenticated: bool.isRequired,
    loginError: propTypes.error,
    scrollingDisabled: bool.isRequired,
    signupError: propTypes.error,
    submitLogin: func.isRequired,
    submitSignup: func.isRequired,
    tab: oneOf([
        'login',
        'signup',
        'email-verification',
        'confirm',
        'signup-rider',
        'signup-horse-owner',
    ]),

    sendVerificationEmailInProgress: bool.isRequired,
    sendVerificationEmailError: propTypes.error,
    onResendVerificationEmail: func.isRequired,
    onManageDisableScrolling: func.isRequired,

    // from withRouter
    location: shape({ state: object }).isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = state => {
    const {
        isAuthenticated,
        loginError,
        signupError,
        confirmError,
        tempDataListingRequestSuccess,
        tmpDLPreflightRequestInProgress,
    } = state.Auth;
    const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
    const { listingData, listingDataFetchingInProgress } = state.AuthenticationPage;

    return {
        authInProgress: authenticationInProgress(state),
        currentUser,
        isAuthenticated,
        loginError,
        scrollingDisabled: isScrollingDisabled(state),
        confirmError,
        signupError,
        confirmError,
        sendVerificationEmailInProgress,
        sendVerificationEmailError,
        listingData,
        listingDataFetchingInProgress,
        tempDataListingRequestSuccess,
        tmpDLPreflightRequestInProgress,
    };
};

const mapDispatchToProps = dispatch => ({
    fetchListing: listingId => dispatch(fetchListingById(listingId)),
    submitLogin: ({ email, password, callback }) => dispatch(login(email, password, callback)),
    submitSignup: (params, paramsToRedirect) => dispatch(signup(params, paramsToRedirect)),
    onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
    submitSingupWithIdp: params => dispatch(signupWithIdp(params)),
    submitSingupWithNativeIdp: params => dispatch(signupWithNativeIdp(params)),
    onManageDisableScrolling: (componentId, disableScrolling) =>
        dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AuthenticationPage = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(AuthenticationPageComponent);

export default AuthenticationPage;

import { insertAuthorDataIntoListing } from '../../util/listings';

export const FETCH_LISTINGS_BY_ID = 'app/AuthenticationPage/FETCH_LISTINGS_BY_ID';
export const FETCH_LISTINGS_BY_ID_END = 'app/AuthenticationPage/FETCH_LISTINGS_BY_ID_END';

const initialState = {
  listingData: null,
  listingDataFetchingInProgress: false,
};

const AuthenticationPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_LISTINGS_BY_ID:
      return { ...state, listingData: null, listingDataFetchingInProgress: true };
    case FETCH_LISTINGS_BY_ID_END:
      return { ...state, listingData: payload, listingDataFetchingInProgress: false };
    default:
      return state;
  }
};

export default AuthenticationPageReducer;

export const fetchListingByIdRequest = () => ({
  type: FETCH_LISTINGS_BY_ID,
});

export const fetchListingByIdRequestEnds = listingData => ({
  type: FETCH_LISTINGS_BY_ID_END,
  payload: listingData,
});

export const fetchListingById = listingId => async (dispatch, getState, sdk) => {
  dispatch(fetchListingByIdRequest());

  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',
      'variants.square-small',
    ],
  };

  try {
    const {
      data: { data, included },
    } = await sdk.listings.show(params);

    dispatch(fetchListingByIdRequestEnds(insertAuthorDataIntoListing(data, included)));
  } catch (err) {
    dispatch(fetchListingByIdRequestEnds(null));
  }
};

import React from 'react';

import { FormattedMessage } from '../../../util/reactIntl';
import ClockIcon from '../icons/ClockIcon';
import { USER_AVAILABILITY } from '../EditUserInfoModalSectionConstants';

import sectionCss from './AvailabilitySection.css';
import css from '../ProfileSettingsForm.css';
import { LoopIcon, WarningIcon } from '../../../icons';
import classNames from 'classnames';
import ScoreBadge from '../ScoreBadge';
import { IconSpinner } from '../../../components';
import { RIDER_AVAILABILITY_NOT_AVAILABLE } from '../../../marketplace-custom-config';

const AvailabilitySection = ({
    availability,
    openForPartTimeProposals,
    renderEditPencilIcon,
    isPublic,
    handleEditSection,
    scoreData,
    scoreReqInProgress,
}) => {
    const noData = !availability;

    if (isPublic && noData) {
        return null;
    }

    return (
        <div className={css.infoBlock}>
            {scoreData && scoreData.baseScoreBreakdown && (
                <ScoreBadge scoreData={scoreData.baseScoreBreakdown?.availabilityScore} />
            )}
            {scoreReqInProgress && <IconSpinner />}
            <h2 className={css.infoHeading}>
                <FormattedMessage id="ProfileSettingsForm.availabilityHeading" />
                {noData ? (
                    <WarningIcon rootClassName={css.noInfoWarningIcon} />
                ) : (
                    <div className={css.editBlock}>{renderEditPencilIcon(USER_AVAILABILITY)}</div>
                )}
            </h2>
            {noData ? (
                <button
                    type="button"
                    onClick={() => {
                        handleEditSection(USER_AVAILABILITY);
                    }}
                    className={`${css.actionItem} ${css.actionBtn}`}
                >
                    <FormattedMessage id="ProfileSettingsForm.editUserInfoAction-userAvailability" />
                </button>
            ) : (
                <p className={sectionCss.availabilityItem}>
                    <ClockIcon />
                    {availability === 'flexible' ? (
                        <FormattedMessage id="CollectUserInfoWizard.availabilityFlexibleOption" />
                    ) : availability === RIDER_AVAILABILITY_NOT_AVAILABLE ? (
                        <FormattedMessage id="CollectUserInfoWizard.notAvailable" />
                    ) : (
                        <FormattedMessage
                            id="ProfileSettingsForm.availabilityPerWeek"
                            values={{ availability }}
                        />
                    )}
                </p>
            )}
            {!noData && openForPartTimeProposals && (
                <p
                    className={classNames([
                        sectionCss.availabilityItem,
                        sectionCss.availabilityItemProposal,
                    ])}
                >
                    <LoopIcon />
                    <FormattedMessage
                        id={`ProfileSettingsForm.openForPartTimeProposals-${openForPartTimeProposals}${
                            isPublic ? '-public' : ''
                        }`}
                    />
                </p>
            )}
        </div>
    );
};

export default AvailabilitySection;

import {
    CREDITS_ACTION_REFERRAL_INVITED_USER,
    CREDITS_ACTION_REFERRAL_INVITER,
} from '../../credits-config';
import { updateCreditsHistory } from '../../util/api';

export const allocateCreditsOnReferralSignUp = (userId, currentUserId) => {
    /**
     * Allocate credits to user invited
     */
    updateCreditsHistory(
        JSON.stringify({
            userId: currentUserId,
            eventName: CREDITS_ACTION_REFERRAL_INVITED_USER,
            extraFields: {
                inviterId: userId,
                invitedUserId: currentUserId,
            },
        })
    );
    /**
     * Allocate credits to inviter
     */
    updateCreditsHistory(
        JSON.stringify({
            userId,
            eventName: CREDITS_ACTION_REFERRAL_INVITER,
            extraFields: {
                inviterId: userId,
                invitedUserId: currentUserId,
            },
        })
    );
};

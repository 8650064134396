import React, { useState } from 'react';
import { compose } from 'redux';
import { FormattedMessage } from '../../util/reactIntl';
import css from './AppointmentMessage.css';
import classNames from 'classnames';
import { trimDisplayNameLastWord } from '../../util/data';
import { injectIntl } from '../../util/reactIntl';
import {
    APPOINTMENT_PENDING_STATUS,
    APPOINTMENT_DECLINED_STATUS,
    APPOINTMENT_CANCELED_STATUS,
    APPOINTMENT_ACCEPTED_STATUS,
    ACCEPT_APPOINTMENT_REQUEST,
    DECLINE_APPOINTMENT_REQUEST,
    CANCEL_APPOINTMENT_REQUEST,
} from '../../util/transaction';
import { Avatar, InterruptProcessModal, SecondaryButton } from '../../components';
import { formatDateToString } from '../../util/dates';
import { CalendarTinyIcon, ClockIconTiny } from '../../icons';

const AppointmentMessage = ({
    currentProvider,
    currentCustomer,
    currentUser,
    currentListing,
    appointmentData,
    handleAppointmentAction,
    appointmentInProgress,
    appointmentError,
    children,
    intl,
}) => {
    const [interruptModalVisible, setInterruptModalVisible] = useState(false);
    const [modalAction, setModalAction] = useState(null);

    const { data, status, sender, createdAt: _createdAt, updatedAt: _updatedAt } = appointmentData;
    const { date, time, location, message: userMessage } = data;
    const createdAt = _createdAt ? new Date(_createdAt) : new Date();

    const isSender = sender.userId === currentUser.id.uuid;

    const isCustomer = currentUser.id.uuid === currentCustomer.id.uuid;
    const otherParty = isCustomer ? currentProvider : currentCustomer;
    const otherPartyName = trimDisplayNameLastWord(otherParty.attributes.profile.displayName);

    const handleModalVisibility = (type, flag) => {
        setInterruptModalVisible(flag);
        setModalAction(type);
    };

    const handler = action => {
        if (appointmentInProgress) return;

        handleAppointmentAction(
            { appointmentData, otherParty, currentUser, currentListing, intl },
            action
        );
        handleModalVisibility(null, false);
    };

    const getAppointmentStatus = () => {
        const config = {
            [APPOINTMENT_PENDING_STATUS]: {
                statusMessage: (
                    <>
                        <FormattedMessage id="AppointmentScheduler.appointmentPending" />{' '}
                        <p
                            className={css.link}
                            onClick={() => handleModalVisibility(CANCEL_APPOINTMENT_REQUEST, true)}
                        >
                            <FormattedMessage id="AppointmentScheduler.appointmentPendingCancelAction" />
                        </p>
                    </>
                ),
                statusClassName: css.statusPending,
            },
            [APPOINTMENT_DECLINED_STATUS]: {
                statusMessage: <FormattedMessage id="AppointmentScheduler.appointmentDeclined" />,
                statusClassName: isSender ? css.statusPending : css.statusDeclined,
            },
            [APPOINTMENT_CANCELED_STATUS]: {
                statusMessage: <FormattedMessage id="AppointmentScheduler.appointmentCanceled" />,
                statusClassName: isSender ? css.statusPending : css.statusDeclined,
            },
            [APPOINTMENT_ACCEPTED_STATUS]: {
                statusMessage: <FormattedMessage id="AppointmentScheduler.appointmentApproved" />,
                statusClassName: isSender ? css.statusPending : css.statusApproved,
            },
        };
        const { statusClassName, statusMessage } = config[status];

        return (
            <div className={classNames(css.appointmentStatus, statusClassName)}>
                {statusMessage}
            </div>
        );
    };

    const getAppointmentDate = () => {
        const meetingDate = new Date(date);
        const options = {
            weekday: 'long',
            day: 'numeric',
            year: 'numeric',
            month: 'long',
        };
        return meetingDate.toLocaleDateString('de-DE', options);
    };

    const actionButtonGroup = (
        <div className={css.buttonGroup}>
            {/** accept appointment */}
            <SecondaryButton
                className={classNames(css.appointmentButtonAccept, css.appointmentButton)}
                inProgress={appointmentInProgress}
                onClick={() => handler(ACCEPT_APPOINTMENT_REQUEST)}
            >
                <FormattedMessage id="AppointmentScheduler.appointmentAcceptInvitation" />
            </SecondaryButton>
            {/** decline appointment */}
            <SecondaryButton
                className={css.appointmentButton}
                inProgress={appointmentInProgress}
                onClick={() => handleModalVisibility(DECLINE_APPOINTMENT_REQUEST, true)}
            >
                <FormattedMessage id="AppointmentScheduler.appointmentDeclineInvitation" />
            </SecondaryButton>
        </div>
    );

    const isPending = status === APPOINTMENT_PENDING_STATUS;
    const shouldActionButtonsBeVisible = isPending && !isSender;
    const showStatus = !shouldActionButtonsBeVisible;

    // const showTransitionMessage = status !== APPOINTMENT_PENDING_STATUS;

    const displayMessage = isSender ? (
        <FormattedMessage
            id="AppointmentScheduler.appointmentHeadingSender"
            values={{ otherPartyName }}
        />
    ) : (
        <FormattedMessage
            id="AppointmentScheduler.appointmentHeadingReceiver"
            values={{ otherPartyName }}
        />
    );

    const wrapperClasses = isSender
        ? classNames(css.ownMessageWrapper, css.appointmentWrapper)
        : css.appointmentWrapper;

    const messageClasses = isSender
        ? classNames(css.ownMessage, css.appointmentMessage)
        : css.appointmentMessage;

    const messageDateClasses = isSender
        ? classNames(css.messageDate, css.ownMessageDate)
        : css.messageDate;

    const appointmentErrorMaybe = isPending && appointmentError;

    return (
        <React.Fragment>
            <section className={messageClasses}>
                {!isSender && <Avatar className={css.avatar} user={otherParty} />}
                <div>
                    <div className={wrapperClasses}>
                        <div className={css.appointmentContent}>
                            <div className={css.appointmentHeading}>{displayMessage}</div>
                            <div className={css.userMessage}>{userMessage}</div>
                            <div
                                className={classNames([
                                    css.appointmentField,
                                    css.appointmentFieldFirst,
                                    {
                                        [css.appointmentFieldOwnMessage]: !isSender,
                                    },
                                ])}
                            >
                                <CalendarTinyIcon />
                                <span className={css.appointmentTimeDate}>
                                    {getAppointmentDate()}
                                </span>
                            </div>
                            <div
                                className={classNames(css.appointmentField, {
                                    [css.appointmentFieldOwnMessage]: !isSender,
                                })}
                            >
                                <ClockIconTiny />
                                <span className={css.appointmentTimeDate}>{time}</span>
                            </div>
                            <a
                                className={css.googleBtn}
                                href={`https://maps.google.com/maps/place/${location}`}
                                target="_blank"
                            >
                                <FormattedMessage id="AppointmentScheduler.appointmentLocationButton" />
                            </a>
                        </div>

                        {shouldActionButtonsBeVisible && actionButtonGroup}

                        {showStatus && getAppointmentStatus()}

                        {appointmentErrorMaybe ? (
                            <div className={css.appointmentError}>
                                <FormattedMessage id="AppointmentScheduler.sendingFailed" />
                                <br />
                                {typeof appointmentError === 'string' && appointmentError}
                            </div>
                        ) : null}
                    </div>
                    <div className={messageDateClasses}>
                        {children} {formatDateToString(createdAt)}
                    </div>
                </div>
                {modalAction && interruptModalVisible && (
                    <InterruptProcessModal
                        isOpen
                        onClose={() => setInterruptModalVisible(false)}
                        handleInterruption={() => handler(modalAction)}
                        descriptionId={`AppointmentScheduler.interruptProcessModalDesc-${modalAction}`}
                        sidenoteId={`AppointmentScheduler.interruptProcessModalSidenote-${modalAction}`}
                        sidenoteValues={{ otherPartyName }}
                        actionId={`AppointmentScheduler.interruptProcessModalAction-${modalAction}`}
                    />
                )}
            </section>
        </React.Fragment>
    );
};

export default compose(injectIntl)(AppointmentMessage);

import React, { useState, useEffect } from 'react';
import { bool, func, number, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { propTypes } from '../../util/types';
import { sendVerificationEmail, hasCurrentUserErrors } from '../../ducks/user.duck';
import { logout, authenticationInProgress } from '../../ducks/Auth.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { Topbar } from '../../components';
import { getOwnListingsById } from '../ManageListingsPage/ManageListingsPage.duck';

export const TopbarContainerComponent = ({ hasGenericError, location, ...rest }) => {
    const [showError, setShowError] = useState(null);

    const urlSearchParams = new URLSearchParams(location.search);
    const errorMessage = urlSearchParams.get('errorMessage');

    useEffect(() => {
        setShowError(hasGenericError || errorMessage);
    }, [errorMessage, hasGenericError]);

    return (
        <Topbar
            showGenericError={showError}
            setShowGenericError={setShowError}
            genericErrorContent={errorMessage}
            location={location}
            {...rest}
        />
    );
};

TopbarContainerComponent.defaultProps = {
    currentPage: null,
    currentSearchParams: null,
    currentUser: null,
    currentUserHasOrders: null,
    sendVerificationEmailError: null,
    rawSearchParams: null,
};

TopbarContainerComponent.propTypes = {
    authInProgress: bool.isRequired,
    currentPage: string,
    currentSearchParams: object,
    currentUser: propTypes.currentUser,
    currentUserHasPublishedListings: bool.isRequired,
    currentUserHasOrders: bool,
    isAuthenticated: bool.isRequired,
    onLogout: func.isRequired,
    onManageDisableScrolling: func.isRequired,
    sendVerificationEmailInProgress: bool.isRequired,
    sendVerificationEmailError: propTypes.error,
    onResendVerificationEmail: func.isRequired,
    hasGenericError: bool.isRequired,
    rawSearchParams: object,
    // from withRouter
    history: shape({
        push: func.isRequired,
    }).isRequired,
    location: shape({ state: object }).isRequired,
};

const mapStateToProps = state => {
    // Topbar needs isAuthenticated
    const { isAuthenticated, logoutError } = state.Auth;
    // Topbar needs user info.
    const {
        currentUser,
        currentUserHasPublishedListings,
        currentUserHasOrders,
        currentUserUnreadMessagesRider,
        currentUserUnreadMessagesOwner,
        sendVerificationEmailInProgress,
        sendVerificationEmailError,
    } = state.user;
    const { disableScrollRequests } = state.UI;
    const { currentPageResultIds } = state.ManageListingsPage;

    const { wishlistListingStatusChanged } = state.FavoritePage;

    const hasGenericError = !!(logoutError || hasCurrentUserErrors(state));
    return {
        authInProgress: authenticationInProgress(state),
        currentUser,
        currentUserHasPublishedListings,
        currentUserHasOrders,
        isAuthenticated,
        sendVerificationEmailInProgress,
        sendVerificationEmailError,
        hasGenericError,
        wishlistListingStatusChanged,
        currentUserUnreadMessagesRider,
        currentUserUnreadMessagesOwner,
        disableScrollRequests,
        hasDraftListing: (
            getOwnListingsById(state, currentPageResultIds) || [{ attributes: { state: null } }]
        ).some(({ attributes }) => attributes.state === 'draft'),
    };
};

const mapDispatchToProps = dispatch => ({
    onLogout: historyPush => dispatch(logout(historyPush)),
    onManageDisableScrolling: (componentId, disableScrolling) =>
        dispatch(manageDisableScrolling(componentId, disableScrolling)),
    onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TopbarContainer = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(TopbarContainerComponent);

export default TopbarContainer;

import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { bool, object, string } from 'prop-types';
import * as validators from '../../util/validators';
import { FieldSelect, FieldTextInput } from '../../components';

import { stripeCountryConfigs } from './PayoutDetailsForm';
import css from './PayoutDetailsForm.css';

export const CANADIAN_PROVINCES = [
    'AB',
    'BC',
    'MB',
    'NB',
    'NL',
    'NS',
    'NT',
    'NU',
    'ON',
    'PE',
    'QC',
    'SK',
    'YT',
];

const PayoutDetailsAddress = props => {
    const { className, country, intl, disabled, form, fieldId } = props;
    const countryConfig = country ? stripeCountryConfigs(country).addressConfig : null;

    const isRequired = (countryConfig, field) => {
        return countryConfig[field];
    };

    const showTitle =
        fieldId === 'company.address' ||
        fieldId === 'individual' ||
        fieldId === 'company.personalAddress';
    const addressTitle = intl.formatMessage({
        id:
            fieldId === 'company.address'
                ? 'PayoutDetailsForm.companyAddressTitle'
                : 'PayoutDetailsForm.streetAddressLabel',
    });

    const showAddressLine = country && isRequired(countryConfig, 'addressLine');

    const streetAddressPlaceholder = intl.formatMessage({
        id: 'PayoutDetailsForm.streetAddressPlaceholder',
    });

    const houseNumberPlaceholder = intl.formatMessage({
        id: 'PayoutDetailsForm.houseNumberPlaceholder',
    });
    const houseNumberRequired = intl.formatMessage({
        id: 'PayoutDetailsForm.houseNumberRequired',
    });
    const streetAddressRequired = validators.required(
        intl.formatMessage({
            id: 'PayoutDetailsForm.streetAddressRequired',
        })
    );

    const showPostalCode = country && isRequired(countryConfig, 'postalCode');

    const postalCodePlaceholder = intl.formatMessage({
        id: 'PayoutDetailsForm.postalCodePlaceholder',
    });
    const postalCodeRequired = validators.required(
        intl.formatMessage({
            id: 'PayoutDetailsForm.postalCodeRequired',
        })
    );

    const showCity = country && isRequired(countryConfig, 'city');

    const cityPlaceholder = intl.formatMessage({ id: 'PayoutDetailsForm.cityPlaceholder' });
    const cityRequired = validators.required(
        intl.formatMessage({
            id: 'PayoutDetailsForm.cityRequired',
        })
    );

    const showState = country && isRequired(countryConfig, 'state');

    const statePlaceholder = intl.formatMessage({ id: 'PayoutDetailsForm.statePlaceholder' });
    const stateRequired = validators.required(
        intl.formatMessage({
            id: 'PayoutDetailsForm.stateRequired',
        })
    );

    const showProvince = country && isRequired(countryConfig, 'province');

    const provincePlaceholder = intl.formatMessage({
        id: 'PayoutDetailsForm.canadianProvincePlaceholder',
    });
    const provinceRequired = validators.required(
        intl.formatMessage({
            id: 'PayoutDetailsForm.canadianProvinceRequired',
        })
    );

    return (
        <div className={className ? className : css.sectionContainer}>
            {showTitle ? <h3 className={css.subTitle}>{addressTitle}</h3> : null}

            {showAddressLine ? (
                <div className={css.formRow}>
                    <FieldTextInput
                        id="streetAddress"
                        name="streetAddress"
                        disabled={disabled}
                        className={css.streetAddress}
                        type="text"
                        autoComplete="street-address"
                        placeholder={streetAddressPlaceholder}
                        validate={validators.composeValidators(
                            streetAddressRequired,
                            validators.isValidAddress(
                                'Zahlen und Sonderzeichen sind nicht erlaubt.'
                            )
                        )}
                        onUnmount={() => form.change('streetAddress', undefined)}
                    />
                    <FieldTextInput
                        id="houseNumber"
                        name="houseNumber"
                        disabled={disabled}
                        className={css.houseNumber}
                        type="text"
                        autoComplete="house-number"
                        placeholder={houseNumberPlaceholder}
                        validate={validators.required(houseNumberRequired)}
                        onUnmount={() => form.change('houseNumber', undefined)}
                    />
                </div>
            ) : null}
            <div className={css.formRow}>
                {showPostalCode ? (
                    <FieldTextInput
                        id="postalCode"
                        name="postalCode"
                        disabled={disabled}
                        className={css.postalCode}
                        type="text"
                        autoComplete="postal-code"
                        placeholder={postalCodePlaceholder}
                        validate={postalCodeRequired}
                        onUnmount={() => form.change('postalCode', undefined)}
                    />
                ) : null}
                {showCity ? (
                    <FieldTextInput
                        id="city"
                        name="city"
                        disabled={disabled}
                        className={css.city}
                        type="text"
                        autoComplete="address-level2"
                        placeholder={cityPlaceholder}
                        validate={validators.composeValidators(
                            cityRequired,
                            validators.isValidAddress(
                                'Zahlen und Sonderzeichen sind nicht erlaubt.'
                            )
                        )}
                        onUnmount={() => form.change('city', undefined)}
                    />
                ) : null}
            </div>
            {showState ? (
                <FieldTextInput
                    id="state"
                    name="state"
                    disabled={disabled}
                    className={css.state}
                    type="text"
                    autoComplete="address-level1"
                    placeholder={statePlaceholder}
                    validate={stateRequired}
                    onUnmount={() => form.change('state', undefined)}
                />
            ) : null}

            {showProvince ? (
                <FieldSelect
                    id="province"
                    name="province"
                    disabled={disabled}
                    className={css.selectCountry}
                    autoComplete="address-level1"
                    validate={provinceRequired}
                    form={form}
                    placeholder={provincePlaceholder}
                    optionsList={CANADIAN_PROVINCES.map(p => ({
                        label: intl.formatMessage({
                            id: `PayoutDetailsForm.canadianProvinceNames.${p}`,
                        }),
                        value: p,
                    }))}
                />
            ) : null}
        </div>
    );
};
PayoutDetailsAddress.defaultProps = {
    country: null,
    disabled: false,
    fieldId: null,
};

PayoutDetailsAddress.propTypes = {
    country: string,
    disabled: bool,
    form: object.isRequired,
    fieldId: string,

    // from injectIntl
    intl: intlShape.isRequired,
};

export default PayoutDetailsAddress;

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import {
    Modal,
    Button,
    Form,
    FieldTextInput,
    FieldPhoneNumberInput,
    FieldSelect,
} from '../../components';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import css from './MissingPhoneNumberModal.css';
import { bool, func, number, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { required, composeValidators, isNumber } from '../../util/validators';
import { ProtectionInfoIcon, SuccessIconThin } from '../../icons';
import { countryCodes, addMaskToPhoneNumber } from './helpers';

const ACCEPT_VERIFICATION = 'acceptVerification';
const ENTER_PHONE_NUMBER = 'enterPhoneNumber';
const CONFIRM_PHONE_NUMBER = 'confirmPhoneNumber';
const PHONE_NUMBER_CONFIRMED = 'phoneNumberConfirmed';

const MissingPhoneNumberModalComponent = props => {
    const {
        currentOtp,
        id,
        intl,
        initialState,
        isOpen,
        history,
        onClose,
        onSubmitContactDetails,
        onSendVerificationOtp,
        onVerifyPhoneNumber,
        missingPhoneNumberDescriptionId,
        redirectOnCLose,
        currentUser,
        phoneNumber: currentPhoneNumber,
        countryCode: countryCodeFromProps,
    } = props;

    const { attributes } = currentUser || {};
    const { profile, email } = attributes || {};

    const protectedData = currentUser ? profile.protectedData : null;
    const currentPhoneNumberVerified = protectedData && protectedData.phoneVerified;
    const currentEmail = currentUser ? email : null;

    const initialStateMaybe =
        initialState || (currentPhoneNumber && CONFIRM_PHONE_NUMBER) || ENTER_PHONE_NUMBER;

    const [portalRoot, setPortalRoot] = useState(null);
    const [verificationState, setVerificationState] = useState(initialStateMaybe);
    const [otpCode, setOtpCode] = useState(null);
    const [otpError, setOtpError] = useState(null);
    const [phoneSubmitError, setPhoneSubmitError] = useState(null);
    const [phoneVerificationError, setPhoneVerificationError] = useState(null);
    const [submitInProgress, setSubmitInProgress] = useState(null);

    useEffect(() => setPortalRoot(document.getElementById('portal-root')), []);

    useEffect(() => setOtpCode(currentOtp), [currentOtp]);

    const initialPhoneNumber = currentPhoneNumber ? currentPhoneNumber.replace(/\+\d\d/, '') : null;

    const handleCompleteProfileDismiss = () =>
        history.push(createResourceLocatorString('LandingPage', routeConfiguration(), {}, {}));

    const handleSubmitPhoneNumberForm = async values => {
        setPhoneSubmitError(null);
        setSubmitInProgress(true);
        setOtpError(null);

        const { phoneNumber, countryCode } = values;

        const phoneNumberFormatted = countryCode.toString() + phoneNumber.replace(/\D/g, '');

        const sendOtp = async () => {
            try {
                const otp = await onSendVerificationOtp(phoneNumberFormatted);
                setOtpCode(otp);
                setVerificationState(CONFIRM_PHONE_NUMBER);
                setSubmitInProgress(false);
            } catch (e) {
                setOtpError(
                    e || intl.formatMessage({ id: 'MissingPhoneNumberModal.otpSubmitError' })
                );
                setSubmitInProgress(false);
                return;
            }
        };

        try {
            await onSubmitContactDetails({ ...values, currentEmail, currentPhoneNumber });
            sendOtp();
        } catch (e) {
            setPhoneSubmitError(
                e.message ||
                    e.data ||
                    intl.formatMessage({ id: 'MissingPhoneNumberModal.phoneNumberError' })
            );
            setSubmitInProgress(false);
        }
    };

    const verifyPhoneNumber = () => {
        setSubmitInProgress(true);
        setPhoneVerificationError(null);

        onVerifyPhoneNumber({
            phoneNumber: `${countryCodeFromProps}${currentPhoneNumber}`,
            phoneVerified: true,
        })
            .then(() => setVerificationState(PHONE_NUMBER_CONFIRMED))
            .catch(e =>
                setPhoneVerificationError(
                    e.message ||
                        e.data ||
                        intl.formatMessage({ id: 'MissingPhoneNumberModal.phoneNumberError' })
                )
            )
            .finally(() => setSubmitInProgress(false));
    };

    const handleChange = code => {
        setOtpError(null);

        if (code.length > 6) {
            setOtpError(intl.formatMessage({ id: 'MissingPhoneNumberModal.otpCodeError' }));
        } else if (code.length == 6 && parseInt(code) !== otpCode) {
            setOtpError(intl.formatMessage({ id: 'MissingPhoneNumberModal.otpCodeMatchError' }));
        }
    };

    const handleReSendOtp = () => {
        if (!currentPhoneNumber) {
            return setOtpError(
                intl.formatMessage({ id: 'MissingPhoneNumberModal.otpCodePhoneMissingError' })
            );
        }

        setOtpError(null);
        setSubmitInProgress(true);

        onSendVerificationOtp(`${countryCodeFromProps}${currentPhoneNumber}`)
            .then(otp => {
                setOtpCode(otp);
            })
            .catch(e =>
                setOtpError(
                    e || intl.formatMessage({ id: 'MissingPhoneNumberModal.otpSubmitError' })
                )
            )
            .finally(() => setSubmitInProgress(false));
    };

    const regionRequiredMessage = intl.formatMessage({
        id: 'MissingPhoneNumberModal.fieldIsRequired',
    });

    const phonePlaceholder = intl.formatMessage({
        id: 'MissingPhoneNumberModal.phonePlaceholder',
    });

    const messagePlaceholder = intl.formatMessage({
        id: 'SMSVerificationForm.messagePlaceholder',
    });

    const acceptVerificationContent = (
        <div className={css.completeProfileWrapper}>
            <SuccessIconThin rootClassName={css.successIcon} />
            <h3 className={classNames(css.completeProfileHeader)}>
                <FormattedMessage id="MissingPhoneNumberModal.missingPhoneNumberModalTitle" />
            </h3>
            <p className={css.completeProfileParagraph}>
                <FormattedMessage
                    id={`MissingPhoneNumberModal.${missingPhoneNumberDescriptionId}`}
                />
            </p>
            <footer className={css.footerOverlay}>
                <Button type="button" className={css.completeProfileButton} onClick={onClose}>
                    <FormattedMessage id="MissingPhoneNumberModal.missingPhoneNumberCompleteProfile" />
                </Button>
            </footer>
        </div>
    );

    const enterPhoneNumberContent = (
        <div className={css.completeProfileWrapper}>
            <h3 className={classNames(css.completeProfileHeader)}>
                <FormattedMessage id="MissingPhoneNumberModal.completeProfileTitle" />
            </h3>
            <p className={css.completeProfileParagraph}>
                <FormattedMessage
                    id={`MissingPhoneNumberModal.${missingPhoneNumberDescriptionId}`}
                />
            </p>
            <div className={css.modalFormBody}>
                <FinalForm
                    onSubmit={handleSubmitPhoneNumberForm}
                    initialValues={{
                        phoneNumber: initialPhoneNumber,
                        countryCode: countryCodeFromProps,
                    }}
                    render={fieldRenderProps => {
                        const { handleSubmit, invalid, values, form } = fieldRenderProps;

                        const submitDisabled = !!(invalid || submitInProgress);
                        const { countryCode } = values || {};

                        return (
                            <Form onSubmit={handleSubmit}>
                                <div className={css.contactDetailsSection}>
                                    <FieldSelect
                                        id="countryCode"
                                        name="countryCode"
                                        minLength={5}
                                        validate={required(regionRequiredMessage)}
                                        form={form}
                                        placeholder="Auswählen"
                                        optionsList={countryCodes.map(({ label, value }) => ({
                                            label,
                                            value,
                                        }))}
                                    />
                                    <FieldPhoneNumberInput
                                        countryCode={countryCode}
                                        name="phoneNumber"
                                        id="phoneNumber"
                                        placeholder={phonePlaceholder}
                                        validate={composeValidators(
                                            required(regionRequiredMessage),
                                            isNumber(regionRequiredMessage)
                                        )}
                                    />
                                </div>
                                <p className={css.error}>{otpError || phoneSubmitError}</p>

                                <footer className={css.footerOverlay}>
                                    <p className={css.completeProfileProtectionMessage}>
                                        <ProtectionInfoIcon />
                                        <FormattedMessage id="MissingPhoneNumberModal.infoProtectionMessage" />
                                    </p>
                                    <Button
                                        type="submit"
                                        inProgress={submitInProgress}
                                        disabled={submitDisabled}
                                        className={css.modalSubmitButton}
                                    >
                                        <FormattedMessage id="MissingPhoneNumberModal.submitPhoneNumber" />
                                    </Button>
                                </footer>
                            </Form>
                        );
                    }}
                />
            </div>
        </div>
    );

    const confirmPhoneNumberContent = (
        <div className={css.completeProfileWrapper}>
            <h3 className={classNames(css.completeProfileHeader)}>
                <FormattedMessage id="MissingPhoneNumberModal.confirmPhoneNumberitle" />
            </h3>
            <p className={css.completeProfileParagraph}>
                <FormattedMessage
                    id="MissingPhoneNumberModal.confirmPhoneNumberDescription"
                    values={{
                        userPhoneNumber: (
                            <b>
                                {countryCodeFromProps}{' '}
                                {addMaskToPhoneNumber(currentPhoneNumber, countryCodeFromProps)}
                            </b>
                        ),
                    }}
                />
            </p>
            <div className={css.modalFormBody}>
                <FinalForm
                    onSubmit={verifyPhoneNumber}
                    initialValues={{ otp: '' }}
                    render={fieldRenderProps => {
                        const { handleSubmit, invalid, values } = fieldRenderProps;
                        const { otp } = values;
                        const submitDisabled = !!(
                            invalid ||
                            submitInProgress ||
                            otpError ||
                            !otp ||
                            otp.length < 6
                        );

                        return (
                            <Form onSubmit={handleSubmit}>
                                <FieldTextInput
                                    id="otp"
                                    name="otp"
                                    type="text"
                                    placeholder={messagePlaceholder}
                                    maxLength={10}
                                    notifyOnChange={value => handleChange(value)}
                                    validate={composeValidators(
                                        required(regionRequiredMessage),
                                        isNumber(regionRequiredMessage)
                                    )}
                                />

                                <p className={css.error}>{otpError || phoneVerificationError}</p>

                                <p className={css.modalNotification}>
                                    <span style={{ color: '#B2B2B2', cursor: 'auto' }}>
                                        Kein SMS-Code erhalten?{' '}
                                    </span>
                                    <span onClick={() => handleReSendOtp()} className={css.link}>
                                        Nochmals senden{' '}
                                    </span>
                                    <br />
                                    <span style={{ color: '#B2B2B2', cursor: 'auto' }}>
                                        {' '}
                                        Falsche Mobilnummer?
                                    </span>
                                    <span
                                        onClick={() => setVerificationState(ENTER_PHONE_NUMBER)}
                                        className={css.link}
                                    >
                                        {' '}
                                        Ändern
                                    </span>
                                </p>

                                <footer className={css.footerOverlay}>
                                    <p className={css.completeProfileProtectionMessage}>
                                        <ProtectionInfoIcon />
                                        <FormattedMessage id="MissingPhoneNumberModal.infoProtectionMessage" />
                                    </p>
                                    <Button
                                        type="submit"
                                        inProgress={submitInProgress}
                                        disabled={submitDisabled}
                                        className={css.modalSubmitButton}
                                    >
                                        <FormattedMessage id="MissingPhoneNumberModal.submitVerificationCode" />
                                    </Button>
                                </footer>
                            </Form>
                        );
                    }}
                />
            </div>
        </div>
    );

    const phoneNumberConfirmedContent = (
        <div className={css.completeProfileWrapper}>
            <SuccessIconThin rootClassName={css.completeProfileIcon} />
            <h1 className={classNames(css.completeProfileHeader)}>
                <FormattedMessage id="SMSVerificationForm.modalTitleSuccess" />
            </h1>
            <p className={css.completeProfileParagraph}>
                <FormattedMessage id="SMSVerificationForm.modalMessageSuccess" />
            </p>
            <Button
                type="button"
                onClick={onClose}
                className={classNames(css.modalSubmitButton, css.closeButton)}
            >
                <FormattedMessage id="SMSVerificationForm.close" />
            </Button>
        </div>
    );

    const stateConfig = {
        [ACCEPT_VERIFICATION]: {
            content: acceptVerificationContent,
        },
        [ENTER_PHONE_NUMBER]: {
            content: enterPhoneNumberContent,
        },
        [CONFIRM_PHONE_NUMBER]: {
            content: confirmPhoneNumberContent,
        },
        [PHONE_NUMBER_CONFIRMED]: {
            content: phoneNumberConfirmedContent,
        },
    };

    const handleClose =
        redirectOnCLose && (!currentPhoneNumber || !currentPhoneNumberVerified)
            ? handleCompleteProfileDismiss
            : onClose;

    const modalComponent = (
        <Modal
            id={id}
            containerClassName={css.modalContainer}
            contentClassName={css.modalContent}
            containerClassNameJoined
            isOpen={isOpen}
            onClose={handleClose}
            onManageDisableScrolling={() => null}
        >
            {stateConfig[verificationState].content}
        </Modal>
    );

    const modal = () => {
        return ReactDOM.createPortal(modalComponent, portalRoot);
    };
    return portalRoot ? modal() : null;
};

MissingPhoneNumberModalComponent.defaultProps = {
    initialState: null,
    id: 'MissingPhoneNumberModal',
    currentOtp: null,
    missingPhoneNumberDescriptionId: 'missingPhoneNumberDescription',
    redirectOnCLose: true,
};

MissingPhoneNumberModalComponent.propTypes = {
    id: string,
    isOpen: bool,
    initialState: string,
    onSubmitContactDetails: func.isRequired,
    onSendVerificationOtp: func.isRequired,
    onVerifyPhoneNumber: func.isRequired,
    currentOtp: number,
    missingPhoneNumberDescriptionId: string,
    redirectOnCLose: bool,
    currentUser: propTypes.currentUser,
};

const MissingPhoneNumberModal = compose(withRouter, injectIntl)(MissingPhoneNumberModalComponent);

export default MissingPhoneNumberModal;

import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { EditListingPhotosForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import css from './EditListingPhotosPanel.css';
import { DEFAULT_LISTING_TITLE } from '../../marketplace-custom-config';

class EditListingPhotosPanel extends Component {
    render() {
        const {
            className,
            rootClassName,
            errors,
            fetchInProgress,
            newListingPublished,
            images,
            listing,
            submitButtonText,
            panelUpdated,
            updateInProgress,
            onChange,
            onSubmit,
            createListingDraftInProgress,
            externalListing,
            ...rest
        } = this.props;

        const rootClass = rootClassName || css.root;
        const classes = classNames(rootClass, className);
        const currentListing = ensureOwnListing(listing);
        const listingPublicData = currentListing?.attributes?.publicData || {};
        const title = currentListing?.attributes?.title;
        const description = currentListing?.attributes?.description;
        const {
            anonymousListingPackageIntention,
            gender,
            age,
            hight,
            breed,
            color,
        } = listingPublicData;

        const {
            metadata: { anonymousListing: isAnonymousListing },
            publicData: { anonymousListingTitle },
        } = currentListing.attributes;

        const notEmptyListingTitle = isAnonymousListing
            ? anonymousListingTitle
            : title == DEFAULT_LISTING_TITLE
            ? ''
            : title;

        return (
            <div className={classes}>
                <EditListingPhotosForm
                    className={css.form}
                    disabled={fetchInProgress}
                    ready={newListingPublished}
                    fetchErrors={errors}
                    initialValues={{
                        images,
                        otherFields: {
                            title: notEmptyListingTitle,
                            anonymousListingPackageIntention:
                                anonymousListingPackageIntention || isAnonymousListing,
                            gender,
                            age,
                            hight,
                            description,
                            breed,
                            color,
                        },
                    }}
                    images={images}
                    onSubmit={values => {
                        const {
                            addImage,
                            title,
                            anonymousListingPackageIntention,
                            gender,
                            age,
                            hight,
                            description,
                            breed,
                            color,
                            isAutoSave,
                            redirectToListingPage,
                            hasVideo = false,
                            hasGalleryAssets = false,
                            ...updateValues
                        } = values;

                        const allValues = {
                            publicData: {
                                anonymousListingPackageIntention,
                                gender,
                                age,
                                hight,
                                breed,
                                color,
                                hasVideo,
                                hasGalleryAssets,
                            },
                            title: isAnonymousListing
                                ? 'Anonym'
                                : title
                                ? title
                                : DEFAULT_LISTING_TITLE,
                            description,
                            ...updateValues,
                        };

                        if (isAutoSave) {
                            allValues.isAutoSave = isAutoSave;
                        }

                        if (redirectToListingPage) {
                            allValues.redirectToListingPage = redirectToListingPage;
                        }

                        onSubmit(allValues);
                    }}
                    onChange={onChange}
                    saveActionMsg={submitButtonText}
                    updated={panelUpdated}
                    updateInProgress={updateInProgress}
                    createListingDraftInProgress={createListingDraftInProgress}
                    externalListing={externalListing}
                    isAnonymousListing={isAnonymousListing}
                    currentListing={currentListing}
                    {...rest}
                />
            </div>
        );
    }
}

EditListingPhotosPanel.defaultProps = {
    className: null,
    rootClassName: null,
    errors: null,
    images: [],
    listing: null,
    externalListing: false,
};

EditListingPhotosPanel.propTypes = {
    className: string,
    rootClassName: string,
    errors: object,
    images: array,

    // We cannot use propTypes.listing since the listing might be a draft.
    listing: object,
    onSubmit: func.isRequired,
    onChange: func.isRequired,
    submitButtonText: string.isRequired,
    panelUpdated: bool.isRequired,
    updateInProgress: bool.isRequired,
    externalListing: bool,
};

export default EditListingPhotosPanel;

import React, { useState } from 'react';
import { CircleCrossedInsideIcon, RoundInfoIcon } from '../../icons';
import Tooltip from '../Tooltip/Tooltip';
import { FormattedMessage } from '../../util/reactIntl';
import css from './CollectUserInfoWizard.css';
import { useIsMobile } from '../../hooks/useIsMobile';

const TooltipSectionInfo = ({ headingId, descriptionId, containerRef }) => {
    const [isMobile, , computing] = useIsMobile();
    const [tooltipVisible, setTooltipVisibility] = useState(false);

    if (computing) return null;

    return (
        <Tooltip
            tooltipClassName={css.levelTooltip}
            suppressOutsideClick
            isStatic
            staticTooltipState={tooltipVisible}
            parentContainerRef={containerRef}
            padding={isMobile ? 24 : 0}
            content={
                <>
                    <p>
                        <FormattedMessage id={headingId} />
                    </p>
                    <p>
                        <FormattedMessage id={descriptionId} />
                    </p>
                    <CircleCrossedInsideIcon clickHandler={() => setTooltipVisibility(false)} />
                </>
            }
        >
            <RoundInfoIcon clickHandler={() => setTooltipVisibility(prevState => !prevState)} />
        </Tooltip>
    );
};

export default TooltipSectionInfo;

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import { ensureCurrentUser } from '../../util/data';
import {
    Page,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
} from '../../components';
import { TopbarContainer } from '..';
import css from './FindListingsPage.css';
import {
    SectionHero,
    SectionFreeAdvertise,
    SectionHowItWorks,
    SectionAdvantages,
    SectionCommunityFeedbacks,
    SectionFAQ,
    SectionInterestingNearby,
} from '../LandingPage/sections';
import { useIsMobile } from '../../hooks/useIsMobile';

const imagesMapper = {
    'listing-rider': [
        'inserat-erstellen',
        'pferdemenschen-finden',
        'nachrichten-senden-und-erhalten',
        'persoenliches-kennenlernen',
    ],
    horse: ['how-it-works-0', 'how-it-works-1', 'how-it-works-2', 'how-it-works-3'],
};

const constructHIWOptionsByType = (type, intl) =>
    [0, 1, 2, 3].map(index => ({
        url: `url('https://horsedeal.imgix.net/static/landingPage/${imagesMapper[type][index]}.webp')`,
        heading: intl.formatMessage({
            id: `SectionHowItWorks.instructionHeading-${index}-${type}`,
        }),
        instruction: intl.formatMessage({
            id: `SectionHowItWorks.instructionDesc-${index}-${type}`,
        }),
    }));

const {
    siteTitle,
    canonicalRootURL,
    userTypeRider,
    userTypeHorseowner,
    listingTypeHorse,
    listingTypeRider,
} = config;

const listingToUserTypeMapper = {
    [listingTypeHorse]: userTypeRider,
    [listingTypeRider]: userTypeHorseowner,
};

export const FindListingsPage = ({
    type,
    intl,
    location,
    history,
    scrollingDisabled,
    currentUser,
}) => {
    const [isMobile, , computing] = useIsMobile(1024);

    const schemaTitle = intl.formatMessage(
        { id: `FindListingsPage.schemaTitle-find-${type}` },
        { siteTitle }
    );
    const schemaDescription = intl.formatMessage({
        id: `FindListingsPage.schemaDescription-find-${type}`,
    });

    return (
        <Page
            scrollingDisabled={scrollingDisabled}
            contentType="website"
            description={schemaDescription}
            title={schemaTitle}
            facebookImages={[
                {
                    url: 'https://horsedeal.imgix.net/static/social-image-1200x630.webp',
                    width: 1200,
                    height: 630,
                },
            ]}
            twitterImages={[
                {
                    url: 'https://horsedeal.imgix.net/static/social-image-600x314.webp',
                    width: 600,
                    height: 314,
                },
            ]}
            schema={{
                '@context': 'http://schema.org',
                '@type': 'WebPage',
                description: schemaDescription,
                name: schemaTitle,
                image: ['https://horsedeal.imgix.net/static/social-image-1200x630.webp'],
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain
                    className={css.layout}
                    blobBackground
                    blobProps={{
                        xDistance: 20,
                        baseWidth: 760,
                    }}
                >
                    <main className={css.container}>
                        <SectionHero
                            userType={listingToUserTypeMapper[type]}
                            computing={computing}
                            isMobile={isMobile}
                            location={location}
                            history={history}
                            currentUser={currentUser}
                            type={type}
                        />
                        <SectionFreeAdvertise type={type} />
                        {computing ? null : (
                            <SectionHowItWorks
                                options={constructHIWOptionsByType(type, intl)}
                                type={type}
                                isMobile={isMobile}
                            />
                        )}
                        <SectionAdvantages type={type} />
                        <SectionCommunityFeedbacks
                            headingId={`SectionCommunityFeedbacks.heading-for-${type}`}
                            descriptionId={`SectionCommunityFeedbacks.description-for-${type}`}
                            type={type}
                        />
                        <SectionFAQ
                            descriptionId={`SectionFAQ.description-for-${type}`}
                            questionsSet={`find-${type}`}
                        />
                        <SectionInterestingNearby
                            type={type}
                            subHeadingId={`SectionInterestingNearby.subHeading-for-${type}`}
                            headingId={`SectionInterestingNearby.heading-for-${type}`}
                            descriptionId={`SectionInterestingNearby.description-for-${type}`}
                        />
                    </main>
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};

const { bool, object, array } = PropTypes;

FindListingsPage.defaultProps = {
    listings: [],
};

FindListingsPage.propTypes = {
    listings: array,
    scrollingDisabled: bool.isRequired,

    // from withRouter
    history: object.isRequired,
    location: object.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = ({ user: { currentUser }, UI }) => ({
    currentUser: ensureCurrentUser(currentUser),
    scrollingDisabled: isScrollingDisabled({ UI }),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671

export default compose(withRouter, connect(mapStateToProps), injectIntl)(FindListingsPage);

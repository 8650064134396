import { useEffect } from 'react';
import routeConfiguration from '../routeConfiguration';
import { createResourceLocatorString } from '../util/routes';
import { parse } from '../util/urlHelpers';

export const usePaymentStatus = ({
    location,
    history,
    pageName,
    errorSearchParamName = 'paymentStatus',
    errorSearchParamValue = 'failed',
    successSearchParamValue = 'success',
    onPaymentSuccessCallback,
}) => {
    const searchParams = parse(location.search);
    const statusSearchParam = searchParams[errorSearchParamName];

    const paymentError = statusSearchParam === errorSearchParamValue;
    const paymentSuccess = statusSearchParam === successSearchParamValue;

    useEffect(() => {
        if (paymentSuccess) {
            if (typeof onPaymentSuccessCallback === 'function') {
                /**
                 * if it's not needed to fire the callback,
                 * pass smth like "() => null" as a prop
                 */
                onPaymentSuccessCallback();
            } else {
                const { origin, pathname } = window.location;
                window.location.replace(origin + pathname);
            }
        }
    }, [paymentSuccess]);

    const navigate = (params = {}) =>
        history.push(createResourceLocatorString(pageName, routeConfiguration(), {}, params));

    const navigateToErrorPage = errorMessage => {
        const errorPageSearchParams = {
            [errorSearchParamName]: errorSearchParamValue,
        };

        if (errorMessage) {
            Object.assign(errorPageSearchParams, {
                errorMessage,
            });
        }
        return navigate(errorPageSearchParams);
    };

    return {
        paymentError,
        navigateToErrorPage,
        navigateFromErrorPage: () => navigate(),
        navigateToSuccessPage: () =>
            navigate({
                [errorSearchParamName]: successSearchParamValue,
            }),
    };
};

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import { StaticPage, TopbarContainer } from '../../containers';
import {
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    IconSpinner,
    NamedLink,
    NamedRedirect,
} from '../../components';

import { string } from 'prop-types';

import css from './AdminPanelsPage.css';
import { isAdmin } from '../../util/user';

const AdminPanelsPage = ({ title, currentUser, currentUserRequestInProgress }) => {
    const noIndexTag = [
        {
            name: 'robots',
            content: 'noindex',
        },
    ];

    const currentUserDefined = currentUser && currentUser.id && currentUser.id.uuid;
    const currentUserIsAdmin = isAdmin(currentUser);

    if (currentUserDefined && !currentUserIsAdmin) {
        return <NamedRedirect name="LandingPage" />;
    }

    return (
        <StaticPage
            title={title}
            metaTags={noIndexTag}
            schema={{
                '@context': 'http://schema.org',
                '@type': 'AdminPanelsPage',
                description: 'Admin panel',
                name: 'Admin panel',
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>

                <LayoutWrapperMain className={css.staticPageWrapper}>
                    {currentUserRequestInProgress && <IconSpinner />}
                    {!currentUserRequestInProgress && (
                        <>
                            <div className={css.contentWrapper}>
                                <h1 className={css.pageTitle}>Admin Panel</h1>
                                <div>
                                    <NamedLink name="AdminReviewsPage">Reviews</NamedLink>
                                </div>
                                <div>
                                    <NamedLink name="AdminPageSuperDeals">Super Deals</NamedLink>
                                </div>
                                <div>
                                    <NamedLink name="AdminPageClubsOffers">Clubs Offers</NamedLink>
                                </div>
                            </div>
                        </>
                    )}
                </LayoutWrapperMain>

                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </StaticPage>
    );
};

AdminPanelsPage.defaultProps = {
    title: null,
};

AdminPanelsPage.propTypes = {
    title: string,
};

const mapStateToProps = ({ user: { currentUser, currentUserRequestInProgress } }) => ({
    currentUser,
    currentUserRequestInProgress,
});

export default compose(withRouter, connect(mapStateToProps), injectIntl)(AdminPanelsPage);

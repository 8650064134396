import React, { useState } from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_CUSTOMER_COMMISSION, propTypes } from '../../util/types';
import css from './BookingBreakdown.css';
import { Tooltip } from '../../components';
import { TooltipIcon } from './Icons';
import { TOOLTIP_DATA_REQUEST_ATTR } from '../../components/Tooltip/Tooltip';
import { resolveFormattedTotalPrice } from './helpers';

const { Money } = sdkTypes;

// Validate the assumption that the commission exists and the amount
// is zero or positive.
const isValidCommission = commissionLineItem => {
  return (
    commissionLineItem &&
    commissionLineItem.lineTotal instanceof Money &&
    commissionLineItem.lineTotal.amount >= 0
  );
};

const LineItemCustomerCommissionMaybe = props => {
  const { transaction, isCustomer, intl } = props;
  const [tooltipVisible, setTooltipVisibility] = useState(false);
  const faqLink = isCustomer
    ? 'https://support.horsedeal.com/hc/de/articles/17568230507282'
    : 'https://support.horsedeal.com/hc/de/articles/17568256572690';
  const customerCommissionLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_CUSTOMER_COMMISSION && !item.reversal
  );

  // If commission is passed it will be shown as a fee already reduces from the total price
  const isCustomerMaybe = isCustomer && customerCommissionLineItem;

  if (isCustomerMaybe && !isValidCommission(customerCommissionLineItem)) {
    // eslint-disable-next-line no-console
    console.error('invalid commission line item:', customerCommissionLineItem);
    throw new Error('Commission should be present and the value should be zero or positive');
  }

  const commission = customerCommissionLineItem.lineTotal;
  const formattedCommission = resolveFormattedTotalPrice(commission, intl);

  const tooltipContent = (
    <div className={css.tooltipContent}>
      <h5>
        <FormattedMessage id={'BookingBreakdown.tooltipFlexComissionHeading'} />
      </h5>
      <p>
        <FormattedMessage id={'BookingBreakdown.tooltipFlexComissionText'} />
      </p>
      <span
        className={css.tooltipLink}
        onClick={() => window.location.replace(faqLink)}
        data-role={TOOLTIP_DATA_REQUEST_ATTR}
      >
        <FormattedMessage id={'BookingBreakdown.tooltipLinkText'} />
      </span>
    </div>
  );

  return isCustomerMaybe ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.commission" />
      </span>
      <Tooltip
        content={tooltipContent}
        rootClassName={css.tooltipWrapper}
        tooltipClassName={css.tooltip2}
        staticTooltipState={tooltipVisible}
        outsideClickHandler={setTooltipVisibility}
        isStatic
      >
        <div
          className={css.infoItem}
          data-role={TOOLTIP_DATA_REQUEST_ATTR}
          onClick={() => setTooltipVisibility(prevState => !prevState)}
        >
          <TooltipIcon />
        </div>
      </Tooltip>
      <span className={css.dayInfo}>{formattedCommission}</span>
    </div>
  ) : null;
};

LineItemCustomerCommissionMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCustomerCommissionMaybe;

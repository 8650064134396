import React from 'react';
import { func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { Form } from '../../components';
import { InviteReferalsForm } from '../../forms';

import css from './CollectUserInfoWizard.css';
import { Footer } from './Footer';

const CollectRecommendationsForm = ({
    navigateToPreviousForm,
    updateInfoInProgress,
    children,
    intl,
    ...restProps
}) => (
    <FinalForm
        {...restProps}
        render={fieldRenderProps => {
            const { form, handleSubmit, values } = fieldRenderProps;
            const { recipientsList, message } = values;

            const { value: emailValue, valid: emailValid } = form.getFieldState('email') || {};

            const disabled =
                !recipientsList || recipientsList.length === 0
                    ? false
                    : !(message && emailValue ? emailValid : true);

            return (
                <>
                    <Form onSubmit={handleSubmit} className={css.form}>
                        <h2 className={css.collectInfoHeader}>
                            <FormattedMessage id="CollectUserInfoWizard.recommendationsHeader" />
                        </h2>

                        <InviteReferalsForm form={form} recipientsList={recipientsList} />
                        {children}
                    </Form>
                    <Footer
                        disabled={disabled}
                        navigateToPreviousForm={navigateToPreviousForm}
                        updateInfoInProgress={updateInfoInProgress}
                        isLast
                        handleSubmit={() => {
                            restProps.onSubmit(values);
                        }}
                    />
                </>
            );
        }}
    />
);

CollectRecommendationsForm.defaultProps = {
    rootClassName: null,
    className: null,
    fetchErrors: null,
};

CollectRecommendationsForm.propTypes = {
    rootClassName: string,
    className: string,
    onSubmit: func.isRequired,
};

export default compose(injectIntl)(CollectRecommendationsForm);

import React, { useState } from 'react';
import config from '../../config';
import { FormattedMessage } from '../../util/reactIntl';
import { ProtectionInfoIcon } from '../../icons';

import css from './LogoutOverlay.css';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import ChangeUserTypeModal from './ChangeUserTypeModal';

const { userTypeHorseowner } = config;

const LogoutOverlay = ({
  isLoggedOut,
  switchUserRoleAction,
  userName,
  sectionName,
  rootClassName = '',
  onManageDisableScrolling,
  pageParams,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { userType } = pageParams || {};
  const isHorseownerPage = userType === userTypeHorseowner;

  const sectionMessage = (
    <FormattedMessage
      id={`ProfileSettingsForm.viewProfileRequest-${sectionName}`}
      values={{ userName }}
    />
  );

  return isLoggedOut || switchUserRoleAction ? (
    <aside
      className={classNames({
        [css.viewProfileRequestAction]: true,
        [rootClassName]: !!rootClassName,
      })}
    >
      <div>
        <ProtectionInfoIcon />
        {switchUserRoleAction ? (
          <div className={css.link} onClick={() => setModalOpen(true)}>
            {sectionMessage}
          </div>
        ) : (
          <NamedLink name={isHorseownerPage ? 'SignupRider' : 'SignupHorseowner'}>
            {sectionMessage}
          </NamedLink>
        )}
      </div>

      {modalOpen && (
        <ChangeUserTypeModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          onManageDisableScrolling={onManageDisableScrolling}
        />
      )}
    </aside>
  ) : null;
};

export default LogoutOverlay;

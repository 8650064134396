import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
    fetchCurrentUser,
    sendVerificationEmail,
    sendVerificationOtp,
} from '../../ducks/user.duck';
import {
    LayoutSideNavigation,
    LayoutWrapperMain,
    LayoutWrapperAccountSettingsSideNav,
    LayoutWrapperTopbar,
    LayoutWrapperFooter,
    Footer,
    Page,
} from '../../components';
import { ContactDetailsForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
    saveContactDetails,
    saveContactDetailsClear,
    verifyPhoneNumber,
    resetPassword,
} from './ContactDetailsPage.duck';
import css from './ContactDetailsPage.css';
import { format } from '../../components/FieldPhoneNumberInput/fiFormatter';

export const ContactDetailsPageComponent = props => {
    const {
        saveEmailError,
        savePhoneNumberError,
        saveContactDetailsInProgress,
        currentUser,
        contactDetailsChanged,
        onChange,
        scrollingDisabled,
        sendVerificationEmailInProgress,
        sendVerificationEmailError,
        onResendVerificationEmail,
        onResendVerificationOtp,
        onSubmitContactDetails,
        sendVerificationOtpInProgress,
        sendVerificationOtpError,
        intl,
        onVerifyPhoneNumber,
        onResetPassword,
        resetPasswordInProgress,
        resetPasswordError,
    } = props;

    const [currentOtp, setCurrentOtp] = useState(null);

    const user = ensureCurrentUser(currentUser);
    const {
        attributes: {
            profile: { protectedData = {} },
            email: currentEmail = '',
        },
    } = user;
    const currentPhoneNumber = (protectedData.phoneNumber || '').toString();

    const contactInfoForm = user.id ? (
        <ContactDetailsForm
            className={css.form}
            initialValues={{
                email: currentEmail,
                phoneNumber: currentPhoneNumber.substring(3),
            }}
            saveEmailError={saveEmailError}
            savePhoneNumberError={savePhoneNumberError}
            currentUser={currentUser}
            onResendVerificationEmail={onResendVerificationEmail}
            resetPasswordInProgress={resetPasswordInProgress}
            resetPasswordError={resetPasswordError}
            onResetPassword={onResetPassword}
            onResendVerificationOtp={onResendVerificationOtp}
            onSubmit={values => {
                const phoneNumber =
                    values.phoneNumber && values.countryCode
                        ? `${values.countryCode}${format(values.phoneNumber, values.countryCode)}`
                        : null;

                onSubmitContactDetails({
                    ...values,
                    phoneNumber,
                    currentEmail,
                    currentPhoneNumber,
                });
            }}
            onChange={onChange}
            inProgress={saveContactDetailsInProgress}
            ready={contactDetailsChanged}
            sendVerificationEmailInProgress={sendVerificationEmailInProgress}
            sendVerificationEmailError={sendVerificationEmailError}
            sendVerificationOtpError={sendVerificationOtpError}
            sendVerificationOtpInProgress={sendVerificationOtpInProgress}
            savedCountryCode={currentPhoneNumber.substring(0, 3)}
            setCurrentOtp={setCurrentOtp}
            currentOtp={currentOtp}
            onVerifyPhoneNumber={onVerifyPhoneNumber}
        />
    ) : null;

    const title = intl.formatMessage({ id: 'ContactDetailsPage.title' });

    return (
        <Page title={title} scrollingDisabled={scrollingDisabled}>
            <LayoutSideNavigation
                rootClassName={css.sideNavigationLayout}
                joinContainerClasses
                containerClassName={css.containerClassName}
            >
                <LayoutWrapperTopbar>
                    <TopbarContainer
                        currentPage="ContactDetailsPage"
                        desktopClassName={css.desktopTopbar}
                        mobileClassName={css.mobileTopbar}
                    />
                </LayoutWrapperTopbar>
                <LayoutWrapperAccountSettingsSideNav currentTab="ContactDetailsPage" />
                <LayoutWrapperMain blobBackground className={css.layoutWrapperMain}>
                    <div className={css.content}>
                        <h1 className={css.title}>
                            <FormattedMessage id="ContactDetailsPage.heading" />
                        </h1>
                        {contactInfoForm}
                    </div>
                </LayoutWrapperMain>
                <LayoutWrapperFooter className={css.footer}>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSideNavigation>
        </Page>
    );
};

ContactDetailsPageComponent.defaultProps = {
    saveEmailError: null,
    savePhoneNumberError: null,
    currentUser: null,
    sendVerificationEmailError: null,
    resetPasswordInProgress: false,
    resetPasswordError: null,
};

const { bool, func } = PropTypes;

ContactDetailsPageComponent.propTypes = {
    saveEmailError: propTypes.error,
    savePhoneNumberError: propTypes.error,
    saveContactDetailsInProgress: bool.isRequired,
    currentUser: propTypes.currentUser,
    contactDetailsChanged: bool.isRequired,
    onChange: func.isRequired,
    onSubmitContactDetails: func.isRequired,
    scrollingDisabled: bool.isRequired,
    sendVerificationEmailInProgress: bool.isRequired,
    sendVerificationEmailError: propTypes.error,
    onResendVerificationEmail: func.isRequired,
    onResendVerificationOtp: func.isRequired,
    esetPasswordInProgress: bool,
    resetPasswordError: propTypes.error,
    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = state => {
    // Topbar needs user info.
    const {
        currentUser,
        sendVerificationEmailInProgress,
        sendVerificationEmailError,
        sendVerificationOtpInProgress,
        sendVerificationOtpError,
        verificationCode,
    } = state.user;
    const {
        saveEmailError,
        savePhoneNumberError,
        saveContactDetailsInProgress,
        contactDetailsChanged,
        resetPasswordInProgress,
        resetPasswordError,
    } = state.ContactDetailsPage;
    // const { country_code } = state.country_code;
    return {
        saveEmailError,
        savePhoneNumberError,
        saveContactDetailsInProgress,
        currentUser,
        contactDetailsChanged,
        scrollingDisabled: isScrollingDisabled(state),
        sendVerificationEmailInProgress,
        sendVerificationEmailError,
        sendVerificationOtpInProgress,
        sendVerificationOtpError,
        verificationCode,
        resetPasswordInProgress,
        resetPasswordError,
    };
};

const mapDispatchToProps = dispatch => ({
    onChange: () => dispatch(saveContactDetailsClear()),
    onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
    onResendVerificationOtp: phoneNumber => dispatch(sendVerificationOtp(phoneNumber)),
    onManageDisableScrolling: () => null,
    onVerifyPhoneNumber: values => dispatch(verifyPhoneNumber(values)),
    onSubmitContactDetails: values => dispatch(saveContactDetails(values)),
    onResetPassword: values => dispatch(resetPassword(values)),
});

const ContactDetailsPage = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(ContactDetailsPageComponent);

ContactDetailsPage.loadData = () => {
    // Since verify email happens in separate tab, current user's data might be updated
    return fetchCurrentUser();
};

export default ContactDetailsPage;

/**
 * @param {String} str
 * @returns {String} - capitalized version of the string: foo -> Foo
 */
export const capitalize = str => str.charAt(0).toLocaleUpperCase() + str.substring(1);

export const sanitizeSpecChars = str => str.replace(/[^\p{L}]/gu, ' ');

export const CroppText = ({ maxHeight, children, componentRef }) => {
    if (!componentRef || !componentRef.current) return children;

    const trimmedComp = () => {
        const { clientHeight } = componentRef.current;

        if (clientHeight <= maxHeight) return children;

        const { innerHTML } = componentRef.current;

        const plain = innerHTML.slice(0, innerHTML.length - 4).trim();
        const ellipsed = plain + '...';

        componentRef.current.innerHTML = ellipsed;

        return trimmedComp();
    };

    return trimmedComp();
};

import React from 'react';

import { ModalPortal } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './EditUserInfoModal.css';

import EditUserWeightSection from './editSections/EditUserWeightSection';
import EditUserHeightSection from './editSections/EditUserHeightSection';
import EditUserName from './editSections/EditUserNameSection';
import EditUserTrainingsSection from './editSections/EditUserTrainingsSection';
import EditUserAwardsSection from './editSections/EditUserAwardsSection';
import EditUserSkills from './editSections/EditUserSkills';
import EditUserQualification from './editSections/EditUserQualification';
import EditUserDisciplines from './editSections/EditUserDisciplines';
import EditUserDescription from './editSections/EditUserDescription';
import EditUserLanguagesSection from './editSections/EditUserLanguagesSection';
import EditUserInterestSection from './editSections/EditUserInterestSection';
import EditUserAvailabilitySection from './editSections/EditUserAvailabilitySection';
import EditUserAgeSection from './editSections/EditUserAgeSection';
import EditUserExperinceSection from './editSections/EditUserExperinceSection';
import EditUserMobilitySection from './editSections/EditUserMobilitySection';
import classNames from 'classnames';
import {
    USER_HEIGHT,
    USER_WEIGHT,
    USER_NAME,
    USER_AGE,
    USER_MOBILITY,
    USER_EXPERINCE,
    USER_AVAILABILITY,
    USER_INTEREST,
    USER_LANGUAGES,
    USER_BIO,
    USER_DISCIPLINES,
    USER_QUALIFICATION,
    USER_SKILLS,
    USER_AWARDS,
    USER_TRAININGS,
} from './EditUserInfoModalSectionConstants';

export const EDIT_USER_MODAL_ID = 'EditUserProfileInfo';

export const EDIT_SECTIONS = {
    [USER_NAME]: {
        renderComponent: props => <EditUserName {...props} />,
        getInitialValues: ({ currentUser }) => ({
            firstName: currentUser.attributes.profile.firstName,
            lastName: currentUser.attributes.profile.lastName,
        }),
    },
    [USER_HEIGHT]: {
        renderComponent: props => <EditUserHeightSection {...props} />,
        getInitialValues: ({ currentUser }) => ({
            [USER_HEIGHT]: currentUser.attributes.profile.publicData[USER_HEIGHT],
        }),
    },
    [USER_WEIGHT]: {
        renderComponent: props => <EditUserWeightSection {...props} />,
        getInitialValues: ({ currentUser }) => ({
            [USER_WEIGHT]: currentUser.attributes.profile.publicData[USER_WEIGHT],
        }),
    },
    [USER_AGE]: {
        renderComponent: props => <EditUserAgeSection {...props} />,
        getInitialValues: ({ currentUser }) => ({
            birthDate: currentUser.attributes.profile.publicData.birthDate || {},
        }),
    },
    [USER_MOBILITY]: {
        renderComponent: props => <EditUserMobilitySection {...props} />,
        getInitialValues: ({ currentUser }) => ({
            mobility: currentUser.attributes.profile.publicData.mobility,
        }),
    },
    [USER_EXPERINCE]: {
        renderComponent: props => <EditUserExperinceSection {...props} />,
        getInitialValues: ({ currentUser }) => ({
            experience: currentUser.attributes.profile.publicData.experience,
            experienceFocus: currentUser.attributes.profile.publicData.experienceFocus,
            experienceRegularity: currentUser.attributes.profile.publicData.experienceRegularity,
        }),
    },
    [USER_AVAILABILITY]: {
        renderComponent: props => <EditUserAvailabilitySection {...props} />,
        getInitialValues: ({ currentUser }) => ({
            availability: currentUser.attributes.profile.publicData.availability,
            openForPartTimeProposals:
                currentUser.attributes.profile.publicData.openForPartTimeProposals,
        }),
    },
    [USER_INTEREST]: {
        renderComponent: props => <EditUserInterestSection {...props} />,
        getInitialValues: ({ currentUser }) => ({
            interest: currentUser.attributes.profile.publicData.interest,
        }),
    },
    [USER_LANGUAGES]: {
        renderComponent: props => <EditUserLanguagesSection {...props} />,
        getInitialValues: ({ currentUser }) => ({
            languages: currentUser.attributes.profile.publicData.languages,
        }),
    },
    [USER_BIO]: {
        renderComponent: props => <EditUserDescription {...props} />,
        getInitialValues: ({ currentUser }) => ({
            bio: currentUser.attributes.profile.bio,
        }),
    },
    [USER_DISCIPLINES]: {
        renderComponent: props => <EditUserDisciplines {...props} />,
        getInitialValues: ({ currentUser }) => ({
            disciplines: currentUser.attributes.profile.publicData.desiredDisciplines,
            disciplinesLevel: currentUser.attributes.profile.publicData.disciplinesLevel,
        }),
    },
    [USER_QUALIFICATION]: {
        renderComponent: props => <EditUserQualification {...props} />,
        getInitialValues: ({ currentUser, modalParams }) => ({
            qualifications: currentUser.attributes.profile.publicData.riderQualification,
            certifications: currentUser.attributes.profile.publicData.riderCertification,
            qualification: modalParams.qualification,
            certification: modalParams.certification,
        }),
    },
    [USER_SKILLS]: {
        renderComponent: props => <EditUserSkills {...props} />,
        getInitialValues: ({ currentUser }) => ({
            skills: currentUser.attributes.profile.publicData.skills,
        }),
    },
    [USER_AWARDS]: {
        renderComponent: props => <EditUserAwardsSection {...props} />,
        getInitialValues: ({ currentUser, modalParams }) => ({
            awards: currentUser.attributes.profile.publicData.awards,
            awardName: modalParams.awardName,
            organizationName: modalParams.organizationName,
            awardYear: modalParams.awardYear,
        }),
    },
    [USER_TRAININGS]: {
        renderComponent: props => <EditUserTrainingsSection {...props} />,
        getInitialValues: ({ currentUser, modalParams }) => ({
            trainings: currentUser.attributes.profile.publicData.trainings,
            trainingName: modalParams.trainingName,
            organizationName: modalParams.organizationName,
            trainingStart: modalParams.trainingStart,
            trainingEnd: modalParams.trainingEnd,
            description: modalParams.description,
            isCurrentJob: modalParams.isCurrentJob,
        }),
    },
};

const EditUserInfoModal = props => {
    const {
        isOpen,
        onClose,
        editSectionName,
        currentUser,
        onManageDisableScrolling,
        uploadDocumentsInProgress,
        onRemoveSingleDocRequest,
        onUploadSingleDocRequest,
        onDocsListRequest,
        userDocuments,
        onUpdateProfile,
        updateInProgress,
        modalParams,
        setModalParams,
        userDocErrorMetadata,
        onPromptUserBio,
        promptUserBioInProgress,
        promptUserBioError,
        promptUserBio,
        modalId,
    } = props;
    return (
        <ModalPortal
            id={modalId || `${EDIT_USER_MODAL_ID}.${editSectionName}`}
            isOpen={isOpen}
            onClose={() => {
                if (uploadDocumentsInProgress || updateInProgress) {
                    return;
                }
                onClose();
            }}
            onManageDisableScrolling={onManageDisableScrolling}
            containerClassName={classNames({
                [css.modalContainer]: true,
            })}
            contentClassName={classNames({
                [css.modalContent]: true,
            })}
            containerClassNameJoined
            isSticky
        >
            <h3 className={css.editorHeading}>
                <FormattedMessage
                    id={`ProfileSettingsForm.editUserInfoHeading-${editSectionName}`}
                />
            </h3>
            <p className={css.editorDesc}>
                <FormattedMessage id={`ProfileSettingsForm.editUserInfoDesc-${editSectionName}`} />
            </p>
            {EDIT_SECTIONS[editSectionName] &&
                EDIT_SECTIONS[editSectionName].renderComponent({
                    onSubmit: (publicDataValues, __event, profileValues, closeOnSubmit = true) => {
                        onUpdateProfile(profileValues, publicDataValues);
                        closeOnSubmit && onClose();
                    },
                    uploadDocumentsInProgress,
                    userDocuments,
                    initialValues: EDIT_SECTIONS[editSectionName].getInitialValues(props),
                    currentUser,
                    updateInProgress,
                    modalParams,
                    setModalParams,
                    onRemoveSingleDocRequest,
                    onUploadSingleDocRequest,
                    onDocsListRequest,
                    onClose,
                    userDocErrorMetadata,
                    onPromptUserBio,
                    promptUserBioInProgress,
                    promptUserBioError,
                    promptUserBio,
                })}
        </ModalPortal>
    );
};

export default EditUserInfoModal;

import React, { useState } from 'react';
import { Button, ModalPortal } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './TransactionQuestionSet.css';
import { RoundInfoIcon } from '../../icons';

const TransactionQuestionSet = ({ currentUserType }) => {
    const [modalVisible, setModalVisibility] = useState(false);
    const [questionKey, setQuestionKey] = useState(null);

    const handleQuestionClick = key => {
        setModalVisibility(true);
        setQuestionKey(key);
    };

    return (
        <div className={css.container}>
            <div>
                {['first', 'second', 'third'].map(key => (
                    <p
                        key={key}
                        className={css.questionItem}
                        onClick={() => handleQuestionClick(key)}
                    >
                        <RoundInfoIcon />{' '}
                        <FormattedMessage
                            id={`TransactionQuestionSet.question-${currentUserType}-${key}`}
                        />
                    </p>
                ))}
            </div>
            <ModalPortal
                id="TransactionQuestionSetModal"
                isOpen={modalVisible}
                onClose={() => setModalVisibility(false)}
                onManageDisableScrolling={() => null}
                contentClassName={css.modalContent}
                containerClassName={css.modalContainer}
                containerClassNameJoined
            >
                <h4 className={css.modalHeader}>
                    <FormattedMessage
                        id={`TransactionQuestionSet.heading-${currentUserType}-${questionKey}`}
                    />
                </h4>
                <p className={css.modalParagraph}>
                    <FormattedMessage
                        id={`TransactionQuestionSet.info-${currentUserType}-${questionKey}`}
                    />
                </p>
                <Button
                    type="button"
                    className={css.modalActionButton}
                    onClick={() => setModalVisibility(false)}
                >
                    <FormattedMessage id="TransactionQuestionSet.closeModal" />
                </Button>
            </ModalPortal>
        </div>
    );
};

export default TransactionQuestionSet;

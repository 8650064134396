import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Button } from '../../components';
import { ModalPortal } from '../../components';

import css from './ExtendListingRuntimeModal.css';
import { SuccessIconThin } from '../../icons';

const ExtendListingRuntimeModal = ({ onClose, onManageDisableScrolling }) => (
    <ModalPortal
        id="extend-listing-runtime-modal"
        containerClassName={css.modalContainer}
        contentClassName={css.modalContent}
        isOpen
        onClose={onClose}
        onManageDisableScrolling={onManageDisableScrolling}
        containerClassNameJoined
    >
        <SuccessIconThin />
        <h3 className={css.modalTitle}>
            <FormattedMessage id="ManageListingsPage.ExtendListingRuntimeModal.title" />
        </h3>
        <p className={css.modalMessage}>
            <FormattedMessage id="ManageListingsPage.ExtendListingRuntimeModal.description" />
        </p>
        <Button type="button" onClick={onClose}>
            <FormattedMessage id="ManageListingsPage.ExtendListingRuntimeModal.action" />
        </Button>
    </ModalPortal>
);

export default ExtendListingRuntimeModal;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Logo.css';
import LogoIcon from './LogoIcon';

const Logo = props => {
    const { className, format, ...rest } = props;
    const mobileClasses = classNames(css.logoMobile, className);

    return <LogoIcon className={format === 'desktop' ? className : mobileClasses} {...rest} />;
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
    className: null,
    format: 'desktop',
};

Logo.propTypes = {
    className: string,
    format: oneOf(['desktop', 'mobile']),
};

export default Logo;

import React from 'react';
import { func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { Form, Button } from '../../components';
import { InviteReferalsForm } from '../../forms';
import { ProtectionInfoIcon } from '../../icons';

import css from './EditUserInfoPage.css';

const Recommendations = ({
  navigateToPreviousForm,
  updateInfoInProgress,
  children,
  intl,
  ...restProps
}) => (
  <FinalForm
    {...restProps}
    render={fieldRenderProps => {
      const { form, handleSubmit, values } = fieldRenderProps;
      const { recipientsList, message } = values;
      const disabled = !recipientsList || recipientsList.length === 0 || !message;

      return (
        <>
          <Form onSubmit={handleSubmit} className={css.form}>
            <h3 className={css.collectInfoHeader}>
              <FormattedMessage id="EditUserInfoPage.Heading" />
            </h3>
            <InviteReferalsForm form={form} messageRequired recipientsList={recipientsList} />
            <p className={css.msgSection}>
              <ProtectionInfoIcon />
              <FormattedMessage id="CollectUserInfoWizard.infoProtectedRecommendations" />
            </p>
          </Form>
          <Button disabled={disabled} onClick={() => restProps.onSubmit(values)}>
            <FormattedMessage id="EditUserInfoPage.actionBtn" />
          </Button>
        </>
      );
    }}
  />
);

Recommendations.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
};

Recommendations.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,
};

export default compose(injectIntl)(Recommendations);

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { manageDisableScrolling } from '../../ducks/UI.duck';
import { ModalPortal, SecondaryButton } from '..';
import { FormattedMessage } from '../../util/reactIntl';
import { WarningIcon } from '../../icons';

import css from './ConfirmActionModal.css';

const ConfirmActionModal = ({
  isOpen,
  onClose,
  onManageDisableScrolling,
  heading,
  description,
  action,
  actionHandler = () => null,
  id = 'confirm-action-modal',
}) => (
  <ModalPortal
    id={id}
    isOpen={isOpen}
    onClose={onClose}
    onManageDisableScrolling={onManageDisableScrolling}
    containerClassName={css.modalContainer}
    contentClassName={css.modalContent}
    containerClassNameJoined
  >
    <WarningIcon />
    <h3 className={css.modalHeading}>
      <FormattedMessage id={heading} />
    </h3>
    <p className={css.modalDesc}>
      <FormattedMessage id={description} />
    </p>
    <footer className={css.modalAction}>
      <SecondaryButton
        onClick={() => {
          actionHandler();
          onClose();
        }}
      >
        <FormattedMessage id={action} />
      </SecondaryButton>
    </footer>
  </ModalPortal>
);

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default compose(connect(null, mapDispatchToProps))(ConfirmActionModal);

import React, { useState } from 'react';
import { IconSpinner } from '../../components';
import { useRequest } from '../../hooks/useRequest';
import { InterruptProcessModal } from '../../components';
import { handleHttpAction } from '../../util/api';

export default Component => ({ api, ...rest }) => {
    const [view, setView] = useState('list'); // list | edit-form | remove-modal
    const [action, setAction] = useState(null);
    const [initialValues, setInitialValues] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const { error, loading, request } = useRequest();

    const reloadPage = () => window.location.reload();

    const goBack = () => {
        setView('list');
        setAction(null);
        setInitialValues(null);
    };

    const createAction = body => {
        const handler = () =>
            handleHttpAction(`${api}/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body,
            });

        request(handler, reloadPage);
    };

    const editAction = body => {
        const handler = () =>
            handleHttpAction(`${api}/update`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body,
            });
        request(handler, reloadPage);
    };

    const deleteAction = () => {
        const handler = () =>
            handleHttpAction(`${api}/${initialValues._id}/delete`, {
                method: 'DELETE',
            });

        const callback = () => setModalOpen(false);
        request(handler, callback, callback);
    };

    const handleAdminAction = (action, initialValues = null) => {
        setInitialValues(initialValues);

        const isRemoveAction = action === 'remove';
        if (isRemoveAction) return setModalOpen(true);

        setAction(action);
        setView('edit-form');
    };

    const handleSubmit = values => {
        const addNewItem = action === 'add';
        const body = JSON.stringify(values);
        const handler = addNewItem ? createAction : editAction;
        handler(body);
    };

    const showList = view === 'list';
    const showForm = view === 'edit-form';

    if (loading) return <IconSpinner />;

    return (
        <>
            <Component
                error={error}
                showList={showList}
                showForm={showForm}
                action={action}
                modalOpen={modalOpen}
                initialValues={initialValues}
                goBack={goBack}
                handleSubmit={handleSubmit}
                handleAdminAction={handleAdminAction}
                setModalOpen={setModalOpen}
                deleteAction={deleteAction}
                {...rest}
            />
            {modalOpen && (
                <InterruptProcessModal
                    id="remove-superdeal-item"
                    isOpen={modalOpen}
                    onClose={() => setModalOpen(false)}
                    handleInterruption={deleteAction}
                    headingId="Are you sure?"
                    descriptionId="This item will be removed permanently"
                    sidenoteId="admin::action::remove"
                    showAsPlainText
                />
            )}
        </>
    );
};

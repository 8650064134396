import React from 'react';
/** icon-info-l */
/** icon-info */
export default ({ rootClassName = '', clickHandler = () => null }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={rootClassName}
        onClick={clickHandler}
    >
        <path
            d="M11.297.281a11.699 11.699 0 0 0-6.703 2.605A11.669 11.669 0 0 0 .857 8.3c-.825 2.48-.816 5.008.027 7.488A11.479 11.479 0 0 0 3.699 20.3a11.48 11.48 0 0 0 4.513 2.816c2.509.853 5.102.848 7.618-.014A11.475 11.475 0 0 0 20.3 20.3a11.487 11.487 0 0 0 2.802-4.47c.452-1.319.635-2.42.635-3.83 0-1.102-.086-1.82-.339-2.84a11.756 11.756 0 0 0-6.258-7.719 11.78 11.78 0 0 0-5.843-1.16M13.48 1.86a10.292 10.292 0 0 1 8.138 6.597c.191.511.434 1.471.527 2.083.055.365.073.715.073 1.46 0 1.125-.053 1.571-.299 2.543a10.242 10.242 0 0 1-6.159 6.991c-2.429.96-5.197.945-7.62-.04-3.092-1.258-5.422-4.01-6.139-7.252a10.903 10.903 0 0 1-.098-3.982A10.262 10.262 0 0 1 11 1.801c.518-.054 1.948-.02 2.48.059m-2.206 4.32a1.201 1.201 0 0 0-.672.643c-.212.568.012 1.141.549 1.405.852.418 1.816-.375 1.553-1.279a1.133 1.133 0 0 0-1.43-.769m-1.055 3.676c-.553.241-.556 1.097-.004 1.347.128.058.262.075.593.076l.428.001.013 2.03c.012 1.971.015 2.038.101 2.293.224.665.793 1.232 1.447 1.443.2.064.403.089.843.104.694.023.874-.015 1.085-.225.344-.345.285-.878-.127-1.151-.156-.103-.197-.11-.771-.133-.334-.013-.643-.044-.687-.069a.944.944 0 0 1-.337-.385c-.028-.074-.043-.787-.043-2.081 0-2.176-.008-2.265-.249-2.64a1.583 1.583 0 0 0-.732-.583c-.19-.071-.31-.083-.831-.081-.411.001-.647.018-.729.054"
            fill="#1A2B49"
            fillRule="evenodd"
        />
    </svg>
);

import React from 'react';

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon-eye-l">
      <g id="Vector">
        <path
          d="M11.9987 6.00107C8.41551 5.94063 4.70969 8.44466 2.37988 11.0091C2.13533 11.2806 2 11.6331 2 11.9985C2 12.3639 2.13533 12.7163 2.37988 12.9878C4.65902 15.4981 8.35418 18.0599 11.9987 17.9986C15.6432 18.0599 19.3392 15.4981 21.6201 12.9878C21.8647 12.7163 22 12.3639 22 11.9985C22 11.6331 21.8647 11.2806 21.6201 11.0091C19.2876 8.44466 15.5818 5.94063 11.9987 6.00107Z"
          stroke="#8F2593"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.332 12.0003C15.3319 12.6595 15.1362 13.3039 14.7698 13.8519C14.4035 14.4 13.8828 14.8271 13.2737 15.0793C12.6646 15.3314 11.9944 15.3973 11.3478 15.2686C10.7013 15.1399 10.1074 14.8223 9.6413 14.3561C9.17521 13.8899 8.85782 13.2959 8.72928 12.6494C8.60073 12.0028 8.66679 11.3326 8.91911 10.7235C9.17144 10.1145 9.59868 9.59394 10.1468 9.22771C10.695 8.86147 11.3394 8.66599 11.9987 8.66599C12.4365 8.66588 12.8701 8.75205 13.2746 8.91958C13.6792 9.08711 14.0467 9.33272 14.3563 9.64237C14.6659 9.95202 14.9114 10.3196 15.0788 10.7242C15.2462 11.1288 15.3323 11.5624 15.332 12.0003Z"
          stroke="#8F2593"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);

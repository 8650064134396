import React from 'react';
import { Button, ModalPortal } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ChangeUserTypeModal.css';

const ChangeUserTypeModal = ({
    modalOpen,
    setModalOpen,
    onManageDisableScrolling,
    viewProfileRequestModalDescId,
}) => {
    return (
        <ModalPortal
            id="switch-user-role-modal"
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
            onManageDisableScrolling={onManageDisableScrolling}
            containerClassName={css.modalContainer}
            contentClassName={css.modalContent}
            containerClassNameJoined
        >
            <h3>
                <FormattedMessage id="ProfileSettingsForm.viewProfileRequestModal-heading" />
            </h3>
            <p>
                <FormattedMessage
                    id={
                        viewProfileRequestModalDescId ||
                        'ProfileSettingsForm.viewProfileRequestModal-desc'
                    }
                />
            </p>
            <footer>
                <p>
                    <FormattedMessage id="ProfileSettingsForm.viewProfileRequestModal-sidenote" />
                </p>
                <Button type="button" onClick={() => setModalOpen(false)} disabled>
                    <FormattedMessage id="ProfileSettingsForm.viewProfileRequestModal-action" />
                </Button>
            </footer>
        </ModalPortal>
    );
};

export default ChangeUserTypeModal;

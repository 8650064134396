import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { CarouselProvider, Slider, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';

import { ArrowNextIcon } from '../../icons';

import CustomSlideDot from './CustomSlideDot';
import css from './ListingCardsCarousel.css';

const Carousel = ({
    isMobile,
    items,
    touchEnabled = false,
    sliderVisibleSlides: sliderVisibleSlidesProp,
    sliderClassName,
    hideButtonsOnEndStart = false,
    showNextButton = true,
    showPrevButton = true,
    showDots: showDotsProp = true,
    minItemsCount = 3,
    rendererSingleItem,
    rendererCarousel,
    isIntrinsicHeight = true,
    naturalSlideWidth = 100,
    naturalSlideHeight = 100,
    ...rest
}) => {
    const [sliderCurrentIndex, setSliderCurrentIndex] = useState(0);

    const slider = useRef();

    const handlerSliderTransition = () =>
        slider &&
        slider.current &&
        setSliderCurrentIndex(Math.round(slider.current.state.currentSlide || 0));

    const showAsSingleItem =
        items && ((items.length < minItemsCount && !isMobile) || items.length === 1);

    const showAsSlider =
        !showAsSingleItem &&
        items &&
        ((!isMobile && items.length >= minItemsCount) || (isMobile && items.length > 1));

    const { length: itemsNum } = items || [];

    const sliderVisibleSlides =
        sliderVisibleSlidesProp || (isMobile && showAsSingleItem ? 1 : isMobile ? 1.1 : 2.2);

    if (itemsNum === 0) {
        return null;
    }

    const total =
        sliderVisibleSlides < 2 ? itemsNum : Math.ceil(itemsNum - sliderVisibleSlides) + 1;

    const showDots = showDotsProp && (total > 2 || total === Math.ceil(sliderVisibleSlides));

    return (
        <div
            className={classNames(css.sliderOuterContainer, {
                [css.rightOverlayHidden]:
                    Math.ceil(sliderCurrentIndex) + Math.round(sliderVisibleSlides) >= itemsNum,
                [sliderClassName]: !!sliderClassName,
            })}
        >
            {showAsSingleItem && (
                <div className={css.singleItemsWrapper}>{items.map(rendererSingleItem)}</div>
            )}
            {showAsSlider && (
                <div className={css.carouselHolder}>
                    <CarouselProvider
                        naturalSlideWidth={naturalSlideWidth}
                        naturalSlideHeight={naturalSlideHeight}
                        isIntrinsicHeight={isIntrinsicHeight}
                        totalSlides={itemsNum}
                        visibleSlides={sliderVisibleSlides}
                        infinite={false}
                        dragEnabled={touchEnabled}
                        touchEnabled={touchEnabled}
                        {...rest}
                    >
                        <Slider
                            className={css.sliderWrapper}
                            ref={slider}
                            onTransitionEnd={handlerSliderTransition}
                            classNameAnimation={css.sliderAnimation}
                        >
                            {items.map(rendererCarousel)}
                        </Slider>
                        {showPrevButton && (
                            <ButtonBack
                                className={classNames(css.sliderButton, css.sliderButtonBack, {
                                    [css.hideOnStartEnd]: hideButtonsOnEndStart,
                                })}
                            >
                                <ArrowNextIcon />
                            </ButtonBack>
                        )}
                        {showNextButton && (
                            <ButtonNext
                                className={classNames(css.sliderButton, css.sliderButtonNext, {
                                    [css.hideOnStartEnd]: hideButtonsOnEndStart,
                                })}
                            >
                                <ArrowNextIcon />
                            </ButtonNext>
                        )}
                        {showDots && (
                            <DotGroup
                                key={sliderCurrentIndex}
                                renderDots={() => (
                                    <CustomSlideDot
                                        sliderCurrentIndex={Math.ceil(sliderCurrentIndex)}
                                        total={total}
                                    />
                                )}
                            />
                        )}
                    </CarouselProvider>
                </div>
            )}
        </div>
    );
};

export default Carousel;

import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import { parse } from '../../util/urlHelpers';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { FormattedMessage } from '../../util/reactIntl';
import config from '../../config';
import {
    Page,
    Alert,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    NoResultsBlock,
    IconSpinner,
    ManageListingCard,
    NamedRedirect,
} from '../../components';
import { TopbarContainer } from '../../containers';

import {
    closeListing,
    openListing,
    getOwnListingsById,
    queryOwnListings,
} from './ManageListingsPage.duck';

import css from './ManageListingsPage.css';
import classNames from 'classnames';
import ExtendListingRuntimeModal from './ExtendListingRuntimeModal';

const { userTypeRider } = config;

const EXTEND_LISTING_RUNTIME = 'extend-listing-runtime';

const ManageListingsPageComponent = ({
    scrollingDisabled,
    intl,
    queryInProgress,
    queryListingsError,
    openingListingError,
    closingListingError,
    onManageDisableScrolling,
    listings,
    location,
    history,
    ...rest
}) => {
    const [error, setError] = useState(null);
    const [extendListingRuntimeModalOpen, setExtendListingRuntimeModalOpen] = useState(false);
    const [noListingsAlert, setNoListingsAlert] = useState(true);
    const [filterListingsByState, setFilterListingsByState] = useState(null); // draft pendingApproval published closed
    const [activeControlPanelIndex, setActiveControlPanelIndex] = useState(-1);

    useEffect(() => {
        /**
         * If user clicks on the button “Laufzeit verlängern” in
         * email template “Listing Expery Reminder” the user
         * will be redirected to the manage listings page
         * and this dialog will show up. In this case the
         * listing runtime resets to 0 days.
         */
        const searchParams = parse(location.search);

        if (searchParams && searchParams.hasOwnProperty(EXTEND_LISTING_RUNTIME)) {
            setExtendListingRuntimeModalOpen(true);
        }
    }, []);

    useEffect(() => {
        if (queryListingsError) {
            return setError('queryListingsError');
        }
        if (closingListingError || openingListingError) {
            return setError('manageListingStateError');
        }
        setError(null);
    }, [queryListingsError, closingListingError, openingListingError]);

    useEffect(() => {
        if (!filterListingsByState) {
            return setNoListingsAlert(false);
        }

        setNoListingsAlert(
            listings.filter(
                ({ attributes: { state: listingState } }) => listingState === filterListingsByState
            ).length === 0
        );
    }, [filterListingsByState]);

    const noResults =
        !queryInProgress &&
        (!listings || (listings && listings.length === 0)) &&
        !queryListingsError;

    const dataAvailable =
        listings && listings.length > 0 && !queryListingsError && !queryInProgress;

    const currentUser = rest.currentUser;

    if (currentUser && currentUser.attributes.profile.publicData.userType === userTypeRider) {
        return <NamedRedirect name="LandingPage" />;
    }

    return (
        <Page
            title={intl.formatMessage({ id: 'ManageListingsPage.title' })}
            scrollingDisabled={scrollingDisabled}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer currentPage="ManageListingsPage" />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain blobBackground className={css.layoutWrapper}>
                    {queryInProgress && <IconSpinner />}
                    {noResults && (
                        <NoResultsBlock
                            headerId="Du hast noch keine Inserate."
                            parapraphId="Es scheint so, als hättest Du noch kein Pferd bei uns inseriert."
                            buttonId="Pferd inserieren"
                            pageName="NewListingPage"
                            rootClass={css.noResults}
                            buttonLinkMaybe
                        />
                    )}
                    {error && (
                        <Alert
                            type="failure"
                            header="ManageListingsPage.noListingsTitle"
                            description={`ManageListingsPage.noListingsDesc-${error}`}
                            rootClassName={css.alert}
                        />
                    )}
                    {dataAvailable && (
                        <main>
                            <header className={css.manageListingsHeading}>
                                <h3>
                                    <FormattedMessage id="ManageListingsPage.heading" />
                                </h3>
                                <p>
                                    <FormattedMessage id="ManageListingsPage.description" />
                                </p>
                            </header>
                            <nav className={css.statesNavBar}>
                                {['draft', 'pendingApproval', 'published', 'closed'].map(option => (
                                    <p
                                        className={classNames(css.filterBadge, {
                                            [css.filterBadgeSelected]:
                                                filterListingsByState === option,
                                        })}
                                        onClick={() =>
                                            setFilterListingsByState(
                                                filterListingsByState === option ? null : option
                                            )
                                        }
                                    >
                                        <FormattedMessage
                                            id={`ManageListingPage.listingState-${option}`}
                                        />
                                    </p>
                                ))}
                            </nav>
                            <section className={css.container}>
                                {listings.map((listing, index) =>
                                    filterListingsByState &&
                                    filterListingsByState !== listing.attributes.state ? null : (
                                        <ManageListingCard
                                            key={listing.id.uuid}
                                            filterListingsByState={filterListingsByState}
                                            index={index}
                                            listing={listing}
                                            controlPanelIndex={index}
                                            activeControlPanelIndex={activeControlPanelIndex}
                                            setActiveControlPanelIndex={setActiveControlPanelIndex}
                                            onManageDisableScrolling={onManageDisableScrolling}
                                            {...rest}
                                        />
                                    )
                                )}
                                {noListingsAlert && (
                                    <Alert
                                        type="warning"
                                        header="ManageListingsPage.noListingsForAppliedFilterHeading"
                                        description="ManageListingsPage.noListingsForAppliedFilterDesc"
                                        rootClassName={css.alert}
                                    />
                                )}
                            </section>
                        </main>
                    )}
                    {extendListingRuntimeModalOpen && (
                        <ExtendListingRuntimeModal
                            onClose={() => {
                                const queryParams = new URLSearchParams(location.search);
                                queryParams.delete(EXTEND_LISTING_RUNTIME);

                                setExtendListingRuntimeModalOpen(false);

                                history.replace({
                                    search: queryParams.toString(),
                                });
                            }}
                            onManageDisableScrolling={onManageDisableScrolling}
                        />
                    )}
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};

ManageListingsPageComponent.defaultProps = {
    queryListingsError: null,
    currentUser: null,
};

const mapStateToProps = ({
    ManageListingsPage,
    Assets: { assetsLoadingRequests },
    user: { currentUser },
    UI,
}) => ({
    currentUser,
    listings: getOwnListingsById({ ManageListingsPage }, ManageListingsPage.currentPageResultIds),
    scrollingDisabled: isScrollingDisabled({ UI }),
    assetsLoadingRequests,

    ...ManageListingsPage,
});

const mapDispatchToProps = dispatch => ({
    onCloseListing: (listingId, params) => dispatch(closeListing(listingId, params)),
    onOpenListing: listingId => dispatch(openListing(listingId)),
    // onGetEntityAssets: id => dispatch(getEntityAssets({ id, variant: SQUARE_SMALL_2X })),
    onManageDisableScrolling: (componentId, disableScrolling) =>
        dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const ManageListingsPage = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(ManageListingsPageComponent);
ManageListingsPage.loadData = (params, search) => {
    const queryParams = parse(search);
    const page = queryParams.page || 1;
    return queryOwnListings({
        ...queryParams,
        page,
        // perPage: 1, // RESULT_PAGE_SIZE,
        include: ['images', 'author'],
        'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
        'limit.images': 1,
    });
};

export default ManageListingsPage;

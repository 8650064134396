import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StaticPage, TopbarContainer } from '../../containers';
import {
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    IconSpinner,
    NamedRedirect,
} from '../../components';

import { string } from 'prop-types';
import css from './AdminPageSuperDeals.css';
import { isAdmin } from '../../util/user';
import Panel from './Panel';

const api = '/api/super-deals';

const AdminPageSuperDeals = ({ title, currentUser, history, currentUserRequestInProgress }) => {
    const noIndexTag = [
        {
            name: 'robots',
            content: 'noindex',
        },
    ];

    const currentUserDefined = currentUser && currentUser.id && currentUser.id.uuid;
    const currentUserIsAdmin = isAdmin(currentUser);

    if (currentUserDefined && !currentUserIsAdmin) {
        return <NamedRedirect name="LandingPage" />;
    }

    return (
        <StaticPage
            title={title}
            metaTags={noIndexTag}
            schema={{
                '@context': 'http://schema.org',
                '@type': 'AdminPanelsPage',
                description: 'Admin panel',
                name: 'Admin panel',
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>

                <LayoutWrapperMain className={css.staticPageWrapper}>
                    {currentUserRequestInProgress && <IconSpinner />}
                    {!currentUserRequestInProgress && (
                        <>
                            <div className={css.contentWrapper}>
                                <h1 className={css.pageTitle}>Super deals</h1>
                                <Panel currentUser={currentUser} api={api} history={history} />
                            </div>
                        </>
                    )}
                </LayoutWrapperMain>

                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </StaticPage>
    );
};

AdminPageSuperDeals.defaultProps = {
    title: null,
};

AdminPageSuperDeals.propTypes = {
    title: string,
};

const mapStateToProps = ({ user: { currentUser, currentUserRequestInProgress } }) => ({
    currentUser,
    currentUserRequestInProgress,
});

export default compose(withRouter, connect(mapStateToProps))(AdminPageSuperDeals);

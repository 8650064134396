import React, { useReducer, useRef } from 'react';
import { compose } from 'redux';
import { func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';

import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { Form, ProgressBarLevels } from '../../components';

import { Footer } from './Footer';
import css from './CollectUserInfoWizard.css';
import DisciplinesLevelMapper from '../../forms/ProfileSettingsForm/DisciplinesLevelMapper';
import { splitTextToFragments } from '../../containers/OnboardingPage/OnboardingPage';
import TooltipSectionInfo from './TooltipSectionInfo';
import { commonDisciplines } from '../../marketplace-custom-config';
import classNames from 'classnames';

const MODAL_BREAKPOINT = 768;

const isWindowDefined = typeof window !== 'undefined';
const isMobile = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;

const reducer = (state, action) => {
    const { type, name, level } = action;
    if (type === 'change_level') {
        return {
            ...state,
            [name]: level,
        };
    }

    throw Error('Unknown action: ' + action.type);
};

const CollectUserLevelForm = ({
    navigateToPreviousForm,
    updateInfoInProgress,
    currentUser,
    intl,
    ...restProps
}) => {
    const levelDescriptionContainerRef = useRef();
    const disciplineLevelContainerRef = useRef();

    const [levelsState, dispatch] = useReducer(
        reducer,
        (restProps.initialValues.disciplines || []).reduce(
            (acc, discipline) => ({ ...acc, [discipline]: 0 }),
            {}
        )
    );

    return (
        <FinalForm
            {...restProps}
            render={fieldRenderProps => {
                const { handleSubmit, form, values } = fieldRenderProps;
                const { disciplinesLevel, disciplines } = values;

                const noLevelProvided =
                    !disciplinesLevel || disciplines.some(d => !disciplinesLevel[d]);
                const disabled = noLevelProvided;

                const addDisciplineLevel = (level, discipline) =>
                    form.change('disciplinesLevel', {
                        ...(disciplinesLevel || {}),
                        [discipline]: level,
                    });

                return (
                    <>
                        <Form onSubmit={handleSubmit} className={css.form}>
                            <h2 className={css.collectInfoHeader} ref={disciplineLevelContainerRef}>
                                <FormattedMessage id="CollectUserInfoWizard.levelHeader" />
                            </h2>
                            <div
                                ref={levelDescriptionContainerRef}
                                className={classNames(
                                    css.collectInfoDescription,
                                    css.levelDescription
                                )}
                            >
                                {isMobile ? (
                                    splitTextToFragments(
                                        intl.formatMessage({
                                            id: 'CollectUserInfoWizard.levelDescriptionMob',
                                        })
                                    )
                                ) : (
                                    <FormattedMessage id="CollectUserInfoWizard.levelDescription" />
                                )}
                                <TooltipSectionInfo
                                    headingId="CollectUserInfoWizard.levelTooltipHeading"
                                    descriptionId="CollectUserInfoWizard.levelTooltipDescription"
                                    containerRef={levelDescriptionContainerRef}
                                />
                            </div>
                            <main className={css.disciplineLevelHoler}>
                                {disciplines.map(discipline => {
                                    const disciplineLevel =
                                        disciplinesLevel && disciplinesLevel[discipline];

                                    const scrollProps = {
                                        onMouseOver: ({ target }) => {
                                            const { tagName } = target;
                                            const isProgressBarItem = tagName === 'CODE';

                                            if (!isProgressBarItem) {
                                                return;
                                            }
                                            const {
                                                body: { clientHeight },
                                            } = document;
                                            const { innerHeight, scrollY } = window;

                                            if (innerHeight + scrollY === clientHeight) {
                                                window.scrollBy({
                                                    behavior: 'smooth',
                                                    top: -150,
                                                });
                                            }
                                        },
                                    };

                                    const preSelectHandlersProps =
                                        /**
                                         * no need to add onMouseOver & onMouseLeave listeners
                                         * for touch devices
                                         */
                                        navigator && navigator.maxTouchPoints > 0
                                            ? {}
                                            : {
                                                  notifyOnPreSelect: level =>
                                                      setTimeout(() => {
                                                          dispatch({
                                                              type: 'change_level',
                                                              name: discipline,
                                                              level,
                                                          });
                                                      }, 100),
                                                  notifyOnPreSelectOver: () =>
                                                      setTimeout(() => {
                                                          dispatch({
                                                              type: 'change_level',
                                                              name: discipline,
                                                              level: 0,
                                                          });
                                                      }, 100),
                                              };

                                    const { label: disciplineLabel } = commonDisciplines.find(
                                        ({ id }) => id === discipline
                                    ) || { label: discipline };

                                    return (
                                        <section
                                            key={discipline}
                                            className={css.disciplineLevel}
                                            {...scrollProps}
                                        >
                                            <p>{disciplineLabel}</p>

                                            <ProgressBarLevels
                                                editAvailable
                                                rootClassName={css.progressBar}
                                                level={disciplineLevel || 0}
                                                levelItemClassName={css.levelItem}
                                                onLevelSelect={level =>
                                                    addDisciplineLevel(level, discipline)
                                                }
                                                {...preSelectHandlersProps}
                                            />

                                            <DisciplinesLevelMapper
                                                level={levelsState[discipline] || disciplineLevel}
                                                placeholderClassName={css.levelNoItem}
                                                user={currentUser}
                                                discipline={discipline}
                                                containerRef={disciplineLevelContainerRef}
                                            />
                                        </section>
                                    );
                                })}
                            </main>
                        </Form>
                        <Footer
                            disabled={disabled}
                            navigateToPreviousForm={navigateToPreviousForm}
                            updateInfoInProgress={updateInfoInProgress}
                            handleSubmit={handleSubmit}
                        />
                    </>
                );
            }}
        />
    );
};

CollectUserLevelForm.defaultProps = {
    rootClassName: null,
    className: null,
    fetchErrors: null,
};

CollectUserLevelForm.propTypes = {
    rootClassName: string,
    className: string,
    onSubmit: func.isRequired,
};

export default compose(injectIntl)(CollectUserLevelForm);

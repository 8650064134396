import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';

import { withRouter } from 'react-router-dom';

import { stringify } from '../../../../util/urlHelpers';
import { FormattedMessage, injectIntl } from '../../../../util/reactIntl';
import { SocialLoginButtons, NamedLink, IconSpinner } from '../../../../components';
import TopbarDesktopSearchPanel from '../../../../components/TopbarDesktop/TopbarDesktopSearchPanel';
import config from '../../../../config';
import { redirectToURLWithModalState } from '../../../../components/Topbar/Topbar.helpers';
import { TopbarLocationSearchForm } from '../../../../forms';
import { getUserCountryName, getUserGeocodingData } from '../../../../util/location';
import { excludeTypes } from '../../../../components/LocationAutocompleteInput/LocationAutocompleteInputImpl.helper';
import {
    encodeBoundsToStr,
    encodeOriginToStr,
} from '../../../../components/TopbarDesktop/TopbarDesktopSearchPanel.helpers';

import SectionHeroHeader from './SectionHeroHeader';
import css from './SectionHero.css';

const { listingTypeHorse, userTypeRider, userTypeHorseowner, listingTypeRider } = config;

const userToListingTypeMapper = {
    [userTypeRider]: listingTypeHorse,
    [userTypeHorseowner]: listingTypeRider,
};

const SectionHero = ({
    intl,
    userType,
    type,
    computing,
    isMobile,
    location,
    history,
    currentUser,
    hideSearhBarForNoAuth = false,
    showAuthSection = false,
}) => {
    const [initialValues, setInitialValues] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const initializeRedirectParams = () => {
        if (!initialValues || !initialValues.location || !initialValues.location.selectedPlace)
            return {};

        return {
            search: stringify({
                address: initialValues.location.search,
                bounds: encodeBoundsToStr(initialValues.location.selectedPlace.bounds),
                origin: encodeOriginToStr(initialValues.location.selectedPlace.origin),
                distance: initialValues.optionDistance,
                listingType,
            }),
        };
    };

    const currentUserDefined = !!userType && currentUser && currentUser.id;
    const isHorseOwner = userType === userTypeHorseowner;

    const horseownerActionLink = !isHorseOwner ? null : (
        <p className={css.horseownerActionLink}>
            <FormattedMessage
                id="SectionHero.createYourListingForFree"
                values={{
                    createListingLink: (
                        <NamedLink name="NewListingPage">
                            <FormattedMessage id="SectionHero.createListingLink" />
                        </NamedLink>
                    ),
                }}
            />
        </p>
    );

    const inProgress = computing || loading;
    const showSearchBar = !inProgress && (hideSearhBarForNoAuth ? currentUserDefined : true);

    const listingType = type || userToListingTypeMapper[userType] || 'horse';

    const { city, postalCode, mobility } = currentUser.attributes.profile.publicData;
    const { radius } = mobility || { radius: '15 km' };
    const { address } = currentUserDefined
        ? {
              address: `${postalCode} ${city}`,
          }
        : {};

    useEffect(() => {
        if (!address) return;

        setLoading(true);

        const searchAddress = `${city}, ${getUserCountryName()}`;

        getUserGeocodingData(searchAddress, excludeTypes)
            .then(response => {
                setInitialValues({
                    optionDistance: radius,
                    location: {
                        search: address,
                        selectedPlace: { ...response },
                    },
                });
            })
            .catch(() => {
                // do nothing
            })
            .finally(() => {
                setLoading(false);
            });
    }, [address]);

    return (
        <section className={css.root}>
            <div className={css.container}>
                <SectionHeroHeader userType={userType} />

                {!currentUserDefined && showAuthSection && (
                    <>
                        <div className={css.socialLoginButtons}>
                            <SocialLoginButtons
                                showGoogleLogin
                                showFacebookLogin
                                buttonsHolderClassName={css.idpButtons}
                            />
                        </div>
                        <div>
                            <p className={css.pseudoLink}>
                                <NamedLink name="SignupPage">
                                    <FormattedMessage id="SectionRegisterViaEmail.registerLink" />
                                </NamedLink>
                            </p>
                            <div className={css.followersHolder}>
                                <div className={css.followers}>
                                    {Array.from({ length: 5 }, (_, index) => (
                                        <img
                                            key={index}
                                            src={`https://horsedeal.imgix.net/static/landingPage/social-proof-${index +
                                                1}.webp`}
                                            alt="our-customers"
                                            style={{
                                                left: `${15 * index * -1}px`,
                                            }}
                                        />
                                    ))}
                                </div>
                                <p>
                                    <FormattedMessage id="SectionRegisterViaEmail.registerDescription" />
                                </p>
                            </div>
                        </div>
                    </>
                )}
                {inProgress && <IconSpinner />}
                {isMobile && showSearchBar && (
                    <>
                        <TopbarLocationSearchForm
                            rootClassName={css.mobLocationForm}
                            listingTypeTab={isHorseOwner ? listingTypeRider : listingTypeHorse}
                            initialValues={
                                initialValues && {
                                    distance: initialValues.optionDistance,
                                    address: initialValues.location.search,
                                }
                            }
                            onTabPanelToggle={() =>
                                redirectToURLWithModalState(
                                    {
                                        location: {
                                            ...location,
                                            ...initializeRedirectParams(),
                                        },
                                        history,
                                    },
                                    'mobilesearch'
                                )
                            }
                        />

                        {horseownerActionLink}
                    </>
                )}
                {!isMobile && showSearchBar && (
                    <div>
                        <TopbarDesktopSearchPanel
                            intl={intl}
                            listingTypeTab={isHorseOwner ? listingTypeRider : listingTypeHorse}
                            rootClassName={css.sectionHeroSearchPanel}
                            initialValues={initialValues}
                        />
                        {horseownerActionLink}
                    </div>
                )}
            </div>
        </section>
    );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
    rootClassName: string,
    className: string,
};

export default compose(withRouter, injectIntl)(SectionHero);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Prompt } from 'react-router';
import { withRouter } from 'react-router-dom';
import { matchPathname } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

import { func } from 'prop-types';
import InterruptProcessModal from '../InterruptProcessModal/InterruptProcessModal';
import { checkMarketplaceCurrentUser } from '../../util/data';
import { parse } from '../../util/urlHelpers';

const UnsavedChangesWrapper = ({ children, currentUser, history, match }) => {
    const [leavePageModalVisible, setLeavePageModalVisibility] = useState(false);
    const [routePathname, setRoutePathname] = useState(null);

    const currentUserDefined = currentUser && currentUser.id;

    const handleNavigateToLocation = () =>
        history.push({ pathname: routePathname || '/', isNavigationAllowed: true });

    useEffect(() => {
        function showAlert(e) {
            const showAlertOnPageLeave = match.params.type === 'draft';

            if (!showAlertOnPageLeave) {
                return;
            }

            e.preventDefault();
            e.returnValue = '';
            return '';
        }

        window.addEventListener('beforeunload', showAlert);
        window.addEventListener('unload', showAlert);

        return () => {
            window.removeEventListener('beforeunload', showAlert);
            window.removeEventListener('unload', showAlert);
        };
    }, []);

    return (
        <>
            <InterruptProcessModal
                id="EditListingUnsavedChangesWrapper.modal"
                isOpen={leavePageModalVisible}
                onClose={() => setLeavePageModalVisibility(false)}
                handleInterruption={handleNavigateToLocation}
                descriptionId="UnsavedChangesWrapper.notificationOnPageLeave"
                sidenoteId="UnsavedChangesWrapper.additionalInfo"
            />

            {currentUserDefined && (
                <Prompt
                    message={location => {
                        const { isNavigationAllowed, pathname, search } = location;
                        if (isNavigationAllowed) return true;

                        const { skipUnsavedChangesAlert } = parse(search);

                        if (skipUnsavedChangesAlert) return true;

                        const {
                            route: { name },
                        } = matchPathname(pathname, routeConfiguration())[0];

                        const {
                            params: { type },
                        } = matchPathname(history.location.pathname, routeConfiguration())[0];

                        const isEditListingPage =
                            name === 'EditListingPage' || name === 'EditListingPageExternal';
                        const pageLeavingOnChangesUnsaved =
                            !isEditListingPage && (type === 'draft' || type === 'new');

                        if (pageLeavingOnChangesUnsaved) {
                            setRoutePathname(pathname);
                            setLeavePageModalVisibility(true);
                            return false;
                        }
                    }}
                />
            )}
            {children}
        </>
    );
};

UnsavedChangesWrapper.propTypes = {
    onManageDisableScrolling: func.isRequired,
};

const mapStateToProps = state => ({
    currentUser: checkMarketplaceCurrentUser(state),
});

export default compose(withRouter, connect(mapStateToProps))(UnsavedChangesWrapper);

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { checkMarketplaceCurrentUser, ensureUser, ensureCurrentUser } from '../../util/data';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
    Page,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
} from '../../components';
import { submitRecommendations } from '../../containers/OnboardingPage/OnboardingPage.duck';
import {
    loadData,
    sendUserExternalReview,
    getUserExternalReview,
    querExternalReviewsError,
} from '../../containers/ProfilePage/ProfilePage.duck';
import { types as sdkTypes } from '../../util/sdkLoader';

import { ArrowNextIcon } from '../../icons';
import { TopbarContainer } from '../../containers';
import css from './EditUserInfoPage.css';
import Recommendations from './Recommendations';
import Gallery from './Gallery';
import { ExternalReviewForm } from '../../forms';
import {
    changeEntityAssetMetadata,
    getEntityAssets,
    removeEntityAssets,
    uploadEntityAssets,
} from '../../ducks/Assets.duck';

export const ADD_RECOMMENDATIONS_FORM = 'add-recommendations';
export const GET_RECOMMENDATIONS_FORM = 'empfehlungen';
export const GALLERY_FORM = 'gallery';

const { UUID } = sdkTypes;

const defaultRecommendationMessage = `Hallo

Ich hoffe, dir geht es gut.

Ich bin seit kurzem Mitglied bei HorseDeal, der Sharing-Plattform für Pferde. Um die Sichtbarkeit meines Profils zu steigern, würde ich mich freuen, wenn Du dir eine Minute Zeit nehmen würdest, um mir eine Empfehlung zu schreiben.

Vielen Dank im Voraus für deine Zeit!`;

const EditUserInfoPage = ({
    onUpdateProfile,
    onSubmitRecommendations,
    onSendUserExternalReview,
    onGetUserExternalReview,
    onQuerExternalReviewsError,
    onChangeEntityAssetMetadata,
    onUploadAssetsRequest,
    onRemoveAssetsRequest,
    onManageDisableScrolling,
    onAssetsRequest,
    uploadImageError,
    uploadInProgress,
    currentUser,
    publicUser,
    userShowLoading,
    intl,
    history,
    location,
    externalReviewsErrors,
    externalReviewsRequests,
    externalReviewsData,
    // userDocErrorMetadata,
    assetsLoadingRequests,
    assetsErrors,
    assetsData,
    match: {
        params: { section, userNameToRecommend, userId },
    },
}) => {
    const schemaTitle = intl.formatMessage({ id: 'EditUserInfoPage.schemaTitle' });
    const {
        id,
        attributes: { profile },
    } = ensureCurrentUser(currentUser);
    const { firstName } = profile || {};
    const { uuid } = id || { uuid: null };

    const uploadAssetsInProgress = assetsLoadingRequests && uuid && assetsLoadingRequests[uuid];

    const editSectionConfig = {
        [GET_RECOMMENDATIONS_FORM]: (
            <Recommendations
                initialValues={{
                    message: defaultRecommendationMessage,
                }}
                onSubmit={({ recipientsList, message }) => {
                    if (recipientsList && recipientsList.length && message) {
                        onSubmitRecommendations({
                            recipientsList,
                            message,
                            recipientFirstName: firstName,
                        });
                        history.goBack();
                    }
                }}
            />
        ),
        [GALLERY_FORM]: (
            <Gallery
                currentUser={currentUser}
                uploadImageError={uploadImageError}
                uploadInProgress={uploadInProgress}
                onSubmit={values => {
                    onUpdateProfile({}, values);
                }}
                location={location}
                onUploadAssetsRequest={onUploadAssetsRequest}
                onAssetsRequest={onAssetsRequest}
                assetsErrors={assetsErrors}
                // userDocErrorMetadata={userDocErrorMetadata}
                uploadAssetsInProgress={uploadAssetsInProgress}
                onChangeEntityAssetMetadata={onChangeEntityAssetMetadata}
                onRemoveAssetsRequest={onRemoveAssetsRequest}
                assetsData={assetsData}
                onManageDisableScrolling={onManageDisableScrolling}
            />
        ),
        [ADD_RECOMMENDATIONS_FORM]: (
            <ExternalReviewForm
                userId={userId}
                currentUser={currentUser}
                publicUser={publicUser}
                externalReviewsErrors={externalReviewsErrors}
                externalReviewsRequests={externalReviewsRequests}
                userShowLoading={userShowLoading}
                externalReviewsData={externalReviewsData}
                onSendUserExternalReview={onSendUserExternalReview}
                onGetUserExternalReview={onGetUserExternalReview}
                onQuerExternalReviewsError={onQuerExternalReviewsError}
                onSubmit={onSendUserExternalReview}
                history={history}
            />
        ),
    };

    return (
        <Page
            title={schemaTitle}
            scrollingDisabled={false}
            schema={{
                '@context': 'http://schema.org',
                '@type': 'WebPage',
                name: schemaTitle,
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain className={css.layoutWrapperMain} blobBackground>
                    <div className={css.root}>
                        {!(assetsLoadingRequests && assetsLoadingRequests[uuid]) && (
                            <div className={css.backButton} onClick={() => history.goBack()}>
                                <ArrowNextIcon />
                                <FormattedMessage id="EditUserInfoPage.prevStep" />
                            </div>
                        )}
                        {userNameToRecommend
                            ? editSectionConfig[ADD_RECOMMENDATIONS_FORM]
                            : editSectionConfig[section] || null}
                    </div>
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};

const mapStateToProps = state => {
    const currentUser = checkMarketplaceCurrentUser(state);
    const { ProfileSettingsPage, ProfilePage, OnboardingPage } = state;
    const { uploadImageError, uploadInProgress } = ProfileSettingsPage;

    const {
        userId,
        userShowLoading,
        externalReviewsErrors,
        externalReviewsRequests,
        externalReviewsData,
    } = ProfilePage;

    // const { uploadDocumentsInProgress, userDocErrorMetadata } = OnboardingPage;
    const { assetsLoadingRequests, assetsData, assetsErrors } = state.Assets;

    const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);

    const publicUser = ensureUser(userMatches.length === 1 ? userMatches[0] : null);

    return {
        currentUser,
        publicUser,
        userShowLoading,
        uploadImageError,
        uploadInProgress,
        externalReviewsErrors,
        externalReviewsRequests,
        externalReviewsData,
        // userDocErrorMetadata,
        // uploadDocumentsInProgress,
        assetsErrors,
        assetsLoadingRequests,
        assetsData,
    };
};

const mapDispatchToProps = dispatch => ({
    onManageDisableScrolling: (componentId, disableScrolling) =>
        dispatch(manageDisableScrolling(componentId, disableScrolling)),
    onSubmitRecommendations: data => dispatch(submitRecommendations(data)),
    onUpdateProfile: (data, publicData) => dispatch(updateProfile(data, publicData)),
    onSendUserExternalReview: data => dispatch(sendUserExternalReview(data)),
    onGetUserExternalReview: userReviewedId => dispatch(getUserExternalReview(userReviewedId)),
    onQuerExternalReviewsError: error => dispatch(querExternalReviewsError(error)),
    onUploadAssetsRequest: data => dispatch(uploadEntityAssets({ ...data, type: 'asset' })),
    onAssetsRequest: id => dispatch(getEntityAssets({ id })),
    onRemoveAssetsRequest: data => dispatch(removeEntityAssets(data)),
    onChangeEntityAssetMetadata: data => dispatch(changeEntityAssetMetadata(data)),
});

EditUserInfoPage.loadData = params => {
    const id = new UUID(params.userId);
    return loadData(id);
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(EditUserInfoPage);

import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import * as validators from '../../util/validators';
import { ModalPortal, Button, Form, FieldTextInput } from '../../components';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';

import css from './Modals.css';

const EditDescriptionModal = ({
    isOpen,
    image,
    onClose,
    onManageDisableScrolling,
    onChangeImageDescription,
    intl,
}) => (
    <ModalPortal
        id="edit-image-description"
        isOpen={isOpen}
        onClose={onClose}
        onManageDisableScrolling={onManageDisableScrolling}
        containerClassName={css.modalContainer}
        contentClassName={css.modalContent}
        containerClassNameJoined
    >
        <h3 className={css.modalHeading}>
            <FormattedMessage id="AddImages.editImageDescHeading" />
        </h3>
        <p className={css.modalDesc}>
            <FormattedMessage id="AddImages.editImageDescDescription" />
        </p>

        <FinalForm
            initialValues={{
                imageDescription: image && image.description ? image.description : '',
            }}
            onSubmit={() => null}
            render={fieldRenderProps => {
                const { values, initialValues, pristine, handleSubmit } = fieldRenderProps;
                const { imageDescription: initialImageDescription } = initialValues;
                const { imageDescription } = values || {};

                const disabled = !imageDescription || pristine;

                return (
                    <>
                        <Form onSubmit={handleSubmit} className={css.form}>
                            <FieldTextInput
                                type="textarea"
                                id="imageDescription"
                                name="imageDescription"
                                maxLength={200}
                                placeholder={intl.formatMessage({
                                    id: 'AddImages.removeImageLabel',
                                })}
                                validate={validators.required(
                                    intl.formatMessage({
                                        id: 'ContactDetailsForm.fieldRequired',
                                    })
                                )}
                            />
                        </Form>
                        <Button
                            disabled={disabled}
                            className={css.actionButton}
                            onClick={() => {
                                onChangeImageDescription(imageDescription, image);
                                onClose();
                            }}
                        >
                            <FormattedMessage
                                id={`AddImages.imageDescAction-${
                                    initialImageDescription ? 'edit' : 'add'
                                }`}
                            />
                        </Button>
                    </>
                );
            }}
        />
    </ModalPortal>
);

export default compose(injectIntl)(EditDescriptionModal);

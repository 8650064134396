import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import { FormattedMessage, injectIntl } from '../../../util/reactIntl';
import { Button } from '../../../components';
import { EditUserAvailabilityForm } from '../../../forms';

import TooltipSectionInfo from './TooltipSectionInfo';
import {
    RIDER_AVAILABILITY_CONFIRMED,
    RIDER_AVAILABILITY_NOT_AVAILABLE,
} from '../../../marketplace-custom-config';

const EditUserAvailabilitySection = ({ intl, ...restProps }) => {
    const { modalParams } = restProps;
    const { isConfirm } = modalParams || {};
    return (
        <FinalForm
            {...restProps}
            render={fieldRenderProps => {
                const { values, valid, updateInProgress, pristine, form } = fieldRenderProps;
                const { availability, openForPartTimeProposals } = values || {};

                const disabled = !availability || !valid || !openForPartTimeProposals;

                return (
                    <>
                        <EditUserAvailabilityForm
                            form={form}
                            selectedValue={availability}
                            openForPartTimeProposals={openForPartTimeProposals}
                            options={[
                                { label: 'flexible', key: 'flexible' },
                                { label: '1-2', key: 'numPerWeek' },
                                { label: '2-3', key: 'numPerWeek' },
                                { label: '3-5', key: 'numPerWeek' },
                                {
                                    label: RIDER_AVAILABILITY_NOT_AVAILABLE,
                                    key: RIDER_AVAILABILITY_NOT_AVAILABLE,
                                },
                            ]}
                        />
                        <footer>
                            <TooltipSectionInfo
                                headingId="EditUserAvailabilityForm.sideNoteTooltipHeading"
                                descriptionId="EditUserAvailabilityForm.sideNoteTooltipDesc"
                                sidenoteId="EditUserForms.updateAvailabilitySideNote"
                            />
                            <Button
                                disabled={disabled}
                                onClick={() => {
                                    restProps.onSubmit({
                                        ...values,
                                        availabilityStatus:
                                            availability === RIDER_AVAILABILITY_NOT_AVAILABLE
                                                ? availability
                                                : RIDER_AVAILABILITY_CONFIRMED,
                                        availabilityUpdatedAt: new Date().toString(),
                                    });
                                }}
                                inProgress={updateInProgress}
                            >
                                <FormattedMessage
                                    id={`ProfileSettingsForm.editUserInfoAction-${
                                        isConfirm && pristine
                                            ? 'confirmUserAvailability'
                                            : 'userAvailability'
                                    }`}
                                />
                            </Button>
                        </footer>
                    </>
                );
            }}
        />
    );
};

EditUserAvailabilitySection.defaultProps = {};

EditUserAvailabilitySection.propTypes = {};

export default compose(injectIntl)(EditUserAvailabilitySection);

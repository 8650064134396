import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import {
    LISTING_PAGE_PARAM_TYPE_DRAFT,
    LISTING_PAGE_PARAM_TYPE_NEW,
    LISTING_PAGE_PARAM_TYPE_EDIT,
    LISTING_PAGE_PARAM_TYPE_EDIT_AND_REDIRECT,
    HORSE,
    DESCRIPTION_HORSE,
    DESCRIPTION_RIDER,
    LOCATION,
    PRICING,
    PHOTOS,
    BOOSTING_CH,
    BOOSTING_DE,
    CHECKOUT,
} from '../../util/urlHelpers';
import { ensureListing } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import css from './EditListingWizard.css';
import {
    EditListingDescriptionPanel,
    EditListingLocationPanel,
    EditListingPhotosPanel,
    EditListingPricingPanel,
} from '../../components';

import { getTheHigherProgress, stepsPerTab } from './Helpers';
import { getListingNavParams } from '../../util/listings';

// EditListingWizardTab component supports these tabs
export const SUPPORTED_TABS = [
    DESCRIPTION_HORSE,
    DESCRIPTION_RIDER,
    LOCATION,
    PHOTOS,
    PRICING,
    BOOSTING_CH,
    BOOSTING_DE,
    CHECKOUT,
];

export const EXTERNAL_SOURCE_LINK = {
    key: 'externalSourceLink',
    label: 'Link to External Source',
    tab: HORSE,
    placeholder: 'https://...',
};
export const EXTERNAL_SOURCE_NAME = {
    key: 'externalSourceName',
    label: 'External Source Name',
    tab: HORSE,
};

export const EXTERNAL_LISTING_REQUIRED_FIELDS = [EXTERNAL_SOURCE_LINK, EXTERNAL_SOURCE_NAME];

// Schweiz
export const DEFAULT_LOCATION = {
    selectedPlace: {
        address: '6072 Schweiz',
        origin: {
            lat: 47.808453,
            lng: 10.492064,
        },
    },
};

export const EXTERNAL_LISTING_DEFAULT_FIELDS = {
    geolocation: { ...DEFAULT_LOCATION.selectedPlace.origin },
    publicData: { address: DEFAULT_LOCATION.selectedPlace.address },
};

export const pathParamsToNextTab = (params, tab, marketplaceTabs, forceTabNumber) => {
    const forceTabNumberMaybe = typeof forceTabNumber === 'number' ? forceTabNumber : 1;
    const nextTabIndex = marketplaceTabs.findIndex(s => s === tab) + forceTabNumberMaybe;
    const nextTab =
        nextTabIndex < marketplaceTabs.length
            ? marketplaceTabs[nextTabIndex]
            : marketplaceTabs[marketplaceTabs.length - 1];
    return { ...params, tab: nextTab };
};

// When user has update draft listing, he should be redirected to next EditListingWizardTab
const redirectAfterDraftUpdate = (
    listingId,
    params,
    tab,
    marketplaceTabs,
    history,
    abortRedirect,
    routeName
) => {
    const currentPathParams = {
        ...params,
        type: LISTING_PAGE_PARAM_TYPE_DRAFT,
        id: listingId,
    };
    const routes = routeConfiguration();

    // Replace current "new" path to "draft" path.
    // Browser's back button should lead to editing current draft instead of creating a new one.
    if (params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
        const draftURI = createResourceLocatorString(routeName, routes, currentPathParams, {});
        history.replace(draftURI);

        if (abortRedirect) {
            return;
        }
    }

    // Redirect to next tab
    const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs);
    const to = createResourceLocatorString(routeName, routes, nextPathParams, {});
    history.push(to);
};

const navigateToTab = (editListingRouteName, nextPathParams, history) => {
    const routes = routeConfiguration();
    const to = createResourceLocatorString(editListingRouteName, routes, nextPathParams, {});
    history.push(to);
};

const navigateToListingPage = (history, listing) => {
    const { params, name } = getListingNavParams(listing);

    history.push(createResourceLocatorString(name, routeConfiguration(), params, {}));
};

const EditListingWizardTab = props => {
    const {
        tab,
        marketplaceTabs,
        params,
        errors,
        fetchInProgress,
        newListingPublished,
        history,
        images,
        listing,
        handleCreateFlowTabScrolling,
        handlePublishListing,
        onUpdateListing,
        onCreateListingDraft,
        onChange,
        updatedTab,
        updateInProgress,
        intl,
        createListingDraftInProgress,
        externalListing,
        editListingRouteName,
        ensuredCurrentUser,
        onManageDisableScrolling,
        handleShowTabs,
        tabParams,
        handleStepChange,
        currentStep,
        isOfflineFlow,
        listingAssets,
        uploadListingAssetsInProgress,
        onAssetsRequest,
        onUploadAssetsRequest,
        onRemoveAssetsRequest,
        listingAssetsUploadError,
        onChangeEntityAssetMetadata,
        setCurrentFormInstance,
        onCompleteEditListingWizardTab,
    } = props;

    const { type } = params;
    const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
    const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
    const isNewListingFlow = isNewURI || isDraftURI;
    const isEditAndRedirect = type === LISTING_PAGE_PARAM_TYPE_EDIT_AND_REDIRECT;
    const isEdit = type === LISTING_PAGE_PARAM_TYPE_EDIT;

    const currentListing = ensureListing(listing);

    const resolveTabNum = tab => {
        /**
         * tab type and it's corresponding num
         */
        const config = {
            [DESCRIPTION_RIDER]: 1,
            [DESCRIPTION_HORSE]: 1,
            [LOCATION]: 2,
            [PHOTOS]: 3,
            [PRICING]: 4,
            [BOOSTING_CH]: 4,
            [BOOSTING_DE]: 4,
            [CHECKOUT]: 4,
        };

        return config[tab];
    };

    const imageIds = images => {
        return images ? images.map(img => img.imageId || img.id) : null;
    };

    const handleExtListingRequiedFieldsMissing = (id, tab) => {
        const currentPathParams = {
            ...params,
            type: LISTING_PAGE_PARAM_TYPE_DRAFT,
            id,
        };
        const nextTabStep = 0;
        const nextPathParams = pathParamsToNextTab(
            currentPathParams,
            tab,
            marketplaceTabs,
            nextTabStep
        );
        return navigateToTab(editListingRouteName, nextPathParams, history);
    };

    const externalListingDefaultValues = defaultFieldsMissing =>
        defaultFieldsMissing.reduce((data, missingKey) => {
            const missingValue = EXTERNAL_LISTING_DEFAULT_FIELDS[missingKey];

            data[missingKey] =
                typeof missingValue === 'object' ? { ...missingValue } : missingValue;

            return data;
        }, {});

    const handleExtenalListingRedirect = (currentListing, response) => {
        const { attributes } = currentListing;
        const { publicData } = attributes;

        const requiedFieldMissing = EXTERNAL_LISTING_REQUIRED_FIELDS.filter(
            s => !publicData[s.key]
        )[0];

        if (requiedFieldMissing) {
            return handleExtListingRequiedFieldsMissing(
                response.data.data.id.uuid,
                requiedFieldMissing.tab
            );
        }

        const defaultFieldsMissing = Object.keys(EXTERNAL_LISTING_DEFAULT_FIELDS).filter(
            defaultKey => !attributes[defaultKey]
        );
        const defaultFieldsMissingMaybe = defaultFieldsMissing && defaultFieldsMissing.length !== 0;

        if (defaultFieldsMissingMaybe) {
            const data = externalListingDefaultValues(defaultFieldsMissing);
            onUpdateListing(null, { ...data, id: response.data.data.id });
        }
    };

    const addExternalListingFlagToPB = upsertValues => {
        if (!upsertValues.publicData) {
            upsertValues.publicData = {};
        }
        upsertValues.publicData = {
            ...upsertValues.publicData,
            externalListing: true,
        };
    };

    const handleAutoSaveAndProgress = (updateValues, finalProgress) => {
        if (updateValues.isAutoSave) {
            delete updateValues.isAutoSave;
        } else {
            if (updateValues.publicData) {
                updateValues.publicData.progress = finalProgress;
            } else {
                updateValues.publicData = { progress: finalProgress };
            }
        }

        if (updateValues.redirectToListingPage) {
            delete updateValues.redirectToListingPage;
        }
    };

    const handleCompleteEditListingWizardTab = (
        tab,
        updateValues,
        forceRedirect = false,
        tabNum = 1
    ) => {
        onCompleteEditListingWizardTab(currentStep, tabNum);

        if (isOfflineFlow) {
            return;
        }
        const abortRedirect = true;
        const { attributes } = currentListing;
        const { publicData } = attributes;

        const maxSteps = stepsPerTab[tabNum];
        const newProgress =
            currentStep == maxSteps ? `${tabNum + 1}-0` : `${tabNum}-${currentStep}`;
        const oldProgress = publicData?.progress || '1-1';
        const finalProgress = getTheHigherProgress(oldProgress, newProgress);
        const goToOverviewPage = updateValues.redirectToListingPage;

        handleAutoSaveAndProgress(updateValues, finalProgress);

        delete updateValues.abortRedirect;

        // Normalize images for API call
        const { images: updatedImages, postponePublishing, ...otherValues } = updateValues;
        const imageProperty =
            typeof updatedImages !== 'undefined' ? { images: imageIds(updatedImages) } : {};

        const updateValuesWithImages = { ...otherValues, ...imageProperty };

        if (isNewListingFlow) {
            const onUpsertListingDraft = isNewURI
                ? (tab, updateValues) => onCreateListingDraft(updateValues)
                : onUpdateListing;

            const upsertValues = isNewURI
                ? updateValuesWithImages
                : { ...updateValuesWithImages, id: currentListing.id };

            if (externalListing && isNewURI) {
                addExternalListingFlagToPB(upsertValues);
            }

            onUpsertListingDraft(tab, upsertValues)
                .then(r => {
                    if (tab !== marketplaceTabs[marketplaceTabs.length - 1]) {
                        // Create listing flow: smooth scrolling polyfill to scroll to correct tab
                        handleCreateFlowTabScrolling(false);

                        // After successful saving of draft data, user should be redirected to next tab
                        // abortRedirect to prevent redirecting on initial image upload
                        if (!abortRedirect || params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
                            redirectAfterDraftUpdate(
                                r.data.data.id.uuid,
                                params,
                                tab,
                                marketplaceTabs,
                                history,
                                abortRedirect,
                                editListingRouteName
                            );
                        }
                    } else {
                        if (externalListing) {
                            handleExtenalListingRedirect(currentListing, r);
                        }
                        /**
                         * postpone publishing until a user selects/skips an extra packages
                         */
                        !postponePublishing && handlePublishListing(currentListing.id);
                    }
                })
                .catch(e => {
                    // No need for extra actions
                });
        } else {
            onUpdateListing(tab, { ...updateValuesWithImages, id: currentListing.id }).then(
                response => {
                    const isLastTab = tabNum == 4;

                    const currentPathParams = {
                        ...params,
                        type: LISTING_PAGE_PARAM_TYPE_EDIT,
                        id: response.data.data.id.uuid,
                    };

                    const nextPathParams = pathParamsToNextTab(
                        currentPathParams,
                        tab,
                        marketplaceTabs
                    );
                    const editAndRedirectCompleted =
                        isEditAndRedirect && currentStep === tabParams.allStepsCount;
                    const goToListingPageAfterPrevStep = goToOverviewPage && isEditAndRedirect;
                    const goToListingPageAfterEditingIsComplete =
                        editAndRedirectCompleted || isLastTab;

                    if (goToListingPageAfterPrevStep || goToListingPageAfterEditingIsComplete) {
                        /**
                         * action is previous and an owner comes from listing page
                         * or
                         * section is fully completed and an owner comes from listing page
                         *
                         * Redirect to the listing's page
                         */
                        navigateToListingPage(history, currentListing);
                    } else if (goToOverviewPage) {
                        handleShowTabs(1, true);
                    } else if (isEditAndRedirect && !editAndRedirectCompleted) {
                        /**
                         * section is NOT fully completed and an owner comes from listing page
                         *
                         * Redirect to next tab
                         */
                        handleStepChange({
                            action: 'next',
                            tabNum: resolveTabNum(tab),
                        });
                    } else if (forceRedirect) {
                        navigateToTab(editListingRouteName, nextPathParams, history);
                    }
                }
            );
        }
    };

    const panelProps = tab => ({
        className: css.panel,
        errors,
        listing,
        onChange,
        panelUpdated: updatedTab === tab,
        updateInProgress,
        externalListing,
        onManageDisableScrolling,
        handleStepChange,
        currentStep,
        isNewListingFlow,
        isEditAndRedirect,
        isEdit,
        setCurrentFormInstance,
    });

    switch (tab) {
        case DESCRIPTION_RIDER: {
            const submitButtonTranslationKey = isNewListingFlow
                ? 'EditListingWizard.saveEditandNew'
                : 'EditListingWizard.saveEditandNew';

            const redirectAfterTabUpdate = false;

            return (
                <EditListingDescriptionPanel
                    {...panelProps(DESCRIPTION_RIDER)}
                    ensuredCurrentUser={ensuredCurrentUser}
                    isOfflineFlow={isOfflineFlow}
                    submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
                    currentTab={tab}
                    tabParams={tabParams}
                    onSubmit={values => {
                        const shouldRedirectToNextStep =
                            !values.isAutoSave &&
                            !values.redirectToListingPage &&
                            !isEditAndRedirect;

                        const isFlexible = values.publicData.availability === 'flexible';
                        const hasPackages = !!values.publicData.package1;

                        if (hasPackages) {
                            const { package2, package3 } = values.publicData;
                            /**
                             * if a user changes availability from flexible to some other value,
                             * deactivate the packages;
                             *
                             * package1 is considered to be always active
                             */
                            values.publicData.package2 = package2
                                ? { ...package2, active: isFlexible }
                                : null;
                            values.publicData.package3 = package3
                                ? { ...package3, active: isFlexible }
                                : null;
                        }

                        handleCompleteEditListingWizardTab(tab, values, redirectAfterTabUpdate, 1);

                        if (shouldRedirectToNextStep) {
                            handleStepChange({
                                action: 'next',
                                tabNum: resolveTabNum(tab),
                            });
                        }
                    }}
                />
            );
        }
        case DESCRIPTION_HORSE: {
            const submitButtonTranslationKey = isNewListingFlow
                ? 'EditListingWizard.saveEditandNew'
                : 'EditListingWizard.saveEditandNew';
            return (
                <EditListingDescriptionPanel
                    {...panelProps(DESCRIPTION_HORSE)}
                    submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
                    currentTab={tab}
                    tabParams={tabParams}
                    onSubmit={values => {
                        handleCompleteEditListingWizardTab(tab, values, false, 1);
                        handleStepChange({
                            action: 'next',
                            tabNum: resolveTabNum(tab),
                        });
                    }}
                />
            );
        }
        case LOCATION: {
            const submitButtonTranslationKey = isNewListingFlow
                ? 'EditListingWizard.saveEditandNew'
                : 'EditListingWizard.saveEditandNew';
            return (
                <EditListingLocationPanel
                    {...panelProps(LOCATION)}
                    submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
                    tabParams={tabParams}
                    onSubmit={values => {
                        const shouldRedirectToNextStep =
                            !values.isAutoSave &&
                            !values.redirectToListingPage &&
                            !isEditAndRedirect;
                        handleCompleteEditListingWizardTab(tab, values, false, 2);
                        if (shouldRedirectToNextStep) {
                            handleStepChange({
                                action: 'next',
                                tabNum: resolveTabNum(tab),
                            });
                        }
                    }}
                />
            );
        }
        case PHOTOS: {
            const submitButtonTranslationKey = isNewListingFlow
                ? 'EditListingWizard.saveEditandNew'
                : 'EditListingWizard.saveEditandNew';

            return (
                <EditListingPhotosPanel
                    {...panelProps(PHOTOS)}
                    submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
                    images={images}
                    tabParams={tabParams}
                    onAssetsRequest={onAssetsRequest}
                    onUploadAssetsRequest={onUploadAssetsRequest}
                    onRemoveAssetsRequest={onRemoveAssetsRequest}
                    listingAssets={listingAssets}
                    uploadListingAssetsInProgress={uploadListingAssetsInProgress}
                    listingAssetsUploadError={listingAssetsUploadError}
                    onChangeEntityAssetMetadata={onChangeEntityAssetMetadata}
                    onSubmit={values => {
                        const shouldRedirectToNextStep =
                            !values.isAutoSave &&
                            !values.redirectToListingPage &&
                            !isEditAndRedirect;
                        handleCompleteEditListingWizardTab(tab, values, false, 3);
                        if (shouldRedirectToNextStep) {
                            handleStepChange({
                                action: 'next',
                                tabNum: resolveTabNum(tab),
                            });
                        }
                    }}
                    createListingDraftInProgress={createListingDraftInProgress}
                />
            );
        }
        case BOOSTING_CH:
        case BOOSTING_DE:
        case CHECKOUT:
        case PRICING: {
            // newListingPublished and fetchInProgress are flags for the last wizard tab
            return (
                <EditListingPricingPanel
                    {...panelProps(PRICING)}
                    newListingPublished={newListingPublished}
                    fetchInProgress={fetchInProgress}
                    tabParams={tabParams}
                    ensuredCurrentUser={ensuredCurrentUser}
                    onSubmit={values => {
                        const shouldRedirectToNextStep =
                            // !values.isAutoSave &&
                            !values.redirectToListingPage && !isEditAndRedirect && isNewListingFlow;

                        handleCompleteEditListingWizardTab(tab, values, false, 4);

                        if (shouldRedirectToNextStep) {
                            handleStepChange({
                                action: 'next',
                                tabNum: resolveTabNum(tab),
                            });
                        }
                    }}
                />
            );
        }

        default:
            return null;
    }
};

EditListingWizardTab.defaultProps = {
    listing: null,
    updatedTab: null,
    externalListing: false,
    userIsAdmin: false,
};

const { array, bool, func, object, oneOf, shape, string } = PropTypes;

EditListingWizardTab.propTypes = {
    // params: shape({
    //     id: string.isRequired,
    //     slug: string.isRequired,
    //     type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    //     tab: oneOf(SUPPORTED_TABS).isRequired,
    // }).isRequired,
    errors: shape({
        createListingDraftError: object,
        publishListingError: object,
        updateListingError: object,
        showListingsError: object,
    }).isRequired,
    fetchInProgress: bool.isRequired,
    newListingPublished: bool.isRequired,
    history: shape({
        push: func.isRequired,
        replace: func.isRequired,
    }).isRequired,
    images: array.isRequired,
    // We cannot use propTypes.listing since the listing might be a draft.
    listing: shape({
        attributes: shape({
            publicData: object,
            description: string,
            geolocation: object,
            pricing: object,
            title: string,
        }),
        images: array,
    }),

    handleCreateFlowTabScrolling: func.isRequired,
    handlePublishListing: func.isRequired,
    onUpdateListing: func.isRequired,
    onCreateListingDraft: func.isRequired,
    onChange: func.isRequired,
    updatedTab: string,
    updateInProgress: bool.isRequired,
    externalListing: bool,
    editListingRouteName: string.isRequired,
    intl: intlShape.isRequired,
};

export default EditListingWizardTab;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { FacebookLogo, GoogleLogo, EditPencilIcon } from '../../icons';
import { requestSaveEmail } from '../ContactDetailsPage/ContactDetailsPage.duck';
import {
    LayoutSideNavigation,
    LayoutWrapperMain,
    LayoutWrapperAccountSettingsSideNav,
    LayoutWrapperTopbar,
    LayoutWrapperFooter,
    Footer,
    Page,
    ModalPortal,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { changePassword, changePasswordClear, resetPassword } from './PasswordChangePage.duck';
import css from './PasswordChangePage.css';
import PasswordChangeForm from './PasswordChangeForm';
import EmailChangeForm from './EmailChangeForm';
import { ensureCurrentUser } from '../../util/data';

const PASSWORD_FORM = 'passwordForm';
const EMAIL_FORM = 'emailForm';

export const PasswordChangePageComponent = props => {
    const [modalVisible, setModalVisibility] = useState(false);
    const [modalForm, setModalForm] = useState(null);
    const [userUpdated, setUserUptated] = useState(null);
    const [cookiesWidgetError, setCookiesWidgetError] = useState(null);
    const {
        changePasswordError,
        changePasswordInProgress,
        currentUser: currentUserFromProps,
        onChange,
        onSubmitChangePassword,
        onResetPassword,
        resetPasswordInProgress,
        resetPasswordError,
        passwordChanged,
        scrollingDisabled,
        intl,
        onManageDisableScrolling,
        onRequestSaveEmail,
        saveEmailError,
        cookiesPage,
    } = props;

    useEffect(() => {
        if (!cookiesPage) {
            return setCookiesWidgetError(false);
        }
        const UC_UI_defined = typeof window !== 'undefined' && window.UC_UI;

        if (!UC_UI_defined) {
            return setCookiesWidgetError(true);
        }

        window.UC_UI.showSecondLayer();
    }, [cookiesPage]);

    const currentUser = ensureCurrentUser(userUpdated || currentUserFromProps);

    const changePasswordForm =
        currentUser && currentUser.id ? (
            <PasswordChangeForm
                className={css.form}
                changePasswordError={changePasswordError}
                currentUser={currentUser}
                onSubmit={onSubmitChangePassword}
                setModalVisibility={setModalVisibility}
                setModalForm={setModalForm}
                onChange={onChange}
                onResetPassword={onResetPassword}
                resetPasswordInProgress={resetPasswordInProgress}
                resetPasswordError={resetPasswordError}
                inProgress={changePasswordInProgress}
                ready={passwordChanged}
            />
        ) : null;

    const modalContentConfig = {
        [PASSWORD_FORM]: changePasswordForm,
        [EMAIL_FORM]: (
            <EmailChangeForm
                onSubmit={values =>
                    onRequestSaveEmail(values).then(userUpdated => {
                        setUserUptated(userUpdated);
                    })
                }
                saveEmailError={saveEmailError}
                setModalVisibility={setModalVisibility}
                setModalForm={setModalForm}
            />
        ),
    };

    const modalContent = modalContentConfig[modalForm];

    const title = intl.formatMessage({ id: 'PasswordChangePage.title' });

    const {
        attributes: { identityProviders, email },
    } = currentUser;

    const { idpId } = (identityProviders || [{}])[0] || {};
    const isGoogleIdp = idpId === 'google';
    const isFacebookIdp = idpId === 'facebook';

    return (
        <Page title={title} scrollingDisabled={scrollingDisabled}>
            <LayoutSideNavigation>
                <LayoutWrapperTopbar>
                    <TopbarContainer
                        currentPage="PasswordChangePage"
                        desktopClassName={css.desktopTopbar}
                        mobileClassName={css.mobileTopbar}
                    />
                </LayoutWrapperTopbar>
                <LayoutWrapperAccountSettingsSideNav
                    currentTab={cookiesPage ? 'CookiesPage' : 'PasswordChangePage'}
                />
                <LayoutWrapperMain blobBackground className={css.layoutWrapperMain}>
                    {cookiesPage ? (
                        <>
                            {cookiesWidgetError &&
                                'Sorry, privacy settings are unavailable. Try later'}
                            {/* <div className="uc-embed" uc-data="all"></div> */}
                            {/* <a href="#uc-corner-modal-show" />
              <div className="uc-embed" uc-layout="1" uc-data="all"></div> */}
                        </>
                    ) : (
                        <div className={css.content}>
                            <h1 className={css.title}>
                                <FormattedMessage id="PasswordChangePage.heading" />
                            </h1>
                            <aside className={css.idpInfo}>
                                {isGoogleIdp && <GoogleLogo />}
                                {isFacebookIdp && <FacebookLogo />}
                                <FormattedMessage
                                    id={`PasswordChangeForm.idpInfo-${idpId || 'absent'}`}
                                />
                            </aside>
                            <div className={css.editSection}>
                                <p className={css.editTitle}>
                                    <FormattedMessage
                                        id={`PasswordChangePage.editEmailHeading-${idpId ||
                                            'absent'}`}
                                    />
                                </p>
                                <div className={css.editInfo}>
                                    <p>{email}</p>
                                    <p
                                        className={css.editAction}
                                        onClick={() => {
                                            setModalForm(EMAIL_FORM);
                                            setModalVisibility(true);
                                        }}
                                    >
                                        <EditPencilIcon />
                                        <FormattedMessage id="PasswordChangePage.editAction" />
                                    </p>
                                </div>
                            </div>
                            {!idpId && (
                                <div className={css.editSection}>
                                    <p className={css.editTitle}>
                                        <FormattedMessage id="PasswordChangePage.editPasswordHeading" />
                                    </p>
                                    <div className={css.editInfo}>
                                        <p>
                                            ••••••••••••••••••••••••
                                            {/* <input type="email" id="email" name="email" autoComplete="email" />
                      <input
                        type="password"
                        id="password"
                        name="password"
                        autoComplete="current-password"
                      /> */}
                                        </p>
                                        <p
                                            className={css.editAction}
                                            onClick={() => {
                                                setModalVisibility(true);
                                                setModalForm(PASSWORD_FORM);
                                            }}
                                        >
                                            <EditPencilIcon />
                                            <FormattedMessage id="PasswordChangePage.editAction" />
                                        </p>
                                    </div>
                                </div>
                            )}
                            <p className={css.contactSupport}>
                                Bitte{' '}
                                <a
                                    href="https://www.horsedeal.com/faq"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    kontaktiere unseren Support
                                </a>
                                , um das Land zu ändern.
                            </p>
                        </div>
                    )}
                </LayoutWrapperMain>
                <LayoutWrapperFooter className={css.footer}>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSideNavigation>

            <ModalPortal
                id="EditUserPassword"
                isOpen={modalVisible}
                onClose={() => setModalVisibility(false)}
                onManageDisableScrolling={onManageDisableScrolling}
                containerClassName={css.modalContainer}
                contentClassName={css.modalContent}
                containerClassNameJoined
            >
                {modalContent}
            </ModalPortal>
        </Page>
    );
};

PasswordChangePageComponent.defaultProps = {
    changePasswordError: null,
    currentUser: null,
    resetPasswordInProgress: false,
    resetPasswordError: null,
};

const { bool, func } = PropTypes;

PasswordChangePageComponent.propTypes = {
    changePasswordError: propTypes.error,
    changePasswordInProgress: bool.isRequired,
    currentUser: propTypes.currentUser,
    onChange: func.isRequired,
    onSubmitChangePassword: func.isRequired,
    passwordChanged: bool.isRequired,
    scrollingDisabled: bool.isRequired,
    resetPasswordInProgress: bool,
    resetPasswordError: propTypes.error,
    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = state => {
    // Topbar needs user info.
    const {
        changePasswordError,
        changePasswordInProgress,
        passwordChanged,
        resetPasswordInProgress,
        resetPasswordError,
    } = state.PasswordChangePage;

    const { saveEmailError } = state.ContactDetailsPage;

    const { currentUser } = state.user;
    return {
        changePasswordError,
        changePasswordInProgress,
        currentUser,
        passwordChanged,
        scrollingDisabled: isScrollingDisabled(state),
        resetPasswordInProgress,
        resetPasswordError,
        saveEmailError,
    };
};

const mapDispatchToProps = dispatch => ({
    onRequestSaveEmail: data => dispatch(requestSaveEmail(data)),
    onChange: () => dispatch(changePasswordClear()),
    onSubmitChangePassword: values => dispatch(changePassword(values)),
    onResetPassword: values => dispatch(resetPassword(values)),
    onManageDisableScrolling: (componentId, disableScrolling) =>
        dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const PasswordChangePage = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(PasswordChangePageComponent);
export default PasswordChangePage;

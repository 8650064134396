import React, { useState } from 'react';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';

import { FormattedMessage, injectIntl } from '../../../util/reactIntl';
import { Button, ConfirmActionModal, FieldSelect } from '../../../components';
import { required, composeValidators } from '../../../util/validators';
import { CircleCrossedInsideIcon } from '../../../icons';
import css from '../EditUserInfoModal.css';

export const LANGUAGES = ['Deutsch', 'Englisch', 'Französisch', 'Italienisch'];

export const LANGUAGES_LEVELS = {
    Grundkenntnisse: 1,
    Konversationssicher: 2,
    Muttersprache: 3,
};

export const sortByLevel = ([, levelPrimary], [, levelSecondary]) => {
    if (LANGUAGES_LEVELS[levelSecondary] === LANGUAGES_LEVELS[levelPrimary]) {
        return LANGUAGES.indexOf(levelSecondary) - LANGUAGES.indexOf(levelPrimary);
    }
    return LANGUAGES_LEVELS[levelSecondary] - LANGUAGES_LEVELS[levelPrimary];
};

const EditUserLanguagesSection = ({ intl, ...restProps }) => {
    const [open, setOpen] = useState(false);
    const [itemToRemove, setItemToRemove] = useState(null);

    return (
        <FinalForm
            {...restProps}
            render={fieldRenderProps => {
                const { values, form, updateInProgress, initialValues } = fieldRenderProps;
                const { languages = {}, newLanguage, level } = values || {};
                const { languages: initialLanguages } = initialValues;
                const languagesEntries = Object.entries(languages);

                const pristine =
                    initialLanguages &&
                    Object.keys(languages).join(', ') === Object.keys(initialLanguages).join(', ');

                const disabled =
                    !languages || !languagesEntries.length || (newLanguage && !level) || pristine;

                const requiredMessageUhrzeit = intl.formatMessage({
                    id: 'ProfileSettingsForm.fieldRequired',
                });

                const removeLanguage = language => {
                    const languagesUpd = { ...languages };
                    delete languagesUpd[language];

                    form.change('languages', languagesUpd);
                    setItemToRemove(null);
                    restProps.onSubmit({ languages: languagesUpd });
                };

                const addLanguage = () => {
                    if (!newLanguage || !level) {
                        return;
                    }
                    const languagesUpd = { ...languages, [newLanguage]: level };
                    form.change('languages', languagesUpd);
                    form.change('newLanguage', undefined);
                    form.change('level', undefined);
                    form.resetFieldState('newLanguage');
                    form.resetFieldState('level');
                };

                return (
                    <>
                        <section className={css.holderSpacing}>
                            <FieldSelect
                                id="newLanguage"
                                name="newLanguage"
                                validate={composeValidators(required(requiredMessageUhrzeit))}
                                form={form}
                                placeholder={intl.formatMessage({
                                    id: 'ProfileSettingsForm.changeLanguageLabel',
                                })}
                                optionsList={LANGUAGES.filter(l =>
                                    !languages ? true : !languages[l]
                                ).map(l => ({
                                    label: l,
                                    value: l,
                                }))}
                                disabled={
                                    languages && Object.keys(languages).length === LANGUAGES.length
                                }
                            />

                            <FieldSelect
                                id="level"
                                name="level"
                                validate={composeValidators(required(requiredMessageUhrzeit))}
                                notifyOnChange={addLanguage}
                                disabled={!newLanguage}
                                form={form}
                                placeholder={intl.formatMessage({
                                    id: 'ProfileSettingsForm.changeLanguageLevelLabel',
                                })}
                                optionsList={Object.keys(LANGUAGES_LEVELS).map(label => ({
                                    label,
                                    value: label,
                                }))}
                            />
                        </section>

                        {languages && languagesEntries.length > 0 && (
                            <aside>
                                <div className={css.skillsetHeadingNav}>
                                    <FormattedMessage id="ProfileSettingsForm.skillsetHeadingNav" />
                                    <FormattedMessage id="ProfileSettingsForm.changeLanguageLevelNav" />
                                </div>
                                {languagesEntries.sort(sortByLevel).map(([key, value]) => (
                                    <div className={css.editUserSkillsetItem} key={key}>
                                        <div className={css.editUserSkillLabel}>
                                            <span>{key}</span>
                                        </div>
                                        <div className={css.levelsHolder}>
                                            <p className={css.levelItem}>{value}</p>
                                            <div
                                                className={css.editUserSkillAction}
                                                onClick={() => {
                                                    setItemToRemove(key);
                                                    setOpen(true);
                                                }}
                                            >
                                                <CircleCrossedInsideIcon />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </aside>
                        )}

                        <div className={css.userDataProtectedMessage} />
                        <footer>
                            <Button
                                disabled={disabled}
                                onClick={() => restProps.onSubmit({ languages })}
                                inProgress={updateInProgress}
                            >
                                <FormattedMessage
                                    id={`ProfileSettingsForm.editUserInfoAction-${
                                        initialLanguages && Object.keys(initialLanguages).length
                                            ? 'edit'
                                            : 'userLanguages'
                                    }`}
                                />
                            </Button>
                        </footer>
                        {open && (
                            <ConfirmActionModal
                                isOpen={open}
                                onClose={() => {
                                    setOpen(false);
                                    setItemToRemove(null);
                                }}
                                actionHandler={() => removeLanguage(itemToRemove)}
                                id="remove-language-item"
                                heading="ProfileSettingsForm.changeLanguage-removalHeading"
                                description="ProfileSettingsForm.changeLanguage-removalDesc"
                                action="ProfileSettingsForm.changeLanguage-removalAction"
                            />
                        )}
                    </>
                );
            }}
        />
    );
};

EditUserLanguagesSection.defaultProps = {};

EditUserLanguagesSection.propTypes = {};

export default compose(injectIntl)(EditUserLanguagesSection);

import { useState, useEffect } from 'react';
import { useStore, useDispatch } from 'react-redux';
import { DEFAULT_VARIANT, getEntityAssets } from '../ducks/Assets.duck';
import { ensureListing } from '../util/data';
import { sdkImagesDto } from '../util/sdk';

export const useAssets = ({ listing, allowedTypes, variant = DEFAULT_VARIANT }) => {
    const store = useStore();

    const {
        Assets: { assetsLoadingRequests, assetsData },
    } = store.getState();
    const dispatch = useDispatch();

    const [assets, setAssets] = useState([]);

    const {
        id,
        attributes: {
            publicData: { userRepresentationId },
        },
        author,
    } = ensureListing(listing);

    const { uuid: listingId } = id || {};
    const { profileImage } = author || { profileImage: null };
    const listingAssets = assetsData && assetsData[listingId] && assetsData[listingId][variant];
    const loading = assetsLoadingRequests && assetsLoadingRequests[listingId];

    useEffect(() => {
        listingId && dispatch(getEntityAssets({ id: listingId, variant }));
    }, [listingId]);

    useEffect(() => {
        const assets = [];

        if (profileImage && userRepresentationId) {
            assets.unshift(profileImage);
        }

        if (loading || !Array.isArray(listingAssets)) {
            return setAssets(assets);
        }

        const assetsCombined = assets.concat(
            listingAssets.map(doc => {
                const { metadata, Key, ...restData } = doc;

                const [, fileName] = Key.split(`${listingId}/`);
                return sdkImagesDto({ ...restData, ...metadata, Key, fileName }, true);
            })
        );

        const assetsWithAllowedTypes = Array.isArray(allowedTypes)
            ? assetsCombined.filter(({ type: assetType }) =>
                  allowedTypes.some(allowedType => allowedType === assetType)
              )
            : assetsCombined;

        setAssets(
            assetsWithAllowedTypes.sort((a, b) => {
                const aVideo = a.type === 'video';
                const bVideo = b.type === 'video';

                if (bVideo && aVideo) {
                    return a.position - b.position;
                }

                if (aVideo || bVideo) {
                    const aN = aVideo ? 1 : 0;
                    const bN = bVideo ? 1 : 0;

                    return bN - aN;
                }

                return a.position - b.position;
            })
        );
    }, [listingAssets, loading, profileImage, userRepresentationId, listingId]);

    return assets;
};

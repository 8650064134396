import isEmpty from 'lodash/isEmpty';
import { clearCurrentUser, fetchCurrentUser } from './user.duck';
import { createUserWithIdp, createUserWithNativeIdp } from '../util/api';
import { storableError } from '../util/errors';
import * as log from '../util/log';

import config from '../config';
import { requestCreateListingDraft } from '../containers/EditListingPage/EditListingPage.duck';
import {
    getTempListingData,
    removeTempListingData,
    setTempListingData,
} from '../util/localStorage';
import { calculateProgressForOfflineMode } from '../util/listings';

const { userTypeHorseowner } = config;

const authenticated = authInfo => authInfo && authInfo.grantType === 'refresh_token';

// ================ Action types ================ //

export const AUTH_INFO_REQUEST = 'app/Auth/AUTH_INFO_REQUEST';
export const AUTH_INFO_SUCCESS = 'app/Auth/AUTH_INFO_SUCCESS';

export const LOGIN_REQUEST = 'app/Auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/Auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'app/Auth/LOGIN_ERROR';

export const LOGOUT_REQUEST = 'app/Auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/Auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'app/Auth/LOGOUT_ERROR';

export const SIGNUP_REQUEST = 'app/Auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'app/Auth/SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'app/Auth/SIGNUP_ERROR';

export const PROVIDE_LOGIN_ANALYTICS = 'app/Auth/PROVIDE_LOGIN_ANALYTICS';
export const PROVIDE_SIGNUP_ANALYTICS = 'app/Auth/PROVIDE_SIGNUP_ANALYTICS';

export const PROVIDE_LOGOUT_MIXPANEL = 'app/Auth/PROVIDE_LOGOUT_MIXPANEL';

// Generic user_logout action that can be handled elsewhere
// E.g. src/reducers.js clears store as a consequence
export const USER_LOGOUT = 'app/USER_LOGOUT';

export const CONFIRM_REQUEST = 'app/Auth/CONFIRM_REQUEST';
export const CONFIRM_SUCCESS = 'app/Auth/CONFIRM_SUCCESS';
export const CONFIRM_ERROR = 'app/Auth/CONFIRM_ERROR';

export const CREATE_LISTING_FORM_TEMP_DATA_REQUEST =
    'app/Auth/CREATE_LISTING_FORM_TEMP_DATA_REQUEST';
export const CREATE_LFTD_PREFLIGHT_REQUEST = 'app/Auth/CREATE_LFTD_PREFLIGHT_REQUEST';
export const CREATE_LISTING_FORM_TEMP_DATA_SUCCESS =
    'app/Auth/CREATE_LISTING_FORM_TEMP_DATA_SUCCESS';

export const CREATE_RIDER_LISTING_AFTER_AUTH = 'app/Auth/CREATE_RIDER_LISTING_AFTER_AUTH';

// ================ Reducer ================ //

const initialState = {
    isAuthenticated: false,

    // auth info
    authInfoLoaded: false,

    // login
    loginError: null,
    loginInProgress: false,

    // logout
    logoutError: null,
    logoutInProgress: false,

    // signup
    signupError: null,
    signupInProgress: false,
    // confirm (create use with idp)
    confirmErro: null,
    confirmInProgress: false,
    // temp data listing
    tempDataListingRequestSuccess: null,
    tempDataListingRequestInProgress: false,
    tmpDLPreflightRequestInProgress: false,
    createRiderListingAfterAuthInitialized: false,
};

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case AUTH_INFO_REQUEST:
            return state;
        case AUTH_INFO_SUCCESS:
            return { ...state, authInfoLoaded: true, isAuthenticated: authenticated(payload) };

        case LOGIN_REQUEST:
            return {
                ...state,
                loginInProgress: true,
                loginError: null,
                logoutError: null,
                signupError: null,
            };
        case LOGIN_SUCCESS:
            return { ...state, loginInProgress: false, isAuthenticated: true };
        case LOGIN_ERROR:
            return { ...state, loginInProgress: false, loginError: payload };

        case LOGOUT_REQUEST:
            return { ...state, logoutInProgress: true, loginError: null, logoutError: null };
        case LOGOUT_SUCCESS:
            return { ...state, logoutInProgress: false, isAuthenticated: false };
        case LOGOUT_ERROR:
            return { ...state, logoutInProgress: false, logoutError: payload };

        case SIGNUP_REQUEST:
            return { ...state, signupInProgress: true, loginError: null, signupError: null };
        case SIGNUP_SUCCESS:
            return { ...state, signupInProgress: false };
        case SIGNUP_ERROR:
            return { ...state, signupInProgress: false, signupError: payload };

        case CONFIRM_REQUEST:
            return { ...state, confirmInProgress: true, loginError: null, confirmError: null };
        case CONFIRM_SUCCESS:
            return { ...state, confirmInProgress: false, isAuthenticated: true };
        case CONFIRM_ERROR:
            return { ...state, confirmInProgress: false, confirmError: payload };
        case CREATE_LFTD_PREFLIGHT_REQUEST:
            return {
                ...state,
                tmpDLPreflightRequestInProgress: true,
            };

        case CREATE_LISTING_FORM_TEMP_DATA_REQUEST:
            return {
                ...state,
                tempDataListingRequestSuccess: null,
                tempDataListingRequestInProgress: true,
            };
        case CREATE_LISTING_FORM_TEMP_DATA_SUCCESS:
            return {
                ...state,
                tempDataListingRequestInProgress: false,
                tmpDLPreflightRequestInProgress: false,
                tempDataListingRequestSuccess: true,
            };

        case CREATE_RIDER_LISTING_AFTER_AUTH:
            return {
                ...state,
                createRiderListingAfterAuthInitialized: true,
            };

        default:
            return state;
    }
}

// ================ Selectors ================ //

export const authenticationInProgress = state => {
    const { loginInProgress, logoutInProgress, signupInProgress, confirmInProgress } = state.Auth;
    return loginInProgress || logoutInProgress || signupInProgress || confirmInProgress;
};

// ================ Action creators ================ //

export const authInfoRequest = () => ({ type: AUTH_INFO_REQUEST });
export const authInfoSuccess = info => ({ type: AUTH_INFO_SUCCESS, payload: info });

export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = () => ({ type: LOGIN_SUCCESS });
export const loginError = error => ({ type: LOGIN_ERROR, payload: error, error: true });
/** used by middleware, see CREATE_RIDER_LISTING_AFTER_AUTH */
export const createRiderListingAfterAuth = () => ({ type: CREATE_RIDER_LISTING_AFTER_AUTH });

export const logoutRequest = () => ({ type: LOGOUT_REQUEST });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutError = error => ({ type: LOGOUT_ERROR, payload: error, error: true });

export const signupRequest = () => ({ type: SIGNUP_REQUEST });
export const signupSuccess = () => ({ type: SIGNUP_SUCCESS });
export const signupError = error => ({ type: SIGNUP_ERROR, payload: error, error: true });

export const userLogout = () => ({ type: USER_LOGOUT });

export const confirmRequest = () => ({ type: CONFIRM_REQUEST });
export const confirmSuccess = () => ({ type: CONFIRM_SUCCESS });
export const confirmError = error => ({ type: CONFIRM_ERROR, payload: error, error: true });

export const provideLoginAnalytics = () => ({ type: PROVIDE_LOGIN_ANALYTICS });
export const provideSignupAnalytics = () => ({ type: PROVIDE_SIGNUP_ANALYTICS });

export const provideLogoutMixpanel = () => ({ type: PROVIDE_LOGOUT_MIXPANEL });

export const createListingFromTempDataRequest = () => ({
    type: CREATE_LISTING_FORM_TEMP_DATA_REQUEST,
});
export const createListingFromTempDataSuccess = () => ({
    type: CREATE_LISTING_FORM_TEMP_DATA_SUCCESS,
});
export const createLFTDPreflightRequest = () => ({
    type: CREATE_LFTD_PREFLIGHT_REQUEST,
});

// ================ Thunks ================ //

export const authInfo = () => (dispatch, getState, sdk) => {
    dispatch(authInfoRequest());
    return sdk
        .authInfo()
        .then(info => dispatch(authInfoSuccess(info)))
        .catch(e => {
            // Requesting auth info just reads the token from the token
            // store (i.e. cookies), and should not fail in normal
            // circumstances. If it fails, it's due to a programming
            // error. In that case we mark the operation done and dispatch
            // `null` success action that marks the user as unauthenticated.
            log.error(e, 'auth-info-failed');
            dispatch(authInfoSuccess(null));
        });
};

export const createListingFromTempData = () => async (dispatch, getState, sdk) => {
    const {
        user: { currentUser },
    } = getState();

    if (!currentUser || !currentUser.id) {
        dispatch(createListingFromTempDataSuccess());
        return removeTempListingData();
    }

    const {
        attributes: {
            profile: {
                publicData: { userType, infoCollected },
            },
        },
    } = currentUser;

    const isHorseOwner = userType === userTypeHorseowner;
    const tempListingData = getTempListingData();

    dispatch(createListingFromTempDataRequest());

    if (!infoCollected) {
        /**
         * the same request will be made after successful onboarding
         */
        return dispatch(createListingFromTempDataSuccess());
    }

    if (!isHorseOwner || !tempListingData || Object.keys(tempListingData).length === 0) {
        dispatch(createListingFromTempDataSuccess());
        /** no need to create a temp listing for a rider */
        return removeTempListingData();
    }

    const progress = calculateProgressForOfflineMode();
    const firstTabCompleted = progress === '1-8';

    const params = {
        publicData: {
            ...tempListingData,
            progress: firstTabCompleted ? '2-0' : progress,
        },
    };

    await dispatch(requestCreateListingDraft(params))
        .then(
            ({
                data: {
                    data: {
                        id,
                        attributes: { title, state, publicData },
                    },
                },
            }) => {
                /**
                 * add a field with newly created listing data;
                 * the field data will be used to redirect a user to listing page
                 */
                setTempListingData({
                    id,
                    attributes: { title, state, publicData },
                });
            }
        )
        .finally(() => {
            dispatch(createListingFromTempDataSuccess());
            removeTempListingData();
        });
};

export const login = (username, password, loginUserAfterSignUp) => (dispatch, getState, sdk) => {
    if (authenticationInProgress(getState())) {
        return Promise.reject(new Error('Login or logout already in progress'));
    }

    dispatch(loginRequest());
    dispatch(createLFTDPreflightRequest());
    // Note that the thunk does not reject when the login fails, it
    // just dispatches the login error action.
    return (
        sdk
            .login({ username, password })
            .then(() => dispatch(loginSuccess()))
            .then(() => dispatch(fetchCurrentUser()))
            .then(() => dispatch(createListingFromTempData()))
            .then(() => {
                // It is not needed to send ga login event right after signup
                if (!loginUserAfterSignUp) {
                    dispatch(provideLoginAnalytics());
                }
            })
            // .then(() => dispatch(fetchUnreadMessages()))
            .catch(e => {
                dispatch(loginError(storableError(e)));
            })
    );
};

export const logout = () => (dispatch, getState, sdk) => {
    if (authenticationInProgress(getState())) {
        return Promise.reject(new Error('Login or logout already in progress'));
    }
    dispatch(logoutRequest());

    // Note that the thunk does not reject when the logout fails, it
    // just dispatches the logout error action.
    return sdk
        .logout()
        .then(() => {
            // The order of the dispatched actions
            dispatch(logoutSuccess());
            dispatch(clearCurrentUser());
            log.clearUserId();
            dispatch(userLogout());
            dispatch(provideLogoutMixpanel());
        })
        .catch(e => dispatch(logoutError(storableError(e))));
};

export const signup = (params, paramsToRedirect) => (dispatch, getState, sdk) => {
    if (authenticationInProgress(getState())) {
        return Promise.reject(new Error('Login or logout already in progress'));
    }

    dispatch(signupRequest());
    const { email, password, firstName, lastName, ...rest } = params;
    const infoCollected = false;

    let createUserParams = isEmpty(rest)
        ? { email, password, firstName, lastName, publicData: { infoCollected, paramsToRedirect } }
        : {
              email,
              password,
              firstName,
              lastName,
              publicData: { infoCollected, paramsToRedirect, ...rest },
          };

    // We must login the user if signup succeeds since the API doesn't
    // do that automatically.
    const loginUserAfterSignUp = true;
    return sdk.currentUser
        .create(createUserParams)
        .then(() => dispatch(signupSuccess()))
        .then(() => dispatch(login(email, password, loginUserAfterSignUp)))
        .then(() => getState().user.currentUser)
        .catch(e => {
            dispatch(signupError(storableError(e)));
            log.error(e, 'signup-failed', {
                email: params.email,
                firstName: params.firstName,
                lastName: params.lastName,
            });
        });
};

export const signupWithIdp = params => (dispatch, getState, sdk) => {
    dispatch(confirmRequest());
    return createUserWithIdp(params)
        .then(() => dispatch(confirmSuccess()))
        .then(() => dispatch(fetchCurrentUser()))
        .then(() => getState().user.currentUser)
        .catch(e => {
            log.error(e, 'create-user-with-idp-failed', { params });
            return dispatch(confirmError(storableError(e)));
        });
};

export const signupWithNativeIdp = params => (dispatch, getState, sdk) => {
    dispatch(confirmRequest());
    return createUserWithNativeIdp(params)
        .then(() => dispatch(confirmSuccess()))
        .then(() => dispatch(fetchCurrentUser()))
        .then(() => getState().user.currentUser)
        .catch(e => {
            log.error(e, 'create-user-with-native-idp-failed', { params });
            // redirect to confirm page when registration is required
            window.open('/confirm', '_top');
            return dispatch(confirmError(storableError(e)));
        });
};

import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const locationToMixpanelTracking = location => {
    const { pathname } = location;
    console.log({ pathname });
    if (!pathname) return;

    if (pathname === '/signup/rider') return mixpanel.track('Rider Sign Up Started');

    if (pathname.includes('/profile/rider/preview/')) return mixpanel.track('Rider Profile Viewed');

    if (pathname === '/signup/horseowner') return mixpanel.track('Horse Owner Sign Up Started');
};

export const useLocationTracker = () => {
    const location = useLocation();
    useEffect(() => {
        if (!location) return;
        locationToMixpanelTracking(location);
    }, [location]);
};

import React, { useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { IconShevronLeft } from '../../icons';
import css from './AccordionItems.css';

const Accordion = props => {
    let { expandFirstElement } = props;
    const { items } = props;

    if (expandFirstElement) {
        expandFirstElement = 0;
    } else {
        expandFirstElement = null;
    }

    const [activeIndex, setActiveIndex] = useState(expandFirstElement);
    const itemRefs = useRef([]);

    const onItemClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);

        if (index !== activeIndex && itemRefs.current[index]) {
            setTimeout(() => {
                const yOffset = -90;
                const y =
                    itemRefs.current[index].getBoundingClientRect().top + window.scrollY + yOffset;

                window.scrollTo({ top: y, behavior: 'smooth' });
            }, 50);
        }
    };

    const generateIdFromText = (text) => {
        const normalizedText = text.replace(/\W/g, '');

        return `${normalizedText}`;
    }

    const renderItems = () => {
        return items.map((item, index) => {
            const isActive = index === activeIndex;
            const formattedContent = item.content.replace(/\n/g, '<br />').replace(/<a /g, '<a target="_blank" ');

            return (
                <div key={index} id={generateIdFromText(item.title)} className={`${css.accordionItem} ${isActive ? css.active : ''}`} ref={(ref) => (itemRefs.current[index] = ref)}>
                    <div
                        className={css.accordionItemHeader}
                        onClick={() => onItemClick(index)}
                    >
                        <h3 className={css.accordionTitle}>
                            {item.title}
                        </h3>
                        <IconShevronLeft rootClassName={css.accordionIconShevron} />
                    </div>
                    <div className={`${css.accordionContent} ${isActive ? css.active : ''}`}>
                        <div className={css.accordionContentInner}
                             dangerouslySetInnerHTML={{ __html: formattedContent }} />
                    </div>
                </div>
            );
        });
    };

    return <div className={css.accordion}>{renderItems()}</div>;
};

export const generateAccordionTableOfContents = itemsArray => {

    const generateIdFromText = (text) => {
        const normalizedText = text.replace(/\W/g, '');

        return `${normalizedText}`;
    }

    const onItemClick = (e, elementId) => {
        const accordionElement = document.getElementById(elementId);
        const isActive = accordionElement.firstChild.classList.contains(css.active);

        if(isActive) {
            const yOffset = -70;
            const y = accordionElement.getBoundingClientRect().top + window.scrollY + yOffset;

            window.scrollTo({ top: y, behavior: 'smooth' });
        } else {
            accordionElement.firstChild.click();
        }

        e.preventDefault();
    };

    const renderItems = () => {
        return itemsArray.flatMap((items, arrayIndex) => {
            return items.map((item, index) => {
                const anchorId = generateIdFromText(item.title);

                return (
                    <li className={css.tableOfContentsListItem}>
                        <a
                            href={`#${anchorId}`}
                            onClick={(e) => onItemClick(e, anchorId)}
                        >{item.tableOfContentTitle ? item.tableOfContentTitle : item.title}</a>
                    </li>
                );
            });
        });
    };

    return <ul className={css.tableOfContents}>{renderItems()}</ul>;
};

export default injectIntl(Accordion);

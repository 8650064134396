import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { Modal } from '../../../components';
import ExternalListingModalIcon from '../Icons/ExternalListingModalIcon';
import classNames from 'classnames';

import css from '../ListingPage.css';

const ExternalHorseModal = props => {
  const {
    isModalOpen,
    onCloseModal,
    onManageDisableScrolling,
    externalSourceLink,
    externalSourceName,
  } = props;

  return (
    <Modal
      id="ListingPage.externalListingModal"
      containerClassName={css.externalModalContainer}
      contentClassName={css.externalModalContent}
      containerClassNameJoined
      isOpen={isModalOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <div className={css.externalListingModalHolder}>
        <ExternalListingModalIcon />
        <h3 className={css.externalListingModalHeading}>
          <FormattedMessage id="ListingPage.externalListingModalTitle" />
        </h3>
        <p className={css.externalListingModalNotification}>
          <FormattedMessage id="ListingPage.externalListingModalDescription" />
        </p>
        <h3 className={classNames(css.modalHeader, css.externalListingModalSource)}>
          {externalSourceName}
        </h3>
        <a
          href={externalSourceLink}
          target="_blank"
          rel="nofollow"
          className={css.externalListingModalBtn}
        >
          <FormattedMessage id="ListingPage.externalListingModalContactAuthor" />
        </a>
      </div>
    </Modal>
  );
};

export default ExternalHorseModal;

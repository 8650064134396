import React from 'react';
import { ModalPortal, Form, Button, FieldRadioButton, SecondaryButton } from '../../components';
import { Form as FinalForm } from 'react-final-form';

import css from './AvailableUnlocksModal.css';

const AvailableUnlocksModal = props => {
    const {
        id,
        isOpen,
        onClose,
        onManageDisableScrolling,
        handleAcceptOrDeclineRequest,
        currentUser: {
            attributes: {
                profile: {
                    publicData: { availableUnlocks = 0 },
                },
            },
        },
    } = props;

    const guaranteeLink = (
        <span
            className={css.modalLink}
            onClick={() => {
                window.open(
                    'https://support.horsedeal.com/hc/de/articles/17568208438674',
                    '_blank'
                );
            }}
        >
            Zufriedenheitsgarantie
        </span>
    );

    return (
        <ModalPortal
            id={id}
            isOpen={isOpen}
            onClose={onClose}
            onManageDisableScrolling={() => null}
            containerClassName={css.modalContainer}
            contentClassName={css.modalContent}
            containerClassNameJoined
        >
            <FinalForm
                onSubmit={() => null}
                render={fieldRenderProps => {
                    const { handleSubmit, values, form } = fieldRenderProps;

                    const { credit } = values;

                    return (
                        <Form onSubmit={handleSubmit}>
                            <h3 className={css.modalHeading}>Anfrage freischalten</h3>
                            <p className={css.modalDesc}>
                                Nutze deine Credits, um diese Anfrage zu akzeptieren.
                            </p>
                            <FieldRadioButton
                                form={form}
                                rootClassName={css.field}
                                id="credit"
                                name="credit"
                                label={<p className={css.option}>1 Credit verwenden</p>}
                                value="credit"
                            />
                            <p className={css.modalSumToPay}>
                                Von den verfügbaren {availableUnlocks} Credits wird 1 Credit von
                                deinem Guthaben abgezogen.
                            </p>
                            <footer className={css.footer}>
                                <p className={css.sidenote}>
                                    Geschützt durch unsere {guaranteeLink}
                                </p>
                                <Button
                                    type="button"
                                    disabled={!credit}
                                    onClick={handleAcceptOrDeclineRequest}
                                >
                                    Anfrage akzeptieren
                                </Button>
                                <SecondaryButton type="submit">Abbrechen</SecondaryButton>
                            </footer>
                        </Form>
                    );
                }}
            />
        </ModalPortal>
    );
};

export default AvailableUnlocksModal;

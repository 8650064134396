import intersection from 'lodash/intersection';
import config from '../../config';

import { getCurrentUserLocation } from '../../util/localStorage';
import {
    PART_TIME_OPTION_INDEF,
    PART_TIME_OPTION_TEMP,
    RIDINGS_PER_WEEK_ENOUGHT,
    RIDINGS_PER_WEEK_FLEXIBLE,
    RIDINGS_PER_WEEK_FULL,
    RIDINGS_PER_WEEK_MODERATE,
    MOBILITY_BICYCLING,
    MOBILITY_DRIVING,
    MOBILITY_TRANSIT,
    MOBILITY_TWO_WHEELER,
    commonDisciplines,
    experienceAllowedOptions,
    RIDER_AVAILABILITY_NOT_AVAILABLE,
    RIDER_AVAILABILITY_NOT_CONFIRMED,
    RIDER_AVAILABILITY_CONFIRMED,
    HORSE_AVAILABILITY_PART_TIME_OPTION_UNLIMITED,
    HORSE_AVAILABILITY_PART_TIME_OPTION_FROM_SPEC_DAY,
    HORSE_AVAILABILITY_PART_TIME_OPTION_RANGE,
} from '../../marketplace-custom-config';

export const qualificationSearchSchemaParam = 'pub_SSC_qualification';
export const desiredLevelsSearchSchemaParam = 'pub_SSC_desiredDisciplinesLevels';
export const desiredDisciplinesSearchSchemaParam = 'pub_desiredDisciplines';
export const hasAnyFlag = 'has_any:';

export const defaultSearchParams = {
    include: ['author', 'author.profileImage', 'images'],
    'fields.user': [
        'profile.displayName',
        'profile.abbreviatedName',
        'profile.bio',
        'profile.publicData.country',
    ],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'createdAt'],
    'fields.image': [
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.square-small',
    ],
};

const { listingTypeRider, listingTypeHorse } = config;

const MOBILITY_PARAM_NAME = 'mobility';
const SERVICES_PARAM_NAME = 'services';
const FURNISHING_PARAM_NAME = 'furnishing';

const { defaultCountry, custom } = config;

const {
    R_LIZENZ,
    N_LIZENZ,
    DRESSAGE,
    JUMPING,
    JOURNEY,
    RIDING,
    BREVET,
    COMBINED_LICENSE,
    riderCertification,
    horseCertification,
} = custom;
/**
 * Validates a filter search param agains a filters configuration.
 *
 * All invalid param names and values are dropped
 *
 * @param {String} paramName Search parameter name
 * @param {Object} paramValue Search parameter value
 * @param {Object} filters Filters configuration
 */
export const validURLParamForExtendedData = (
    paramName,
    paramValueRaw,
    filters,
    country = defaultCountry
) => {
    const filtersArray = Object.values(filters);
    // resolve configuration for this filter
    const filterConfig = filtersArray
        .reduce(
            (acc, arrayItem) =>
                arrayItem.groupConfig ? [...acc, ...arrayItem.groupConfig] : [...acc, arrayItem],
            []
        )
        .find(f => f.paramName === paramName);

    const { hasFlag } = filterConfig || {};

    const paramValue = hasFlag
        ? paramValueRaw.toString().replace(hasFlag, '')
        : paramValueRaw.toString();

    const valueArray = paramValue ? paramValue.split(',') : [];

    if (filterConfig && valueArray.length > 0) {
        const { min, max, active } = filterConfig.config || {};

        const options =
            filterConfig.options &&
            (filterConfig.options[country] ||
                filterConfig.options[defaultCountry] ||
                filterConfig.options);

        if (options) {
            // Single and multiselect filters
            const allowedValues = options.flatMap(o => o.supersetKey || o.key);

            const validValues = intersection(valueArray, allowedValues).join(',');

            const collectValAsMultipleFilters = () =>
                allowedValues.reduce((multipleFilters, filter) => {
                    const { options } = filterConfig;
                    const optionFound = options.filter(o => o.key.includes(filter))[0];

                    if (!optionFound || !paramValueRaw.includes(optionFound.key))
                        return multipleFilters;

                    const { pub } = optionFound;

                    if (pub !== paramName) return multipleFilters;
                    const keyExists = !!multipleFilters[pub];

                    if (!multipleFilters[pub]) multipleFilters[pub] = hasFlag ? hasFlag : [];

                    const delimiter = ',';

                    if (hasFlag) {
                        multipleFilters[pub] = keyExists
                            ? multipleFilters[pub] + delimiter + optionFound.key
                            : multipleFilters[pub] + optionFound.key;
                    } else {
                        multipleFilters[pub].push(optionFound.key);
                    }

                    return multipleFilters;
                }, {});

            const valuesMultiple = filterConfig.multipleFilters
                ? collectValAsMultipleFilters()
                : {};
            const validValuesMultiple = Object.values(valuesMultiple);

            return filterConfig.multipleFilters && validValuesMultiple.length
                ? { ...valuesMultiple }
                : validValues.length > 0
                ? { [paramName]: hasFlag ? `${hasFlag}${validValues}` : validValues }
                : {};
        } else if (filterConfig.config && min != null && max != null) {
            // Price filter
            const validValues = valueArray.map(v => {
                return v < min ? min : v > max ? max : v;
            });
            return validValues.length === 2 ? { [paramName]: validValues.join(',') } : {};
        } else if (filterConfig.config && active) {
            // Generic filter
            return paramValue.length > 0 ? { [paramName]: paramValue } : {};
        }
    }
    return {};
};

/**
 * Checks filter param value validity.
 *
 * Non-filter params are dropped.
 *
 * @param {Object} params Search params
 * @param {Object} filters Filters configuration
 */
export const validFilterParams = (params, filters) => {
    const { country: ipAddressCountry, countrySelected } = getCurrentUserLocation();
    const userCountry = countrySelected || ipAddressCountry;
    const filterParamNames = Object.values(filters).reduce((acc, f) => {
        if (f && f.paramName) {
            return [...acc, f.paramName];
        }
        if (f && f.groupConfig) {
            f.groupConfig.forEach(({ urlParam }) => {
                acc = [...acc, urlParam];
            });
        }
        return acc;
    }, []);

    const paramEntries = Object.entries(params);

    return paramEntries.reduce(
        (validParams, [paramName, paramValue]) =>
            filterParamNames.includes(paramName)
                ? {
                      ...validParams,
                      ...validURLParamForExtendedData(paramName, paramValue, filters, userCountry),
                  }
                : { ...validParams },
        {}
    );
};

/**
 * Checks filter param value validity.
 *
 * Non-filter params are returned as they are.
 *
 * @param {Object} params Search params
 * @param {Object} filters Filters configuration
 */
export const validURLParamsForExtendedData = (params, filters) => {
    const { country: ipAddressCountry, countrySelected } = getCurrentUserLocation();
    const userCountry = countrySelected || ipAddressCountry;
    const filterParamNames = Object.values(filters).map(f => f.paramName);
    const paramEntries = Object.entries(params);

    return paramEntries.reduce((validParams, entry) => {
        const paramName = entry[0];
        const paramValue = entry[1];

        return filterParamNames.includes(paramName)
            ? {
                  ...validParams,
                  ...validURLParamForExtendedData(paramName, paramValue, filters, userCountry),
              }
            : { ...validParams, [paramName]: paramValue };
    }, {});
};

// extract search parameters, including a custom URL params
// which are validated by mapping the values to marketplace custom config.
export const pickSearchParamsOnly = (params, filters) => {
    const { address, origin, bounds, ...rest } = params || {};
    const boundsMaybe = bounds ? { bounds } : {};
    const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
    const filterParams = validFilterParams(rest, filters);
    return {
        ...boundsMaybe,
        ...originMaybe,
        ...filterParams,
    };
};

export const createSearchResultSchema = (address, intl) => {
    // Schema for search engines (helps them to understand what this page is about)
    // http://schema.org
    // We are using JSON-LD format

    const siteTitle = config.siteTitle;
    const searchAddress = address || intl.formatMessage({ id: 'SearchPage.schemaMapSearch' });
    const schemaDescription = intl.formatMessage(
        { id: 'SearchPage.schemaDescription' },
        { searchAddress }
    );
    const schemaTitle = intl.formatMessage(
        { id: 'SearchPage.schemaTitle' },
        { searchAddress, siteTitle }
    );

    // const schemaListings = listings.map((l, i) => {
    //     const title = l.attributes.title;
    //     const pathToItem = createResourceLocatorString('ListingPage', routeConfiguration(), {
    //         id: l.id.uuid,
    //         slug: createSlug(title),
    //     });
    //     return {
    //         '@type': 'ListItem',
    //         position: i,
    //         url: `${config.canonicalRootURL}${pathToItem}`,
    //         name: title,
    //     };
    // });

    const schemaMainEntity = JSON.stringify({
        '@type': 'ItemList',
        name: searchAddress,
        itemListOrder: 'http://schema.org/ItemListOrderAscending',
        // itemListElement: schemaListings,
    });
    return {
        title: schemaTitle,
        description: schemaDescription,
        schema: {
            '@context': 'http://schema.org',
            '@type': 'SearchResultsPage',
            description: schemaDescription,
            name: schemaTitle,
            mainEntity: [schemaMainEntity],
        },
    };
};

export const initiFitlersWithSupersetKeys = (pubData, options, isMultiple) =>
    options.reduce((acc, { key, ...rest }) => {
        const conditionFields = pubData.replace(hasAnyFlag, '').split(',');

        const units = key.split(',');
        const isMultipleKeys = units.length > 1;

        if (isMultiple && isMultipleKeys) {
            const keys = conditionFields.reduce(
                (subAcc, condField) => [...subAcc, ...units.map(unit => `${condField}.${unit}`)],
                []
            );
            return [
                ...acc,
                ...keys.map(key => ({
                    ...rest,
                    key,
                    supersetKey: key,
                })),
            ];
        }

        const keys = conditionFields.map(field => `${field}.${key}`);

        return [
            ...acc,
            {
                ...rest,
                key: keys.join(','),
                supersetKey: keys,
            },
        ];
    }, []);

const fillOptionsSet = (options, key, pub) =>
    options.map(value => ({
        key: `${commonDisciplines.find(({ label }) => label === key).id}.${value}`,
        label: value,
        pub,
        fieldName: 'qualificationRider',
        categoryName: key,
    }));

export const initQualificationFilter = (supersetPub, listingType) => {
    const { country, countrySelected } = getCurrentUserLocation();
    const userCountry = countrySelected || country;

    const paramName = qualificationSearchSchemaParam;
    const certification =
        listingType === listingTypeHorse ? horseCertification : riderCertification;

    const optionsCH = [
        {
            key: 'Keine',
            label: 'Keine',
            pub: paramName,
            fieldName: 'qualification',
        },
        {
            key: `${BREVET},${COMBINED_LICENSE}`,
            label: BREVET,
            pub: paramName,
            fieldName: 'qualification',
        },
        ...[R_LIZENZ, N_LIZENZ].map(option => ({
            key: option,
            label: option,
            pub: paramName,
            fieldName: 'qualification',
        })),
    ];

    const optionsDE = [
        {
            key: 'Keine',
            label: 'Keine',
            pub: paramName,
            fieldName: 'qualificationRider',
            categoryName: '',
        },
        ...fillOptionsSet(certification.DE[DRESSAGE], DRESSAGE, paramName),
        ...fillOptionsSet(certification.DE[JUMPING], JUMPING, paramName),
        ...fillOptionsSet(certification.DE[JOURNEY], JOURNEY, paramName),
        ...fillOptionsSet(certification.DE[RIDING], RIDING, paramName),
    ];

    const qualificationConfig = {
        CH: {
            paramName,
            name: 'qualification',
            hasFlag: 'has_any:',
            /**
             * multipleFilters: a single key has multiple values,
             * e.g. key: Brevet, Brevet Comb.
             */
            multipleFilters: true,
            pureOptions: optionsCH,
            options: supersetPub
                ? initiFitlersWithSupersetKeys(supersetPub, optionsCH, true)
                : optionsCH,
        },
        DE: {
            paramName,
            name: 'qualificationRider',
            hasFlag: 'has_any:',
            separateByCategory: true,
            dependentOnSubOption: desiredDisciplinesSearchSchemaParam,
            pureOptions: optionsDE,
            options: supersetPub ? initiFitlersWithSupersetKeys(supersetPub, optionsDE) : optionsDE,
        },
    };

    const countryQualification =
        qualificationConfig[userCountry] || qualificationConfig[defaultCountry];

    return countryQualification;
};

const initAvailabilityFilters = (listingType = listingTypeHorse, intl) => {
    const isRiderListingSearch = listingType === listingTypeRider;

    const availability = [
        {
            label: 'Flexibel',
            key: RIDINGS_PER_WEEK_FLEXIBLE,
        },
        ...[RIDINGS_PER_WEEK_MODERATE, RIDINGS_PER_WEEK_ENOUGHT, RIDINGS_PER_WEEK_FULL].map(
            key => ({
                label: intl.formatMessage(
                    {
                        id: 'EditUserAvailabilityForm.numberOfRidings-numPerWeek',
                    },
                    { value: key }
                ),
                key,
            })
        ),
    ];

    const availabilitySecondaryOptions = (isRiderListingSearch
        ? [PART_TIME_OPTION_INDEF, PART_TIME_OPTION_TEMP]
        : [HORSE_AVAILABILITY_PART_TIME_OPTION_UNLIMITED, HORSE_AVAILABILITY_PART_TIME_OPTION_RANGE]
    ).map(key => ({
        label: intl.formatMessage({
            id: `SearchPage.availabilityPartTimeOption-${key}-${listingType}`,
        }),
        key,
    }));

    return { availability, availabilitySecondaryOptions };
};

const initLevelsFilter = supersetPub => ({
    paramName: desiredLevelsSearchSchemaParam,
    name: 'desiredDisciplinesLevels',
    hasFlag: 'has_any:',
    pureOptions: experienceAllowedOptions,
    options: supersetPub
        ? initiFitlersWithSupersetKeys(supersetPub, experienceAllowedOptions)
        : experienceAllowedOptions,
});

export const getFilters = (
    {
        disciplines = config.custom.disciplines,
        activities = config.custom.activities,
        genders = config.custom.genders,
        services = config.custom.services,
        furnishing = config.custom.furnishing,
        priceFilterConfig = config.custom.priceFilterConfig,
        heightFilterConfig = config.custom.heightFilterConfig,
        horseAgeFilterConfig = config.custom.horseAgeFilterConfig,
        riderAgeFilterConfig = config.custom.riderAgeFilterConfig,
        intl,
    },
    searchInURL
) => {
    const { listingType } = searchInURL;
    const { availability, availabilitySecondaryOptions } = initAvailabilityFilters(
        listingType,
        intl
    );
    const isListingTypeHorse = listingType === listingTypeHorse;
    const ageFilterConfig = isListingTypeHorse ? horseAgeFilterConfig : riderAgeFilterConfig;

    const openForPartTimeProposalsFitler = {
        paramName: 'pub_openForPartTimeProposals',
        name: 'openForPartTimeProposals',
        options: availabilitySecondaryOptions,
    };

    return {
        desiredDisciplineFilter: {
            paramName: desiredDisciplinesSearchSchemaParam,
            hasFlag: hasAnyFlag,
            options: disciplines,
        },
        activitiesFilter: {
            paramName: 'pub_activities',
            hasFlag: 'has_any:',
            options: activities,
        },
        hasVideoFilter: {
            paramName: 'pub_hasVideo',
            name: 'hasVideo',
            options: [
                {
                    key: 'true',
                    value: 'true',
                    label: '',
                },
                {
                    key: 'false',
                    value: 'false',
                    label: '',
                },
            ],
        },
        startDateFilter: {
            paramName: 'pub_startDate',
            name: 'startDate',
            options: [
                HORSE_AVAILABILITY_PART_TIME_OPTION_UNLIMITED,
                HORSE_AVAILABILITY_PART_TIME_OPTION_FROM_SPEC_DAY,
                HORSE_AVAILABILITY_PART_TIME_OPTION_RANGE,
            ].map(key => ({
                key,
                value: key,
                label: '',
            })),
        },
        availabilityStatusFilter: {
            paramName: 'pub_availabilityStatus',
            name: 'availabilityStatus',
            options: [
                {
                    key: RIDER_AVAILABILITY_NOT_AVAILABLE,
                    value: RIDER_AVAILABILITY_NOT_AVAILABLE,
                    label: '',
                },
                {
                    key: RIDER_AVAILABILITY_NOT_CONFIRMED,
                    value: RIDER_AVAILABILITY_NOT_CONFIRMED,
                    label: '',
                },
                {
                    key: RIDER_AVAILABILITY_CONFIRMED,
                    value: RIDER_AVAILABILITY_CONFIRMED,
                    label: '',
                },
            ],
        },
        availabilityFilter: {
            paramName: 'pub_availability',
            name: 'availability',
            options: availability,
            parallelFilter: {
                ...openForPartTimeProposalsFitler,
            },
        },
        openForPartTimeProposalsFitler,
        qualificationFilter: {
            ...initQualificationFilter(
                searchInURL[desiredDisciplinesSearchSchemaParam],
                listingType
            ),
        },
        priceFilter: {
            paramName: 'price',
            config: priceFilterConfig,
        },
        levelFilter: {
            ...initLevelsFilter(searchInURL[desiredDisciplinesSearchSchemaParam]),
        },
        ageFilter: {
            paramName: 'pub_age',
            config: ageFilterConfig,
        },
        hightFilter: {
            paramName: 'pub_hight',
            config: heightFilterConfig,
        },
        genderFilter: {
            paramName: 'pub_gender',
            options: genders,
        },
        equipmentFilter: {
            groupConfig: [
                {
                    groupName: MOBILITY_PARAM_NAME,
                    paramName: 'pub_mobility',
                    urlParam: 'pub_mobility',
                    options: [
                        { key: 'mobility', label: 'Mit öffentlichen Verkehrsmitteln erreichbar.' },
                    ],
                },
                {
                    groupName: SERVICES_PARAM_NAME,
                    paramName: 'pub_services',
                    urlParam: 'pub_services',
                    hasFlag: 'has_any:',
                    options: services,
                },
                {
                    groupName: FURNISHING_PARAM_NAME,
                    paramName: 'pub_furnishing',
                    urlParam: 'pub_furnishing',
                    hasFlag: 'has_any:',
                    options: furnishing,
                    twoColumns: true,
                },
            ],
        },
        mobilityTransportFilter: {
            paramName: 'pub_mobilityTransport',
            options: [
                MOBILITY_TRANSIT,
                MOBILITY_BICYCLING,
                MOBILITY_TWO_WHEELER,
                MOBILITY_DRIVING,
            ].map(key => ({
                label: intl.formatMessage({
                    id: `EditUserMobilitySection.mobility-${key}`,
                }),
                id: key,
                key,
            })),
        },
    };
};

export const applyVerticalSpacing = () => {
    const nodeToApplyStyles = document.getElementById('search-map');

    if (!nodeToApplyStyles) {
        return;
    }
    const topBar = document.querySelector('[data-role="top-bar"]');
    const filtersPanel = document.getElementById('filters-panel');

    const verticalSpacing = [topBar, filtersPanel].reduce(
        (acc, node) => (node ? node.clientHeight + acc : acc),
        0
    );

    nodeToApplyStyles.style.marginTop = `${verticalSpacing}px`;
    nodeToApplyStyles.style.height = `calc(100vh - ${verticalSpacing}px)`;
};

export const searchPageAllowedUriUnits = intl => [
    'address',
    'bounds',
    'origin',
    'listingType',
    'distance',
    'mapView',
    'mapSearch',
    'sort',
    'extraSort',
    'page',
    ...Object.values(getFilters({ intl }, { listingType: listingTypeHorse }))
        .map(({ paramName }) => paramName)
        .filter(unit => !!unit),
];

import * as custom from './marketplace-custom-config.js';
import defaultLocationSearches from './default-location-searches';
import { stripePublishableKey, stripeSupportedCountries } from './stripe-config';
import { currencyConfiguration, supportedCurrenciesConfiguration } from './currency-config';

const env = process.env.REACT_APP_ENV;
const dev = env === 'development';

export const ADMINISTRATOR_ROLE = 'administrator';

// If you want to change the language, remember to also change the
// locale data and the messages in the app.js file.
const locale = 'de';
const i18n = {
    /*
    0: Sunday
    1: Monday
    ...
    6: Saturday
  */
    firstDayOfWeek: 1,
};
const initialSearchAddress = {
    CH: {
        address: 'Schweiz',
        bounds: '47.808453%2C10.492064%2C45.817981%2C5.955902',
    },
    DE: {
        address: 'Deutschland',
        bounds: '55.08149999602162%2C15.04189619759281%2C47.27011146235569%2C5.86634248758437',
    },
};

const search_address = 'search_address';
// Should search results be ordered by distance to origin.
// NOTE: If this is set to true add parameter 'origin' to every location in default-location-searches.js
// Without the 'origin' parameter, search will not work correctly
// NOTE: Keyword search and ordering search results by distance can't be used at the same time. You can turn keyword
// search off by changing the keywordFilterConfig parameter active to false in marketplace-custom-config.js
const sortSearchByDistance = false;

// API supports custom processes to be used in booking process.
// We need to specify it when we are initiating a new order
// (or fetching price information by calling 'initiateSpeculative' endpoint).
//
// In a way, 'processAlias' defines which transaction process (or processes)
// this particular web application is able to handle.
const bookingProcessAlias = 'sca-preauth-with-nightly-booking/release-1';

// The transaction line item code for the main unit type in bookings.
//
// Possible values: ['line-item/night', 'line-item/day', 'line-item/units';]
//
// Note: translations will use different translation keys for night, day or unit
// depending on the value chosen.
const bookingUnitType = 'line-item/night';

// Should the application fetch available time slots (currently defined as
// start and end dates) to be shown on listing page.
const enableAvailability = process.env.REACT_APP_AVAILABILITY_ENABLED === 'true';

// A maximum number of days forwards during which a booking can be made.
// This is limited due to Stripe holding funds up to 90 days from the
// moment they are charged. Also note that available time slots can only
// be fetched for 180 days in the future.
const dayCountAvailableForBooking = 90;

// To pass environment variables to the client app in the build
// script, react-scripts (and the sharetribe-scripts fork of
// react-scripts) require using the REACT_APP_ prefix to avoid
// exposing server secrets to the client side.
const sdkClientId = process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID;
const sdkBaseUrl = process.env.REACT_APP_SHARETRIBE_SDK_BASE_URL;
const sdkTransitVerbose = process.env.REACT_APP_SHARETRIBE_SDK_TRANSIT_VERBOSE === 'true';

/** default currency for marketplace */
const currency = process.env.REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY;

// Currency formatting options.
// See: https://github.com/yahoo/react-intl/wiki/API#formatnumber
const currencyConfig = currencyConfiguration(currency);
const supportedCurrenciesConfig = supportedCurrenciesConfiguration();

// Listing minimum price in currency sub units, e.g. cents.
// 0 means no restriction to the price
// 1 is relevant for CHF currency as it is more than Stripe minimum price https://stripe.com/docs/currencies#minimum-and-maximum-charge-amounts
const listingMinimumPriceSubUnits = 1;

// Sentry DSN (Data Source Name), a client key for authenticating calls to Sentry
const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

// If webapp is using SSL (i.e. it's behind 'https' protocol)
const usingSSL = process.env.REACT_APP_SHARETRIBE_USING_SSL === 'true';

// Address information is used in SEO schema for Organization (http://schema.org/PostalAddress)
const addressCountry = 'CH';
const addressRegion = 'Zürich';
const postalCode = '8800';
const streetAddress = 'Seestrasse 93';

// Canonical root url is needed in social media sharing and SEO optimization purposes.
const canonicalRootURL = process.env.REACT_APP_CANONICAL_ROOT_URL;

// Site title is needed in meta tags (bots and social media sharing reads those)
const siteTitle = 'HorseDeal';

// Twitter handle is needed in meta tags (twitter:site). Start it with '@' character
const siteTwitterHandle = '@HorseDeal24';
const siteTikTokPage = 'https://www.tiktok.com/@horsedeal.official';
const siteYoutubePage = 'https://www.youtube.com/@horsedeal.official';
// Instagram page is used in SEO schema (http://schema.org/Organization)
const siteInstagramPage = 'https://www.instagram.com/horsedeal.official';
const siteSnapchatPage = 'https://www.snapchat.com/add/horsedeal24';
// Facebook page is used in SEO schema (http://schema.org/Organization)
const siteFacebookPage = 'https://www.facebook.com/horsedeal.official';
//const FacebookPageRegister = 'https://horsedeal.herokuapp.com/people/auth/facebook/callback';

// should not be confused with supported countries for stripe
// this config is for countries where the users may use listings

const supportedCountriesConfig = {
    // Switzerland
    CH: {
        name: 'Switzerland',
        code: 'CH',
        phoneCode: '41',
        currency: supportedCurrenciesConfig.CHF.currency,
        defaultAddress: {
            bounds: '48.16143144%2C9.74508925%2C45.54379197%2C6.59750625',
            origin: '46.86856575036036%2C8.171297749999988',
            address: 'Schweiz',
        },
        signature: 'chf',
    },
    DE: {
        // Germany
        name: 'Deutschland',
        code: 'DE',
        phoneCode: '49',
        currency: supportedCurrenciesConfig.EUR.currency,
        defaultAddress: {
            bounds: '55.08149999602162%2C15.04189619759281%2C47.27011146235569%2C5.86634248758437',
            origin: '51.165691%2C10.451526',
            address: 'Deutschland',
        },
        signature: 'eur',
    },
    // Austria
};
/**
 * this var is used as a mapper,
 * e.g. AT is considered to be the same as DE
 */
const supportedCountriesConfigMapper = {
    CH: 'CH',
    DE: 'DE',
    AT: 'DE',
    NL: 'DE',
};
// Social logins & SSO

// Note: Facebook app id is also used for tracking:
// Facebook counts shares with app or page associated by this id
// Currently it is unset, but you can read more about fb:app_id from
// https://developers.facebook.com/docs/sharing/webmasters#basic
// You should create one to track social sharing in Facebook
// Facebook counts shares with app or page associated by this id
// Currently it is unset, but you can read more about fb:app_id from
// https://developers.facebook.com/docs/sharing/webmasters#basic
// You should create one to track social sharing in Facebook
const facebookAppId = process.env.REACT_APP_FACEBOOK_ID_APP;
const maps = {
    mapboxAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
    googleMapsAPIKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,

    // The location search input can be configured to show default
    // searches when the user focuses on the input and hasn't yet typed
    // anything. This reduces typing and avoids too many Geolocation API
    // calls for common searches.
    search: {
        // When enabled, the first suggestion is "Current location" that
        // uses the browser Geolocation API to query the user's current
        // location.
        suggestCurrentLocation: process.env.REACT_APP_DEFAULT_SEARCHES_ENABLED === 'true',

        // Distance in meters for calculating the bounding box around the
        // current location.
        currentLocationBoundsDistance: 1000,

        // Example location can be edited in the
        // `default-location-searches.js` file.
        defaults:
            process.env.REACT_APP_DEFAULT_SEARCHES_ENABLED === 'true'
                ? defaultLocationSearches
                : [],

        // Limit location autocomplete to a one or more countries
        // using ISO 3166 alpha 2 country codes separated by commas.
        // If you want to limit the autocomplete, uncomment this value:
        // countryLimit: ['AU'],
    },
    supportedCountries: supportedCountriesConfig,
    supportedCountriesMapper: supportedCountriesConfigMapper,
    // When fuzzy locations are enabled, coordinates on maps are
    // obfuscated randomly around the actual location.
    //
    // NOTE: This only hides the locations in the UI level, the actual
    // coordinates are still accessible in the HTTP requests and the
    // Redux store.
    fuzzy: {
        enabled: false,

        // Amount of maximum offset in meters that is applied to obfuscate
        // the original coordinates. The actual value is random, but the
        // obfuscated coordinates are withing a circle that has the same
        // radius as the offset.
        offset: 1500,

        // Default zoom level when showing a single circle on a Map. Should
        // be small enough so the whole circle fits in.
        defaultZoomLevel: 13,

        // Color of the circle on the Map component.
        circleColor: '#8f2593',
    },

    // Custom marker image to use in the Map component.
    //
    // NOTE: Not used if fuzzy locations are enabled.
    customMarker: {
        enabled: false,

        // Publicly accessible URL for the custom marker image.
        //
        // The easiest place is /public/static/icons/ folder, but then the
        // marker image is not available while developing through
        // localhost.
        url: encodeURI(`${canonicalRootURL}/static/icons/map-marker-32x32.png`),

        // Dimensions of the marker image.
        width: 32,
        height: 32,

        // Position to anchor the image in relation to the coordinates,
        // ignored when using Mapbox.
        anchorX: 16,
        anchorY: 32,
    },
};
const recaptchaSiteKey =
    process.env.REACT_APP_ENV === 'development'
        ? '6LeHFEsaAAAAAGHsszk1spzvKjGTQ1RWyY4Q5mAy'
        : '6LdnFUsaAAAAAOu0IGglBR3OejgOUmICux7iJSOD';
const platformCommission = 10;

const allowedEmailDomains = ['CH', 'DE', 'AT', 'NL', 'FR', 'IT', 'EU', 'COM'];
// NOTE: only expose configuration that should be visible in the
// client side, don't add any server secrets in this file.
const config = {
    env,
    dev,
    locale,
    bookingProcessAlias,
    bookingUnitType,
    enableAvailability,
    dayCountAvailableForBooking,
    i18n,
    sdk: {
        clientId: sdkClientId,
        baseUrl: sdkBaseUrl,
        transitVerbose: sdkTransitVerbose,
    },
    sortSearchByDistance,
    currency,
    currencyConfig,
    supportedCurrenciesConfig,
    listingMinimumPriceSubUnits,
    stripe: {
        publishableKey: stripePublishableKey,
        supportedCountries: stripeSupportedCountries,
    },
    canonicalRootURL,
    address: {
        addressCountry,
        addressRegion,
        postalCode,
        streetAddress,
    },
    siteTitle,
    siteFacebookPage,
    siteInstagramPage,
    siteTwitterHandle,
    siteSnapchatPage,
    siteTikTokPage,
    siteYoutubePage,
    facebookAppId,
    sentryDsn,
    usingSSL,
    maps,
    custom,
    initialSearchAddress,
    search_address,
    recaptchaSiteKey,
    platformCommission,
    defaultCountry: 'CH',
    userTypeHorseowner: 'horseowner',
    userTypeRider: 'rider',
    listingTypeHorse: 'horse',
    listingTypeRider: 'listing-rider',
    allowedDomains: allowedEmailDomains,
};
export default config;
